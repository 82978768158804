import React from 'react'
import DatePickerField from '../components/DatePickerField'
import InputField from '../components/InputField'
import TextAreaField from '../components/TextAreaField'
import RadioButtonField from '../components/RadioButtonField'
import CheckBox from '../components/CheckBox'
import RangeField from '../components/RangeField'
import DropDown from '../components/DropDown'
import DateRangePickerField from '../components/DateRangePickerField'
import TimePickerField from '../components/TimePickerField'
import DateTimePickerField from '../components/DateTimePickerField'
import MultiSelectedField from '../components/MultiSelectedField'
import MultiwordsFields from '../components/MultiwordsFields'
import RangemultiFeild from '../components/RangemultiFeild'

function SimpleForm() {

    return (
        <div>



            <div className='container-fluid'>


                <div className='row justify-content-center'>

                    <form className=' card shadow col-sm-5 py-4 mt-5 ps-5 pe-5'>

                        <h4 className='text-center'>Horizontal Form</h4>

                        <div className='row justify-content-cetner align-items-center my-2'>

                            <label className='col-sm-2' >Name :</label>

                            <div className='col-sm-10'>
                                <InputField className=" form-error" placeholder={"Enter Your Name"}></InputField>
                                <p className="form-text-error">Please enter your name</p>

                            </div>


                        </div>

                        <div className='row justify-content-cetner align-items-center my-2'>

                            <label className='col-sm-2' >Address :</label>

                            <div className='col-sm-10'>

                                <div className='form-error rounded-top rounded-bottom form-error-textarea'>

                                    <TextAreaField></TextAreaField>

                                </div>
                                <p className="form-text-error">Please enter text</p>

                            </div>

                        </div>

                        <div className='row justify-content-cetner align-items-center my-2'>

                            <label className='col-sm-2' >Gender :</label>

                            <div className='col-sm-10'>

                                {/* <div className=' border'> */}
                                <div>
                                    <RadioButtonField ></RadioButtonField>
                                    <label >Male</label>
                                    <RadioButtonField ></RadioButtonField>
                                    {/* </div> */}
                                    {/* <div className=' border'> */}
                                    <label >Female</label>

                                    <p className="form-text-error">Please enter gender</p>
                                </div>

                                {/* </div> */}

                            </div>

                        </div>


                        <div className=' row justify-content-cetner align-items-center my-2'>

                            <label className='col-sm-2' >Hobbies :</label>
                            <div className='col-sm-10'>

                                <CheckBox className="mx-2" ></CheckBox>
                                <label className="form-check-label" for="flexCheckIndeterminate">
                                    Cricket
                                </label>

                                <CheckBox className="mx-2" ></CheckBox>
                                <label className="form-check-label" for="flexCheckIndeterminate">
                                    Chess
                                </label>

                                <CheckBox className="mx-2" ></CheckBox>
                                <label className="form-check-label" for="flexCheckIndeterminate">
                                    football
                                </label>
                                <p className="form-text-error">Please enter hobbies</p>

                            </div>

                        </div>


                        <div className='row justify-content-cetner align-items-center my-2'>

                            <label className="col-sm-2" >Birth Date :</label>
                            <div className='col-sm-10'>

                                <div className='form-error'>

                                    <DatePickerField ></DatePickerField>
                                </div>
                                <p className="form-text-error">Please enter date of birth</p>
                            </div>

                        </div>

                        <div className=' row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-2 ">Range :</label>
                            <div className='col-sm-10'>
                                <RangeField></RangeField>
                            </div>
                        </div>

                        <div className=' row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-2 ">Range Field :</label>
                            <div className='col-sm-10'>
                           <RangemultiFeild></RangemultiFeild>
                            </div>
                        </div>


                        <div className='row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-2">Country : </label>
                            <div className='col-sm-10'>

                                <div className='form-error'>

                                    <DropDown></DropDown>
                                </div>
                                <p className="form-text-error">Please enter range</p>
                            </div>
                        </div>

                        <div className='row justify-content-cetner align-items-center my-2'>

                            <label className="form-label col-sm-2">City </label>
                            <div className='col-sm-10'>
                                <div className='form-error'>

                                    <DropDown></DropDown>
                                </div>

                                <p className="form-text-error">Please enter city</p>
                            </div>

                        </div>

                        <div className='row justify-content-cetner align-items-center my-2'>

                            <label className="form-label col-sm-2">Date Range </label>

                            <div className='col-sm-10'>
                                <div className='form-error'>

                                    <DateRangePickerField></DateRangePickerField>
                                </div>
                                <p className="form-text-error">Please enter date range</p>

                            </div>
                        </div>

                        <div className='row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-2">Time Picker</label>
                            <div className='col-sm-10'>
                                <div className='form-error'>

                                    <TimePickerField></TimePickerField>
                                </div>
                                <p className="form-text-error">Please enter time</p>
                            </div>
                        </div>

                        <div className='row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-2">Date & TimePicker</label>
                            <div className='col-sm-10'>
                                <div className='form-error'>

                                    <DateTimePickerField></DateTimePickerField>
                                </div>
                                <p className="form-text-error">Please enter date and time</p>
                            </div>

                        </div>


                        <div className='row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-2">MultiSelect</label>
                            <div className='col-sm-10'>
                                <div className='form-error'>

                                    <MultiSelectedField></MultiSelectedField>
                                </div>
                                <p className="form-text-error">Please enter multi field</p>
                            </div>

                        </div>

                        <div className='row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-2">MultiSelect</label>
                            <div className='col-sm-10'>
                                <div className='form-error'>

                                    <MultiwordsFields></MultiwordsFields>
                                </div>
                                <p className="form-text-error">Please enter multi field</p>
                            </div>

                        </div>


                    </form>
                </div>






            </div>


            {/* *******vertical form**************** */}

            <div className='container-fluid'>


                <div className='row justify-content-center'>

                    <form className=' card shadow col-sm-5 py-4 mt-5 ps-5 pe-5'>

                        <h4 className='text-center'>Vertical Form</h4>

                        <div className='row justify-content-cetner align-items-center'>

                            <label className='col-sm-12 mb-2' >Name :</label>

                            <div className='col-sm-12'>
                                <InputField className=" form-error " placeholder={"Enter Your Name"}></InputField>
                                <p className="form-text-error">Please enter your name</p>

                            </div>

                        </div>

                        <div className='row justify-content-cetner align-items-center my-2'>

                            <label className='col-sm-12 mb-2' >Address :</label>

                            <div className='col-sm-12'>

                                <div className="form-error form-error-textarea">
                                    <TextAreaField ></TextAreaField>
                                </div>
                                <p className="form-text-error">Please enter text</p>

                            </div>

                        </div>

                        <div className='row justify-content-cetner align-items-center my-2'>

                            <label className='col-sm-12 mb-2' >Gender :</label>

                            <div className='col-sm-12'>


                                <div>

                                    <div>
                                        <RadioButtonField ></RadioButtonField>
                                        <label >Male</label>
                                    </div>

                                    <div>
                                        <RadioButtonField ></RadioButtonField>
                                        <label >Female</label>
                                    </div>
                                    <p className="form-text-error">Please enter gender</p>
                                </div>



                            </div>

                        </div>


                        <div className=' row justify-content-cetner align-items-center my-2'>

                            <label className='col-sm-12 mb-2' >Hobbies :</label>

                            <div className='col-sm-12'>
                                <div>
                                    <CheckBox className="mx-2" ></CheckBox>
                                    <label className="form-check-label" for="flexCheckIndeterminate">
                                        Cricket
                                    </label>
                                </div>

                                <div>
                                    <CheckBox className="mx-2" ></CheckBox>
                                    <label className="form-check-label" for="flexCheckIndeterminate">
                                        Chess
                                    </label>
                                </div>

                                <div>
                                    <CheckBox className="mx-2" ></CheckBox>
                                    <label className="form-check-label" for="flexCheckIndeterminate">
                                        football
                                    </label>
                                </div>
                                <p className="form-text-error">Please enter hobbies</p>

                            </div>


                        </div>


                        <div className='row justify-content-cetner align-items-center my-2'>

                            <label className="col-sm-12" >Birth Date :</label>
                            <div className='col-sm-12'>

                                <div className=" form-error " >
                                    <DatePickerField ></DatePickerField>
                                </div>
                                <p className="form-text-error">Please enter date of birth</p>
                            </div>

                        </div>

                        <div className=' row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-12 ">Range :</label>
                            <div className='col-sm-12'>
                                <RangeField></RangeField>
                            </div>
                        </div>

                        <div className=' row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-12 ">Range Feild :</label>
                            <div className='col-sm-12'>
                            <RangemultiFeild></RangemultiFeild>
                            </div>
                        </div>


                        <div className='row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-12">Country : </label>
                            <div className='col-sm-12'>

                                <div className=' form-error '>
                                    <DropDown></DropDown>
                                </div>

                                <p className="form-text-error">Please enter range</p>
                            </div>
                        </div>

                        <div className='row justify-content-cetner align-items-center my-2'>

                            <label className="form-label col-sm-12">City </label>
                            <div className='col-sm-12'>

                                <div className=' form-error '>
                                    <DropDown></DropDown>
                                </div>
                                <p className="form-text-error">Please enter city</p>
                            </div>

                        </div>

                        <div className='row justify-content-cetner align-items-center my-2'>

                            <label className="form-label col-sm-12">Date Range </label>

                            <div className='col-sm-12'>
                                <div className=' form-error '>
                                    <DateRangePickerField></DateRangePickerField>
                                </div>
                                <p className="form-text-error">Please enter date range</p>

                            </div>
                        </div>

                        <div className='row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-12">Time Picker</label>
                            <div className='col-sm-12'>
                                <div className=' form-error '>

                                    <TimePickerField></TimePickerField>
                                </div>
                                <p className="form-text-error">Please enter time</p>
                            </div>
                        </div>

                        <div className='row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-12">Date & TimePicker</label>
                            <div className='col-sm-12'>
                                <div className='form-error '>
                                    <DateTimePickerField></DateTimePickerField>
                                </div>
                                <p className="form-text-error">Please enter date and time</p>
                            </div>

                        </div>


                        <div className='row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-12">MultiSelect</label>
                            <div className='col-sm-12'>
                                <div className='form-error'>
                                    <MultiSelectedField></MultiSelectedField>
                                </div>
                                <p className="form-text-error">Please enter multi field</p>
                            </div>

                        </div>


                        <div className='row justify-content-cetner align-items-center my-2'>
                            <label className="form-label col-sm-12">MultiSelect</label>
                            <div className='col-sm-12'>
                                <div className='form-error'>
                                    <MultiwordsFields></MultiwordsFields>
                                </div>
                                <p className="form-text-error">Please enter multi field</p>
                            </div>

                        </div>

                    </form>
                </div>

            </div>




        </div>
    )
}

export default SimpleForm
