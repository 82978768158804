import React from 'react'
import { DateRangePicker } from 'rsuite';
import 'rsuite/DatePicker/styles/index.css';



function DateRangePickerField() {
    return (

        <div>
                <DateRangePicker
                 format="MMM dd, yyyy"
                  placeholder="Select Date Range"
                  className='w-100'
                    />
        </div>
    )
}

export default DateRangePickerField
