import React from 'react'
import errorImg from "../images/error500.svg"

import orexTradelogo from "../images/orexlogo.svg"

function Error500() {

  return (
    <div>

      <div className='error404_logo'>

        <div>
          <img src={orexTradelogo} alt="" />
        </div>
        
      </div>

      <div className='error'>

        <div className='error-img'>
          <img src={errorImg} alt="" />
        </div>

        <div className='erro-text error_500'>
          <h1>Internal Server Error</h1>
          <p>Please try again later or feel free to contact
            us if the problem persists.</p>
        </div>

      </div>
    </div>
  )
}

export default Error500