const MENU_ROUTES = {
    UPDATE_COMPANY_PROFILE : '/updateprofile',
    FORMS: '/form123',
    ECOMMERCE:"/ecommerce",
    ACADEMY:"/academy",
    LOGISTICE:"/logistics",
    USERS: '/users',
    NOTIFICATION: '/notification',
    CONNECTION: '/connetction',
    // LOGIN:"/login",
    // REGISTER:"/registor",
    // FORGOT:"/forgot",
    INPUT:"/b-input",
    GRUPS:"/i-grups",
    ITEM2:"/item2"
};

export default MENU_ROUTES;
