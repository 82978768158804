import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';

import isEmpty from "lodash/isEmpty";

import { resetPasswordAction } from '../actions/loginAction';
import InputField from '../components/InputField';

import loginLogo from "../images/orexlogo.svg";
import resetImg from "../images/final_reset-iamge.png";
import loginIcoin from "../images/login_icon.png";
import "../Orex.css";
import errorMessages from '../constant/errorMessages';
import ROUTES from '../config/routePath';

function ResetPasswordForm(props) {
    let nav = useNavigate("")
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState();
    const [password_confirmation, setpassword_confirmation] = useState();
    const [showPassword, setshowPassword] = useState(false);
    const [showPassword1, setshowPassword1] = useState(false);
    const [searchParams, setsearchParams] = useSearchParams();

    let urlparameters = searchParams.get('token');
    let decodedUrlParam = atob(urlparameters);
    let z = decodedUrlParam.split('&email=');
    let token = z[0];
    let email = z[1];
    // console.log('token111', token);

    const handleShowPassword = () => {
        setshowPassword(!showPassword)
    }

    const handleShowPassword1 = () => {
        setshowPassword1(!showPassword1)
    }

    // let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/; // for 8 to 15 chars only
    // let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; // doesnt allow /,;. etc
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&/;:.,])[A-Za-z\d@$!%*?&/;:.,<=>{+}[`~#^()_-|'"\]\\]{8,}$/;

    const handleSubmit = async (e) => {
        e.preventDefault();
        //   console.log("test99", regex.test(password));
        if (!validation()) {
            await props.resetPassword(password, token, email, password_confirmation)
                .then((response) => {
                    //   console.log("test", response);
                    if (response === true) {
                        nav(ROUTES.LOGIN);
                    } else {

                        const nerrors = {};

                        if (!response.success) {
                            // console.log('HERE1111111111111---', response);
                            if (response.message === "Validation Error.") {
                                if (!isEmpty(response.data.email)) {
                                    nerrors.somethingWentWrong = "Something went wrong";
                                }
                                if (!isEmpty(response.data.password)) {
                                    nerrors.password = response.data.password[0];
                                }
                                if (!isEmpty(response.data.password_confirmation)) {
                                    nerrors.password_confirmation = response.data.password_confirmation[0];
                                }
                            }
                            if (response.message === "Invalid token or email.") {
                                nerrors.invalidTokenOrEmail = response.message;
                            }
                            if (response.message === "Token has expired.") {
                                nerrors.expiredToken = response.message;
                            }
                            if (response.message === "New password cannot be the same as the old password.") {
                                nerrors.expiredToken = response.message;
                            }
                            if (!response) {
                                nerrors.somethingWentWrong = "Something went wrong";
                            }
                        }

                        setErrors(nerrors);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    
    const handleKeyDown = (e) => {
        // console.log(e.code);
        if(e.code === 'Space') {
            e.preventDefault();
        }    
        };

    const validation = () => {
        const errors = {};
        if (password == '' || password == null) {
            errors.password = errorMessages.PASSWORD_REQUIRED;
        } else if (regex.test(password) == false) {
            errors.strongPassword = errorMessages.STRONG_PASSWORD;
        }

        if (password_confirmation == '' || password_confirmation == null) {
            errors.password_confirmation = errorMessages.CONFIRM_PASSWORD_REQUIRED;
        } else if (password !== password_confirmation) {
            errors.matchPassword = errorMessages.MATCH_PASSWORD;
        }

        setErrors(errors);
        return Object.keys(errors).length > 0;
    };

    const styleError = {
        display: 'block',
    };


    return (

        <div>

            <div className='login'>

                <div className='logindesboard_img resetpassword-img'>
                    <img src={resetImg} alt="" />
                </div>

                <div className='login-card'>

                    <div className='card shadow border-0 p-4 card-reset'>

                        <div className='loginpage-img'>

                            <img src={loginLogo} alt="" />

                        </div>

                        <div className='mt-4'>

                            <div className='d-flex align-items-center'>
                                <h3 className='login-title'>Reset Password</h3>
                                <img className='login-icon' src={loginIcoin} alt="" />
                            </div>

                            <p className='login-subtitle'>Your new password must be different from previously used passwords</p>

                        </div>

                        {errors.invalidTokenOrEmail && <div className="invalid-feedback" style={styleError}>{errors.invalidTokenOrEmail}</div>}
                        {errors.expiredToken && <div className="invalid-feedback" style={styleError}>{errors.expiredToken}</div>}
                        {errors.somethingWentWrong && <div className="invalid-feedback" style={styleError}>{errors.somethingWentWrong}</div>}

                        <div>

                            <form className="custom-form">

                                <div className="mt-3">

                                    <label className="required">New password</label>

                                    <div className="d-flex">

                                        <div className='w-100'>
                                            <InputField type={(showPassword === true) ? "text" : "password"} placeholder="******" className="border-end-0" name="password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown} />
                                        </div>


                                        <span className="input-group-text" onClick={handleShowPassword}>
                                            <i className="fa-regular fa-eye" ></i></span>
                                        

                                    </div>
                                    {errors.password && <div className="invalid-feedback" style={styleError}>{errors.password}</div>}
                                    {errors.strongPassword && <div className="invalid-feedback" style={styleError}>{errors.strongPassword}</div>}

                                </div>

                                <div className="mt-3">

                                    <label className="required">Confirm password</label>

                                    <div className="d-flex">

                                      <div className='w-100'>
                                      <InputField type={(showPassword1 === true) ? "text" : "password"} placeholder="******" className="border-end-0" name="password_confirmation" value={password_confirmation} onChange={(e) => setpassword_confirmation(e.target.value)} onKeyDown={handleKeyDown} />
                                      </div>


                                        <span className="input-group-text" onClick={handleShowPassword1}>
                                            <i className="fa-regular fa-eye"></i></span>
                                        
                                    </div>
                                    {errors.password_confirmation && <div className="invalid-feedback" style={styleError}>{errors.password_confirmation}</div>}
                                    {errors.matchPassword && <div className="invalid-feedback" style={styleError}>{errors.matchPassword}</div>}

                                </div>


                                <button type="submit " className="btn login-btn w-100 mt-4 " onClick={handleSubmit}>Save new password</button>

                                <p className='mt-4 text-center'>

                                    <button className='btn back-home' onClick={() => { nav("/login") }}>
                                        <i className="fa-solid fa-angle-left mx-1"></i>
                                        Back to login
                                    </button>

                                </p>

                            </form>


                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}
const mapStateToProps = state => {
    return {
        auth: state.auth.login
    }
}
const mapDispatchToProps = dispatch => {
    return {
        resetPassword: (password, token, email, password_confirmation) => dispatch(resetPasswordAction(password, token, email, password_confirmation))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);