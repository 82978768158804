import React from 'react'

function Button({ children, style, onClick, className }) {
  return (

    <button  
      className={`btn-regi btn-regi border-0 text-white ${className}`}
      style={style} 
      onClick={onClick} >
      {children}
    </button>

  )
}

export default Button