import React from 'react'
import { Button } from 'react-bootstrap';
import { Bounce, toast, ToastContainer } from 'react-toastify';

function AlertWarning() {

    const warning = () => toast.warning('3 attempt left', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

  return (
    <div>
         <Button onClick={warning} className='btn btn-warning m-2'>warning</Button>
         
         <ToastContainer/>
      
    </div>
  )
}

export default AlertWarning
