import React, { useState } from 'react'
import CreatableTagsInput from './CustomInputTage'

function MultiwordsFields() {

    const [imageTags, setImageTags] = useState([]);

    const handleUpdate = (updatedTags) => {
        setImageTags(updatedTags);
      };

  return (
    <div className="input-container">
    <CreatableTagsInput tags={imageTags} onUpdate={handleUpdate} />
  </div>
  )
}

export default MultiwordsFields
