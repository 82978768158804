import React from 'react'

import industry from "../src/images/industry.svg"
import countries from "../src/images/countries_trade .svg"
import setisfied from "../src/images/satisfied_clients .svg"
import network from "../src/images/network_partner.svg"

function Rewards() {
    return (
        <div>

            <section id="landingFunFacts" className="section-py landing-fun-facts">

                <div className="container-fluid reqard-padding">

                    <div className="how_we">

                        <div className='boost'>
                            <p >Our Work</p>
                        </div>

                        <h5> <span className='fw-bold'> Counts </span>
                            <span className='boost-span'>
                                <span className='fw-bold'>of Our </span> Work
                            </span>

                        </h5>

                        <p className='with-our'>
                            Measuring Success Through Our Achievements and Deliverables.

                        </p>
                    </div>

                    <div className="row px-5  count-padding">

                        <div className="col-sm-6 col-lg-3 my-4">
                            <div className="card  shadow border-0 our-work-card pt-2">
                                <div className="card-body text-center ">
                                    <img src={industry} alt="laptop" className="mb-4 ourwork-img" />
                                    <h3 className="mb-2">10+ Yrs</h3>
                                    <p className="with-our">
                                        Industry Experience

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3 my-4">
                            <div className="card shadow border-0 our-work-card pt-2">
                                <div className="card-body text-center">
                                    <img src={countries} alt="laptop" className="mb-4 ourwork-img" />
                                    <h3 className="mb-2">10B+</h3>
                                    <p className="with-our">
                                        Countries Trade Data

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3 my-4">
                            <div className="card shadow border-0 our-work-card pt-2">
                                <div className="card-body text-center">
                                    <img src={setisfied} alt="laptop" className="mb-4 ourwork-img" />
                                    <h3 className="mb-2">4.8/5</h3>
                                    <p className="with-our">
                                        Satisfied Clients Worldwide

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-3 my-4">
                            <div className="card shadow border-0 our-work-card pt-2">
                                <div className="card-body text-center">
                                    <img src={network} alt="laptop" className="mb-4 ourwork-img" />
                                    <h3 className="mb-0">10k+</h3>
                                    <p className=" with-our">
                                        Global Partner
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    )
}

export default Rewards