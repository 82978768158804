import React, { useState } from 'react'
import Redy from './Redy';
import orex from "../src/images/orexlogo.svg"

import ROUTES from './config/routePath';
import arow from "../src/images/arrow-icon.png"
import shadow from "../src/images/banner_bg.png"
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useNavigate } from 'react-router-dom';

// import Sliders from './Sliders';

function NavSection() {

    let nav = useNavigate("")

    const [showOffcanvas, setShowOffcanvas] = useState(false);

    let hendlehome = () => {
        nav(ROUTES.HOME);
    }

    return (
        <div>



            <div td='dark'>

                <div className='about-nav'>

                    {/* <div className="every nev-content">

                        <img src={shadow} alt="" className='bannaer-img' />

                        <h1>Grow your Business with Real Time Data
                        </h1>
                        <p className='features-icon-description'>
                            Facilitating seamless global trade connections, we specializes in providing comprehensive import-export data worldwide
                        </p>

                        <button className='btn-get'>Get early Access</button>

                        <div className='join_arrow'>

                            <p className='join_us'>Join Us Now</p>
                            <img src={arow} alt="" />

                        </div>

                    </div> */}

                    <div className='nav_bar'>

                        <div>
                            <img src={orex} alt="" className='orex-logo' />
                        </div>

                        <div id='pos' >

                            {['xl'].map((expand) => (

                                <Navbar key={expand} expand={expand} className="bg-body-tertiary landing-navbar ">

                                    <Container fluid>
                                        <img src={orex} alt="" className='logo-small' />

                                        <Navbar.Toggle
                                            aria-controls={`offcanvasNavbar-expand-${expand}`}
                                            onClick={() => setShowOffcanvas(!showOffcanvas)}
                                        />

                                        <Navbar.Offcanvas

                                            show={showOffcanvas}
                                            onHide={() => setShowOffcanvas(false)}
                                            id={`offcanvasNavbar-expand-${expand}`}
                                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                            placement="start"
                                        >
                                            <Offcanvas.Header closeButton>

                                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                                    <h2 className="app-brand-text demo menu-text fw-bold ms-2 ps-1">

                                                    </h2>
                                                </Offcanvas.Title>
                                            </Offcanvas.Header>


                                            <Offcanvas.Body>



                                                <Nav className="justify-content-start flex-grow-1 nav-menu">

                                                    <Nav.Link href="#home"
                                                        className='px-4'
                                                        onClick={hendlehome}>
                                                        <span className='nav-color'>Home</span>
                                                        <span className='ps-4'>|</span>
                                                    </Nav.Link>

                                                    <Nav.Link className='px-3' href='#'>
                                                        <span className='nav-color'> About Us</span>
                                                        <span className='ps-4 sepretor_about'>|</span>
                                                    </Nav.Link>

                                                    <Nav.Link href="#contect"
                                                        className='px-3'
                                                        onClick={() => { nav(ROUTES.HOME) }}>
                                                        <span className='nav-color'>Contact</span>
                                                        <span className='ps-4 sepretor_about'>|</span>

                                                    </Nav.Link>

                                                    <Nav.Link href="#faq" className='nav-color px-3 ' onClick={() => { nav(ROUTES.HOME) }}>
                                                        <span className='nav-color'> FAQ</span>
                                                    </Nav.Link>

                                                    {/* <Nav.Link href="#con" className=' nav-color px-3' onClick={(e) => { hendleScroll(e, blog) }}>Blog <span>|</span></Nav.Link> */}

                                                </Nav>



                                                {/* <p className='mt-3 mx-2 px-1'>Get in Touch : <span className='contect_num'>+91 98745 63256</span> </p> */}


                                                <Link to={"/registration"}>

                                                    <Button className='btn-regi'>  Pre  Registration Open
                                                        <i className="fa-solid fa-angle-right ms-1"></i>
                                                    </Button>

                                                </Link>



                                            </Offcanvas.Body>

                                        </Navbar.Offcanvas>

                                    </Container>

                                </Navbar>
                            ))}

                        </div>

                    </div>

                </div>

                {/* <Sliders /> */}

            </div>
        </div>
    )
}

export default NavSection