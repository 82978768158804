import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';

import API_URL from './config/apiConfig';
import ROUTES from './config/routePath';


import AcounInfo from './AcounInfo';
import { property } from './App';

import regiImg from "./images/registra.jpg";

import errorMessages from './constant/errorMessages';

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Contact_white from "./images/Contact_white.svg"
import compneyInf from "./images/Company_agent.svg"

const invalidEmails = [
    'yopmail.com', 'mailinator.com', '10minutemail.com',
    'guerrillamail.com', 'trashmail.com', 'temp-mail.org',
    'dispostable.com', 'fakeinbox.com', 'sharklasers.com',
    'getairmail.com', 'maildrop.cc', 'spambog.com',
    'mytemp.email', 'throwawaymail.com', 'tempmailaddress.com',
    'mailcatch.com', 'moakt.com', 'temp-mail.io', 'tutanota.com'
];

function ContactDetail() {

    const nav = useNavigate();
   
    const { account, compney, compnytext, RegiIcon } = useContext(property);
    const [phoneValue, setphoneValue] = useState();
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        designation: "",
        phone: "",
        salutation: "",
        page: 3
    });
    const [isButtonDisabled, setisButtonDisabled] = useState(false);
    React.useEffect(() => {
        if (account && account[2]) {
            account[2].style.border = "1px solid #0b4471";
            account[2].style.borderTopLeftRadius = "6px";
            account[2].style.borderTopRightRadius = "6px";
            account[2].style.backgroundColor = "#0b4471";
        }


        if (compney && compney[2]) {
            compney[2].style.color = "white";
        }

        if (compnytext && compnytext[2]) {
            compnytext[2].style.color = "white";
        }

        // ****registration-icon****

        if (RegiIcon && RegiIcon[2]) {
            RegiIcon[2].src = `${Contact_white}`
        }

        if (RegiIcon && RegiIcon[0]) {
            RegiIcon[0].src = `${compneyInf}`
        }

    }, [account, compney, compnytext]);

    const handlePrevClick = () => {
        nav(ROUTES.COMPANYREPRESENTATIVE);
    };

    const data = localStorage.getItem('registration-detail');
    const data2 = localStorage.getItem('company-representative');
    if(data == null || data == "" || data2 == null || data2 == ""){
        nav(ROUTES.REGISTRATION);
    }

    const [array, setArray] = useState([0]);
    const [inputFields, setInputFields] = useState([{ salutation: 'Mr', first_name: '', last_name: '', email: '', designation: '', phone: '' }]);
    const [errors, setErrors] = useState({});
    const handleAddDiv = (event) => {
        if (array.length >= 2) {
            event.target.style.display = "none";
        }
        if (array.length < 3) {
            setArray((prev) => [...prev, array.length]);
            setInputFields([...inputFields, { salutation: 'Mr', first_name: '', last_name: '', email: '', designation: '', phone: '' }]);
        }
    };

const handleRemoveDiv = (idx) => {
        if (idx <= 2) {
            document.getElementById('addButton').style.display = 'block';
        }
        if (array.length > 1) {
            setArray((prev) => prev.filter((_, index) => index !== idx));
            setInputFields((prev) => prev.filter((_, index) => index !== idx));
        }
    };

const handleChange = (e, idx) => {
        const { name, value } = e.target;
        const newInputFields = [...inputFields];
        newInputFields[idx][name] = value;
        setInputFields(newInputFields);
    };

    const handlePhoneChange = (val, idx) => {
        const newInputFields = [...inputFields];
        newInputFields[idx].phone = val;
        setInputFields(newInputFields);
    };

    const validation = () => {
        const newErrors = {};
        let hasError = false;
        const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        inputFields.forEach((field, idx) => {
            if (field.first_name !== '') {
                if (field.email === '' && field.phone === '') {
                    newErrors[`email${idx}`] = errorMessages.FIELD_REQUIRED;
                    newErrors[`phone${idx}`] = errorMessages.FIELD_REQUIRED;
                    hasError = true;
                }
            }
            if (field.email !== '') {
                newErrors[`email${idx}`] = '';
                if ((field.first_name === '') || (field.phone === null)) {
                    newErrors[`first_name${idx}`] = errorMessages.FIELD_REQUIRED;
                    hasError = true;
                }
                const currentEmail = field.email.split('@')[1];
                const isValid = regExp.test(field.email);

                if (!isValid || invalidEmails.includes(currentEmail)) {
                    newErrors[`email${idx}`] = errorMessages.IN_VALID_FIELD_FORMAT;
                    hasError = true;
                }

            }
            if (field.phone !== '') {
                newErrors[`phone${idx}`] = '';
                if ((field.first_name === '') || (field.phone === null)) {
                    newErrors[`first_name${idx}`] = errorMessages.FIELD_REQUIRED;
                    hasError = true;
                }
            }
            if (field.phone) {
                if (!field.phone || !isValidPhoneNumber(field.phone))
                {
                    newErrors[`phone${idx}`] = errorMessages.MOBILE_NUMBER_VALIDATION_MSG;
                    hasError = true;
                } 
                // else{
                //     newErrors[`phone${idx}`] = ''; 
                //     hasError = true; 
                // }
    
            }
            if (field.last_name !== '' || field.designation !== '') {
                if (field.email === '' && field.phone === '') {
                    newErrors[`email${idx}`] = errorMessages.FIELD_REQUIRED;
                    newErrors[`phone${idx}`] = errorMessages.FIELD_REQUIRED;
                    hasError = true;
                }
                if (field.first_name === '') {
                    newErrors[`first_name${idx}`] = errorMessages.FIELD_REQUIRED;
                    hasError = true;
                }
            }

        });

        setErrors(newErrors);
        return !hasError;
    };

  const handleSubmit = (e) => {
        e.preventDefault();
        if (validation()) {
            try {           
                const registrationDetail = localStorage.getItem('registration-detail');
                const registrationData = JSON.parse(registrationDetail);

                const companyDetail = localStorage.getItem('company-representative');
                const companyData = JSON.parse(companyDetail);
                axios.post(API_URL + "/member/store", {
                    company_name: registrationData.company_name,
                    branch_office: registrationData.branch_office,
                    head_office: registrationData.head_office,
                    industry: registrationData.industry,
                    company_size: registrationData.company_size,
                    shipment_mode: registrationData.shipment_mode,
                    website: registrationData.website,
                    with_us:registrationData.with_us,
                    page1: 1,
                    company_first_name: companyData.first_name,
                    company_last_name: companyData.last_name,
                    country_id: companyData.country_id,
                    city_id: companyData.city_id,
                    company_designation: companyData.designation,
                    company_salutation: companyData.salutation,
                    mobile: companyData.mobile,
                    company_email: companyData.email,
                    company_phone: companyData.phone,
                    isNewVal:companyData.isNewVal,
                    sal:companyData.sal,
                    page2: 2,
                    salutation: inputFields.map(field => field.salutation),
                    first_name: inputFields.map(field => field.first_name),
                    last_name: inputFields.map(field => field.last_name),
                    email: inputFields.map(field => field.email),
                    phone: inputFields.map(field => field.phone),
                    designation: inputFields.map(field => field.designation),
                    page3: 3
                }, {
                    headers: {
                        "Accept": "application/json"
                    }
                }).then((response) => {
                    if (response.status === 200 && response.data.status === true) {
                        localStorage.removeItem('company-representative');
                        localStorage.removeItem('registration-detail');
                        localStorage.removeItem('city-detail');
                        localStorage.setItem('successMessage', 'Registration Successfully.....');
                        nav(ROUTES.REGISTRATION);
                    }
                    let newErrors = {};
                    setErrors({});
                    if (response.data.status === false) {
                        const result = Object.entries(response.data.error);
                        result.forEach(([key, value]) => {
                            newErrors[key] = value;
                        });
                        setErrors(newErrors);
                    }

                }).catch((error) => {
                    console.log('Error fetching data:', error);
                });
            

            } catch (err) {
                console.log(err);
            }
        }

    };

    const handleFocusOut = (e, idx) => {
        const { name, value } = e.target;
        let hasError = false;

        if (name === "first_name") {
            if (value !== '') {
                const regExp = /^[a-zA-Z][a-zA-Z\d\s]*$/;
                const isValid = regExp.test(value);

                if (!isValid) {
                    hasError = true;
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`first_name${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
                    }));
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`first_name${idx}`]: '',
                    }));
                }
            } else {
                if(value.length > 0){
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`first_name${idx}`]: errorMessages.FIELD_REQUIRED,
                }));
                }
            }
        }

        if (name === "last_name") {
            const regExp = /^[a-zA-Z][a-zA-Z\d\s]*$/;
            const isValid = regExp.test(value);

            if (value.length > 0 && !isValid) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`last_name${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`last_name${idx}`]: '',
                }));
            }
        }

        if (name === "email") {

            const currentEmail = value.split('@')[1];
            const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const isValid = regExp.test(value);

            if (value.length > 0 && !isValid || invalidEmails.includes(currentEmail)) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`email${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`email${idx}`]: '',
                }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`phone${idx}`]: '',
                }));
            }

            const isDuplicateEmail = inputFields.some((item, index) => item.email === value && index !== idx);
            if (value.length > 0 && isDuplicateEmail) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`email${idx}`]: errorMessages.UNIQUE_MSG,
                }));
            }else{

                if (value.length > 0 && !isDuplicateEmail) {
                    setErrors((prevErrors) => {
                        let updatedErrors = { ...prevErrors };
                        Object.keys(updatedErrors).forEach((key) => {
                            if (key.startsWith('email')) {
                                updatedErrors[key] = '';
                            }
                        });
                        return updatedErrors;
                    });
                }else{
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`email${idx}`]: '',
                    }));
                }
                
            }
            const isDuplicateEmail2 = inputFields.some((item, index) => item.email === value && index !== idx);

            const dataEml = localStorage.getItem('company-representative');
            const company = dataEml ? JSON.parse(dataEml) : null;
            const isDuplicateEmail1 = company && company.email === value;
    
            if (value.length > 0 && (isDuplicateEmail2 || isDuplicateEmail1 || isDuplicateEmail)) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`email${idx}`]: errorMessages.UNIQUE_MSG,
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`email${idx}`]: '',
                }));
            }
            
            
            
        }

        if (name === "designation") {
            const regExp = /^[A-Za-z][A-Za-z0-9 ,&.-]*$/;
            const isValid = regExp.test(value);

            if (value.length > 0 && !isValid) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`designation${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`designation${idx}`]: '',
                }));
            }
        }
        

        if (name === 'phone') {
            let mobileValue = value;
            if (mobileValue) {
                if (!mobileValue || !isValidPhoneNumber(mobileValue)){
                    hasError = true;
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`phone${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
                    }));
                }else{
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`phone${idx}`]: '',
                    }));
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`email${idx}`]: '',
                    }));
                }
            }

            const normalizePhone = (phone) => phone.replace(/\D/g, ''); 
            const isDuplicatePhone = inputFields.some((item, index) => {
            return normalizePhone(item.phone) === normalizePhone(value) && index !== idx;
            });

            if (value.length > 0 && isDuplicatePhone) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`phone${idx}`]: errorMessages.UNIQUE_MSG,
                }));
            } else {
                if (value.length > 0 && !isDuplicatePhone) {
                    setErrors((prevErrors) => {
                        let updatedErrors = { ...prevErrors };
                        Object.keys(updatedErrors).forEach((key) => {
                            if (key.startsWith('phone')) {
                                updatedErrors[key] = '';
                            }
                        });
                        return updatedErrors;
                    });
                }

            } 
        }
        setisButtonDisabled(hasError);
        return hasError;
    };

    const styleError = {
        display: 'block',
    };

    return (

        <div>
            <div className="main-regi">
                <div className="regi-menIMG">
                    <img src={regiImg} />
                </div>

                <div className="regi-form">

                    <AcounInfo />

                    <form id="company-representative" className='border-area' onSubmit={handleSubmit}>

                        <div className='contect-btn'>

                            <h4>Additional contact information</h4>

                            <button type="button" className='add-remove add-efect'
                             onClick={handleAddDiv} id="addButton">

                                <span className='remove_icon add-icon-color'>+</span>
                                
                                Add</button>

                        </div>


                        {array.map((item, idx) => (
                            <div key={item} id={idx} className="dynamicDiv">
                                {idx !== 0 && (
                                    <div className='contect-btn-remove'>
                                        <button onClick={() => handleRemoveDiv(idx)} className='add-remove remove-efect'>
                                            <span className='remove_icon '> -</span>
                                            Remove
                                        </button>

                                    </div>
                                )}
                                <div className="row px-5">
                                    <div className="justify-content-between mt-3">
                                       {/* <button onClick={handleAddDiv} className="btn-success text-white add-button" id="addButton">Add</button> */}
                                    </div>
                                    <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                        <label className="form-label">First Name</label>
                                        <div className="input-group">
                                            <span className="input-group-addon">
                                                <select
                                                    name="salutation"
                                                    value={inputFields[idx]?.salutation}
                                                    onChange={(e) => handleChange(e, idx)}>

                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                    <option value="Miss">Miss</option>
                                                </select>
                                            </span>
                                            <input
                                                type="text"
                                                name="first_name"
                                                className="form-control"
                                                placeholder="Enter first name"
                                                value={inputFields[idx]?.first_name}
                                                onChange={(e) => handleChange(e, idx)}
                                                onBlur={(e) => handleFocusOut(e, idx)}
                                            />
                                            {errors[`first_name${idx}`] && (<div className="invalid-feedback" style={styleError}>
                                                {errors[`first_name${idx}`]}
                                            </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                        <label className="form-label">Last Name</label>
                                        <input type="text" name="last_name" id="last_name" className="form-control" onChange={(e) => handleChange(e, idx)} placeholder="Enter last name" onBlur={(e) => handleFocusOut(e, idx)} value={inputFields[idx]?.last_name} />
                                        {errors[`last_name${idx}`] && (<div className="invalid-feedback" style={styleError}>
                                            {errors[`last_name${idx}`]}
                                        </div>
                                        )}
                                    </div>

                                    <div className="col-sm-6 mt-3 fv-plugins-icon-container">

                                        <label className="form-label">Email</label>
                                        <input type="text" name="email" id="email" className="form-control" onChange={(e) => handleChange(e, idx)} placeholder="Enter email" onBlur={(e) => handleFocusOut(e, idx)} value={inputFields[idx]?.email} />
                                        {errors[`email${idx}`] && (<div className="invalid-feedback" style={styleError}>
                                            {errors[`email${idx}`]}
                                        </div>
                                        )}
                                    </div>

                                    <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                        <label className="form-label">Phone</label>
                                        <PhoneInput id="contact-form-phone" className="form-control inquiry-phone inp-font-size border-dropdown" name="phone"
                                            international limitMaxLength
                                            placeholder="Enter contact"
                                            onChange={(e) => handlePhoneChange(e, idx)}
                                            value={inputFields[idx]?.phone}
                                            onBlur={(e) => handleFocusOut(e, idx)}
                                        />
                                        {errors[`phone${idx}`] && (<div className="invalid-feedback" id={idx} style={styleError}>
                                            {errors[`phone${idx}`]}
                                        </div>
                                        )}
                                    </div>

                                    <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                        <label className="form-label">Designation</label>
                                        <input type="text" name="designation" id="designation" className="form-control" onChange={(e) => handleChange(e, idx)} placeholder="Enter designation" onBlur={(e) => handleFocusOut(e, idx)} value={inputFields[idx]?.designation} />
                                        {errors[`designation${idx}`] && (<div className="invalid-feedback" style={styleError}>
                                            {errors[`designation${idx}`]}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="col-12 d-flex justify-content-between  mt-3  ">

                            <button onClick={handlePrevClick} className="btn-regi regi-next ms-5 text-white border-0">
                                Prev
                            </button>

                            <button type="submit" className="btn-regi text-white border-0 me-5 regi-next" disabled={isButtonDisabled}>Save

                            </button>

                        </div>
                    </form>

                </div>

            </div>

        </div>

    )
}

export default ContactDetail  
