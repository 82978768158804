import React from 'react'
import {  Slider } from 'rsuite';
import "rsuite/dist/rsuite.css";

function RangeField() {
    return (
        <div className='ragnefield w-100'>

            <Slider

                progress
                defaultValue={50}
                onChange={value => {
                    console.log(value);
                }}
            />
          

        </div>
    )
}

export default RangeField
