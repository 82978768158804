import { indexOf } from 'lodash';
import React from 'react'
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import includes from "lodash/includes";

import InputField from "../components/InputField"
import MultiSelectedField from '../components/MultiSelectedField';
import redgirl from '../images/redgirl.png';

function FileUploadMultiple() {

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };


  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    maxFiles: 10,
    maxFilesize: 10, // MB
    addRemoveLinks: true,
    accept: {
      'image/*': [],
      'application/pdf': []
    },
    onDrop: acceptedFiles => {
      console.log("acceptedFiles >>>> ", acceptedFiles);
      let newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));     
      setFiles([...files, ...newFiles]);
    },
    noClick: false,
  });

  const removeFile = (fileName) => {
    const newFiles = [...files]
    for (let file of newFiles) {
      if (file.name == fileName) { // Add only file name matched file            
        newFiles.splice(newFiles.indexOf(file), 1);
      }
    }
    setFiles(newFiles)
  }





  const thumbs = files.map(file => ( 
    <div style={thumb} key={file.name} className='multi-upload'>
      {/* <p>{JSON.stringify(file)}</p>
      <p>{JSON.stringify(file.type)}</p> */}
      <div style={thumbInner}>
        { file.type === 'application/pdf' && <img src={(file.type === 'application/pdf')? redgirl : file.preview}
          style={img}
          // Revoke data uri after image is loaded
          alt=""
          // onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />

        }
        {
          includes(['image/png', 'image/jpg', 'image/jpeg'], file.type) && <img src={(file.type === 'application/pdf')? redgirl : file.preview}
          style={img}
          // Revoke data uri after image is loaded
          alt=""
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
        }
        
      </div>

      {/* <a id={file.indexOf()}>Remove File</a>   */}
      {/* <button onClick={removeFile(file)}>Remove File</button> */}

      <button
        onClick={() => removeFile(file.name)}><i className="fa-regular fa-trash-can"></i>
        </button>
    </div>

  ),
    [removeFile, files]);


  return (
    <div className='container-fluid'>

      <div className='row'>

        <section className='mt-5 col-xl-5 '>
          <div {...getRootProps({ className: 'dropzone' })}>
            
            <input {...getInputProps()}/>

            <div className='dropzone-files'>
              <div className='upload-certificate'>Upload your Company certificate here</div>
              <div className='choose'>Choose File</div>
            </div>
            
         
          </div>

          <p className='upload-certificate my-2'>Allowed JPG, GIF or PNG. Max size of 10 MB</p>

          <aside style={thumbsContainer}>
            {thumbs}
          </aside>

        </section>
      </div>

    </div>
  )
}

export default FileUploadMultiple
