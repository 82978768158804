import React, { useState } from 'react'
import { MultiSelect } from 'react-multi-select-component';

function MultiSelectedField() {

    const [selected, setSelected] = useState([]);

    const options = [
        { label: "Grapes ", value: "grapes" },
        { label: "Mango ", value: "mango" },
        { label: "Strawberry ", value: "strawberry", disabled: true },
      ];


  return (
    <div>
     <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy="Select"
    
      />
    </div>
  )
}

export default MultiSelectedField
