import React, { useEffect, useState } from 'react'
import inputicon from "../images/Image _placeholder.svg"

function FileUploadPDF() {

    const style2 = {
        height: '50px',
        width: '50px',
    };


    let pdf_close = document.getElementsByClassName("pdf-close")


    console.log()

    useEffect(() => {

        let jadav = () => {
            pdf_close[0].style.display = "none"
        }
        jadav()

    }, [])


    const [filenamepdf, setFilenamepdf] = useState('');

    const [selectedFilepdf, setSelectedFilepdf] = useState(inputicon);

    let handleChangePdf = (e) => {

        const selectedFilepdf = e.target.files[0];
        console.log(e.target.files[0])

        if (selectedFilepdf) {
            // setFile(selectedFilepdf);
            setFilenamepdf(selectedFilepdf.name);
            previewFilepdf(selectedFilepdf);
            pdf_close[0].style.display = "block"
        }


    }

    let previewFilepdf = (file) => {

        const reader = new FileReader();

        reader.onload = () => {

            if (file.type === "application/pdf") {
                setSelectedFilepdf("https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/800px-PDF_file_icon.svg.png");
            }

            else {
                setSelectedFilepdf(reader.result);
            }

        };
        reader.readAsDataURL(file);

    }

    let handleRemove = () => {
        setSelectedFilepdf(inputicon);
        setFilenamepdf("");
        pdf_close[0].style.display = "none"
        document.querySelector("#formFile").value = ""

    }



    return (
        <div>
            <div className='row m-5'>

                <div className='col-xl-3 '>

                    {/* <label for="formFile" className="form-label">Choose File</label> */}
                    <input className="form-control choose-pdf"
                        type="file"
                        id="formFile"
              
                        onChange={handleChangePdf}

                    />
                     <p className='upload-certificate my-2'>Allowed JPG, GIF or PNG. Max size of 10 MB</p>

                </div>

                <div className='col-xl-12 mt-2 d-flex'>
                    <img src={selectedFilepdf} alt="" style={style2} id="pdf-img" />
                    <span className='mx-3'>{filenamepdf}</span>

                    <button className='btn btn-secondary pdf-close' onClick={handleRemove}>
                        <i className="fa-regular fa-trash-can"></i>
                    </button>
                </div>

            </div>

        </div>
    )
}

export default FileUploadPDF
