import React from 'react'
import { Button, Modal } from 'react-bootstrap'


function MediumPopup(props) {
    return (
        
        <div>
            <Modal

                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.mediummodalShow}
                onHide={() => props.setMediumModalShow(false)}

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='modal-title'>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className='btn-regi dialog-btn'
                        onClick={() => props.setMediumModalShow(false)}
                    >Close</Button>

                    <Button
                        type={props.type}
                        onClick={props.onSubmit}
                        className='btn-regi dialog-btn'

                    >Submit</Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default MediumPopup