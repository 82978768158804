import React from 'react'
import Select from 'react-select';

function DropDown({ options,value,onChange,placeholder,name,id }) {

    return (
        <div>
            <Select
                className='w-100'
                options={options}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                name={name}
                id={id}
            />
        </div>
    )
}

export default DropDown
