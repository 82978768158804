import React from 'react'
import account from "../images/redgirl.png"

function TopMenu() {

    return (

        <div>
            <div className="row">

                <div className='' >

                    <div className="card mb-3 p-2 topmenut-nav">

                        <div className="d-flex justify-content-between mx-3">

                            <div className="col-xxl-4 d-flex align-items-center">

                                <i className="fa-solid fa-magnifying-glass fs-5 "></i>

                                <div className="form-group">
                                    <input type="email" className="form-control border-0 nav-input-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                                </div>

                            </div>

                            <div className="col-xxl-2  d-flex align-items-center justify-content-end ">

                            <i className="fa-regular fa-bell text-danger fs-4 mx-3"></i>


                                <div className="nav-item navbar-dropdown dropdown-user dropdown mx-2 cursor-pointer">

                                    <p className="nav-link dropdown-toggle  nav-account-img" 

                                    data-bs-toggle="dropdown" aria-expanded="false">

                                        <img src={account} alt="" class="rounded-circle" />

                                    </p>

                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li>
                                            <p className="dropdown-item">

                                                <div className="d-flex">

                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-0">John Doe</h6>
                                                        <small className="text-muted">Admin</small>
                                                    </div>

                                                </div>
                                            </p>
                                        </li>

                                        <li className="dropdown-item">
                                            <span>My Profile</span>

                                        </li>

                                        <li className="dropdown-item">
                                            <span>Settings</span>

                                        </li>

                                        <li className="dropdown-item">

                                            <span className="d-flex align-items-center align-middle">
                                                <span className="flex-grow-1 align-middle">Billing Plan</span>
                                                <span className="flex-shrink-0 badge rounded-pill bg-danger">4</span>

                                            </span>

                                        </li>

                                        <li className="dropdown-item">
                                            <span>Log Out</span>

                                        </li>

                                    </ul>

                                </div>

                            </div>


                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default TopMenu
