import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux';

import isEmpty from "lodash/isEmpty";

import { loginAction } from '../actions/loginAction';
import InputField from '../components/InputField';
import CheckBox from '../components/CheckBox';

import loginLogo from "../images/orexlogo.svg"
import loginImg from "../images/login_img.png"
import loginIcoin from "../images/login_icon.png"

import "../Orex.css"
import errorMessages from '../constant/errorMessages';
import ROUTES from '../config/routePath';

function Login(props) {
    let nav = useNavigate("");
    const email1 = localStorage.getItem('email');
    const password1 = localStorage.getItem('password');
    const rememberMeToken = localStorage.getItem('rememberMeToken');
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState(email1);
    var [isButtonDisabled, setisButtonDisabled] = useState(false);

    let decodedPassword = password1;

    if (!isEmpty(password1)) {
        decodedPassword = atob(password1);
    } else {
        decodedPassword = null;
    }

    const [password, setPassword] = useState(decodedPassword);
    const [showPassword, setshowPassword] = useState(false);
    const [isRememberMeChecked, setisRememberMeChecked] = useState(false);

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleShowPassword = () => {
        setshowPassword(!showPassword)
    }

    useEffect(() => {
        if (rememberMeToken) {
            setisRememberMeChecked(true);
        } else {
            setisRememberMeChecked(false);
        }
    }, [rememberMeToken]);
    const handleChange = (e) => {

        setisRememberMeChecked((prev) => !prev);

      

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validation()) {
            setisButtonDisabled(true);
            await props.login(email, password, isRememberMeChecked)
                .then((response) => {
                    setisButtonDisabled(false);
                    if (response.success) {
                        nav(ROUTES.HOME);
                    } else {
                        const errors = {};

                        if (!response.success) {
                            
                            if (response.message === "Validation Error.") {
                                if (!isEmpty(response.data.email)) {
                                    errors.email = response.data.email[0];
                                }
                                if (!isEmpty(response.data.password)) {
                                    errors.password = response.data.password[0];
                                }
                            }
                            if (response.message === "Unauthorised.") {
                                errors.unauthorised = response.data.error;
                            }
                            if (response.message === "The email is not registered.") {
                                errors.noEmail = response.data.error;
                            }
                            if (!response) {
                                errors.somethingWentWrong = "Something went wrong";
                            }
                        }

                        setErrors(errors);
                    }
                })
                .catch((error) => {
                    setisButtonDisabled(false);
                    
                });
        }
    };


    const handleKeyDown = (e) => {
      
        if (e.code === 'Space') {
            e.preventDefault();
        }
    };
    const validation = () => {
        const errors = {};
        if (email == '' || email == null) {
            errors.email = errorMessages.EMAIL_REQUIRED;
        } else if (emailPattern.test(email) == false) {
            errors.invalidEmail = errorMessages.INVALID_EMAIL;
        }
        if (password == '' || password == null) {
            errors.password = errorMessages.PASSWORD_REQUIRED;
        }
        setErrors(errors);
        return Object.keys(errors).length > 0;
    };

    const styleError = {
        display: 'block',
    };
    return (
        <div>

            <div className='login'>

                <div className='logindesboard_img'>
                    <img src={loginImg} alt="" />
                </div>

                <div className='login-card'>

                    <div className='card shadow border-0 p-4'>

                        <div className='loginpage-img'>

                            <img src={loginLogo} alt="" />

                        </div>

                        <div className='mt-4'>

                            <div className='d-flex align-items-center'>
                                <h3 className='login-title'>Welcome to Orex Trade</h3>
                                <img className='login-icon' src={loginIcoin} alt="" />
                            </div>

                            <p className='login-subtitle'>please sign-in to your account and start the trade</p>

                        </div>

                        {errors.unauthorised && <div className="invalid-feedback" style={styleError}>{errors.unauthorised}</div>}
                        {errors.noEmail && <div className="invalid-feedback" style={styleError}>{errors.noEmail}</div>}
                        {errors.somethingWentWrong && <div className="invalid-feedback" style={styleError}>{errors.somethingWentWrong}</div>}

                        <div>

                            <form>

                                <div className="mt-3">

                                    <label className='required'>Email</label>

                                    <div className='w-100 mt-1'>
                                        <InputField type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={handleKeyDown} />
                                        {errors.email && <div className="invalid-feedback" style={styleError}>{errors.email}</div>}
                                        {errors.invalidEmail && <div className="invalid-feedback" style={styleError}>{errors.invalidEmail}</div>}

                                    </div>

                                </div>


                                <div className='mt-3'>

                                    <label className='required'>Password</label>

                                    <div className='d-flex mt-1'>

                                        <div className="w-100">

                                            <InputField type={(showPassword === true) ? "text" : "password"} placeholder="Enter password" className="border-end-0  login-input" name="password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown} />

                                        </div>

                                        <span className="input-group-text" onClick={handleShowPassword}>
                                            <i className="fa-regular fa-eye"></i></span>


                                    </div>
                                </div>


                                {errors.password && <div className="invalid-feedback" style={styleError}>{errors.password}</div>}

                                <div className='d-flex justify-content-between mt-4'>


                                    <div className="form-check">

                                        <CheckBox className="form-check-input login-check" value={isRememberMeChecked} onChange={handleChange} checked={isRememberMeChecked} />

                                        <label className="form-check-label " for="flexCheckIndeterminate">
                                            Remeber me
                                        </label>

                                    </div>

                                    <div>
                                        <span className='login-text' onClick={() => { nav(ROUTES.FORGOT_PASSWORD) }}>Forgot password?</span>
                                    </div>

                                </div>

                                <button type="submit " className="btn login-btn w-100 mt-4 " disabled={isButtonDisabled} onClick={handleSubmit}  >Login</button>

                                <p className='mt-3 mb-4 text-center'>
                                    <span className='login-subtitle'>New on our platform? </span>
                                    <span className='login-text' onClick={() => { nav(ROUTES.REGISTRATION) }}>Create an account</span>
                                </p>

                            </form>


                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        auth: state.auth.login,
        
    }
}
const mapDispatchToProps = dispatch => {
    return {
        login: (email, pswd, isRememberMeChecked) => dispatch(loginAction(email, pswd, isRememberMeChecked))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);