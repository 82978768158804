import React from 'react'
import snet from "../src/images/dashboard.jpg"
import { Link } from 'react-router-dom'

function Redy() {
    return (
        <div>

            <div id="landingCTA" >

            
                <div className="container">

                    <div className="row align-items-center  gy-12">

                        <div className="col-lg-6 text-start text-sm-center text-lg-start text-center my-5">


                            <h3 className="cta-title fw-bold ">Ready to Get Started?</h3>
                            <h5 className="text-body with-our text-start fs-5 redy-center">We are Collecting Pre-registraion data</h5>
                            <Link to={"/registration"}><button href="payment-page.html" className="btn-get mt-3">Get Started</button></Link>
                            

                        </div>

                        <div className="col-lg-6 pt-lg-12 text-center text-lg-end">
                            <img src={snet} alt="cta dashboard" className="img-fluid mt-lg-4" />
                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default Redy