import React from 'react'
import { DatePicker } from 'rsuite'
import 'rsuite/DatePicker/styles/index.css';


function DatePickerField({placeholder}) {
    return (
        <div>
            <DatePicker
            className='w-100'
            placeholder="yyyy-mm-dd"
              />
        </div>
    )
}

export default DatePickerField;