import axios from "axios";
import isEmpty from 'lodash/isEmpty';
import API_URL from "../config/apiConfig";


export const getUserList = (offset,limit,searchTxt = null) => async (dispatch) => {

    const searchQueryString = isEmpty(searchTxt) ? '' : +'/'+searchTxt;
    return await axios.get(API_URL+'/users/'+offset+'/'+limit+searchQueryString).then((response) => {
        if(response.status === true){
            dispatch({ type: "LIST_USERS", payload: response });
        }
        return response.data;
    }).catch((err) => {
        console.log("Error ==== ", err);
    })
}

export const createUser = (data) => async (dispatch) => {

    return await axios.post(API_URL+'/users',data).then((response) => {
        console.log(" API response in userAction File ", response);
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    })
}

export const deleteUser = (id) => (dispatch) => {
    return axios.delete(API_URL+'/users/'+id).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    });
}

export const getUser = (id) => (dispatch) => {
    return axios.get(API_URL+'/user/'+id).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    });
}