import React from 'react'
import FileUpload from '../pages/FileUpload'
import FileUploadPDF from '../pages/FileUploadPDF'
import FileUploadMultiple from '../pages/FileUploadMultiple'

function FilePicker() {
  return (
    <div>

        <FileUpload/>
        <FileUploadPDF/>
        <FileUploadMultiple/>
      

        
    </div>
  )
}

export default FilePicker
