const ROUTES = {
    PERSONAL : '/personal',
    REGISTRATION: '/registration',
    ABOUT: '/about',
    HOME: '/',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgotpassword',
    ACCOUNTDETAIL:'/account-detail',
    COMPANYREPRESENTATIVE:'/company-representative',
    CONTACTDETAIL:'/contact-detail'
};

export default ROUTES;
