import React from 'react'
import connectImg from "./images/New_section.svg"

function ConnectingBuyer() {
    return (
        <div>

            <div className="how_we">

                <div className='boost'>
                    <p >Secure Partner</p>
                </div>

                <h5 > <span className='fw-bold'> Connecting </span>
                    <span className='boost-span'>
                        <span className='fw-bold'> Buyers & Suppliers   </span> with
                    </span>
                    Confidence!
                </h5>

                <p className='with-our'>
                    Providing Buyers & Suppliers with financial protection, real data, and guaranteed trade solutions for secure business growth.
                </p>

            </div>

            <div>

                <div className='bissines connectin_section'>


                    <div className="b-img connect_buyer">

                        <h5 className='mb-3'> How Can OrexTrade <span className='fw-bold text-start'>Benefit You? </span></h5>

                        <p>We are your helping hand for buyers and suppliers, a trusted platform with secure <br />
                            financial protection.</p>


                        <div className='d-flex align-items-center  mb-2'>

                            <div>
                                <i className="fa-solid fa-chevron-right mx-2"></i>

                            </div>

                            <div>
                                <span>Helping buyers and suppliers connect with ease
                                </span>
                            </div>

                        </div>



                        <div className='d-flex align-items-center mb-2'>

                            <div>
                                <i className="fa-solid fa-chevron-right mx-2"></i>
                            </div>

                            <div>
                                <span>A trusted platform for seamless trade interactions</span>
                            </div>
                        </div>


                        <div className='d-flex align-items-center mb-2'>

                            <div>
                                <i className="fa-solid fa-chevron-right mx-2"></i>
                            </div>

                            <div>
                                <span>Secure financial protection for all transactions</span>
                            </div>

                        </div>


                        <div className='d-flex align-items-center mb-2'>

                            <div>
                                <i className="fa-solid fa-chevron-right mx-2"></i>
                            </div>

                            <div>
                                <span>Real-time, AI-powered data solutions for informed decisions
                                </span>
                            </div>
                        </div>


                        <div className='d-flex align-items-center mb-2'>

                            <div>
                                <i className="fa-solid fa-chevron-right mx-2"></i>
                            </div>

                            <div>
                                <span>Empowering businesses with accurate and reliable trade intelligence</span>
                            </div>
                        </div>


                        <div className='d-flex align-items-center mb-2'>

                            <div>
                                <i className="fa-solid fa-chevron-right mx-2"></i>
                            </div>

                            <div>
                                <span>Ensuring guaranteed trade with secure processes</span>
                            </div>
                        </div>

                    </div>

                    <div className="b-img">
                        <img src={connectImg} alt='' />
                    </div>

                </div>

            </div>

        </div>
    )
}

export default ConnectingBuyer
