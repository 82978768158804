import React, { useEffect, useState } from 'react'
import blog from "../src/images/successful-blog-post.png"
import { getUser } from './actions/userAction'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import API_URL from './config/apiConfig'

function TestBlog() {
    const { id } = useParams();

    console.log("id ========", id);

    const [usersList, setUsersList] = useState({
        company_name: '',
        email: '',
        member_id: '',
        website: '',
        name: ''
    });


    const getMember = async (id) => {
        console.log("APIURL =========", API_URL);
        await axios.get("https://orextrade.com/v1/api/members/"+id).then((response) => {
            console.log("response ===========", response);
            setUsersList({
                member_id: response.data.data.id,
                company_name: response.data.data.company_name,
                email: response.data.data.email,                
                website: response.data.data.website,
                name : response.data.data.first_name+ ' '+ response.data.data.last_name
            })
        }).catch((err) => {
            console.log("Error ========== ", err);
        });
    }

    useEffect(() => {
        getMember(id);
    }, []);
    return (
        <div>

            <div >
            <p>{ usersList && usersList.name} </p>
            <p>{ usersList && usersList.email} </p>
            <p>{ usersList && usersList.member_id} </p>
            <p>{ usersList && usersList.company_name} </p>
            <p>{ usersList && usersList.website} </p>
            </div>
        </div>
    )
}

const mapStateToProps = state => {

    return {
        user_list: state.users.userlist,
        member_list: state.member.memberlist,
        // authError: state.auth.errorMessages
    }
}
const mapDispatchToProps = dispatch => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (TestBlog);