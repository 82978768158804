import React from 'react'
import { RangeSlider } from 'rsuite'

const RangemultiFeild = () => {
    
    return <RangeSlider
        defaultValue={[10, 50]}
    />

}

export default RangemultiFeild
