import React from 'react'
import { DatePicker } from 'rsuite'

function TimePickerField() {
  return (
    <div>
      <DatePicker
       format="HH:mm:ss"
       placeholder="hh:mm:ss"
       className='w-100'
        />
    </div>
  )
}

export default TimePickerField
