import React, { useState, useEffect } from "react";

import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

// const NoOptionsMessage = (props) => {
//   return (
//     <span className="no-option-message">Start typing to add a option</span>
//   );
// };

const Menu = (props) => {
    if (props.selectProps.inputValue.length === 0) return null;

    return <components.Menu {...props} />;
};



const FormatCreateOption = (inputValue) => {
    return (
        <span className="create-option">
            <span className="material-icons-outlined">add_circle_outline</span>&nbsp;
            <strong>Add</strong>&nbsp; {inputValue}
        </span>
    );
};


const componentsWithAlwaysOnPrompt = {
    DropdownIndicator: null,
    Menu: Menu
    // NoOptionsMessage: NoOptionsMessage
};



const createOption = (label) => ({
    label,
    value: label
});

const CreatableTagsInput = ({ tags = [], onUpdate = () => { } }) => {
    const [inputValue, setInputValue] = useState("");

    const handleChange = (value, actionMeta) => {
        // console.group("Value Changed");
        // console.log(value);
        // console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        onUpdate(value);
    };

    const handleInputChange = (val) => {
        setInputValue(val);
    };

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        if (event.key === "Enter" || event.key === "Tab") {
            // console.group("Value Added");
            // console.log(tags);
            // console.groupEnd();
            setInputValue("");
            let newTags = [...tags, createOption(inputValue)];
            onUpdate(newTags);
            event.preventDefault();
        }
    };

    return (



        <CreatableSelect
            components={componentsWithAlwaysOnPrompt}
            inputValue={inputValue}
            isClearable={false}
            isMulti
            onChange={handleChange}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Type something and press enter..."
            value={tags}
            formatCreateLabel={FormatCreateOption}
            className="w-100"
        />


    );
};

export default CreatableTagsInput;
