import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import isEmpty from "lodash/isEmpty";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { forgotPasswordAction } from '../actions/loginAction';
import InputField from '../components/InputField'
import "../Orex.css"
import loginLogo from "../images/orexlogo.svg"
import forgot from "../images/forgot_password_img.svg"
import loginIcoin from "../images/login_icon.png"
import errorMessages from '../constant/errorMessages';



function ForgotPasswordForm(props) {
  const nav = useNavigate("");
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState();
  const successMessage = (res) => toast.success(res, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
  var [isButtonDisabled, setisButtonDisabled] = useState(false);

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setisButtonDisabled(true);
    if (!validation()) {
      setisButtonDisabled(true);
      await props.forgotPassword(email)
        .then((response) => {
          setisButtonDisabled(false);
          if (response.message == "We have emailed your password reset link!") {
            // nav(ROUTES.HOME);
            successMessage("We have emailed your password reset link!");
          } else {
            const errors = {};
            if (!response.success) {
              console.log('HERE222222222', response);
              if (response.message === "Validation Error.") {
                if (!isEmpty(response.data.email)) {
                  errors.email = response.data.email[0];
                }
              }
              if (response.message === "The email is not registered.") {
                errors.email = response.data.error;
              }
              if (!response) {
                errors.somethingWentWrong = "Something went wrong";
              }
            }
            setErrors(errors);
          }
        })
        .catch((error) => {
          setisButtonDisabled(false);
          console.log(error);
        });
    }
  };

  const handleKeyDown = (e) => {
    // console.log(e.code);
    if(e.code === 'Space') {
        e.preventDefault();
    }    
    };

  const validation = () => {
    const errors = {};
    if (email == '' || email == null) {
      errors.email = errorMessages.EMAIL_REQUIRED;
    } else if (emailPattern.test(email) == false) {
      errors.invalidEmail = errorMessages.INVALID_EMAIL;
    }

    setErrors(errors);
    return Object.keys(errors).length > 0;
  };

  const styleError = {
    display: 'block',
  };

  return (

    <div>
      <div className='login'>

        <div className='logindesboard_img'>
          <img src={forgot} alt="" />
        </div>

        <div className='login-card'>

          <div className='card shadow border-0 p-4 card-forgot'>

            <div className='loginpage-img'>

              <img src={loginLogo} alt="" />

            </div>

            <div className='mt-4'>

              <div className='d-flex align-items-center'>
                <h3 className='login-title'>Forgot Password?</h3>
                <img className='login-icon' src={loginIcoin} alt="" />
              </div>

              <p className='login-subtitle'>Enter you email and we'll send you instructions to reset your password</p>

            </div>

            <div>
              {errors.somethingWentWrong && <div className="invalid-feedback" style={styleError}>{errors.somethingWentWrong}</div>}
              <form className="custom-form">

                <div className="mt-3">

                  <label className="required">Email</label>

                  <div className="w-100">

                    <InputField type="email" placeholder="Enter email" className="w-100 mt-1" name="email" value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={handleKeyDown}/>
                    {errors.email && <div className="invalid-feedback" style={styleError}>{errors.email}</div>}
                    {errors.invalidEmail && <div className="invalid-feedback" style={styleError}>{errors.invalidEmail}</div>}

                  </div>

                </div>

                <button type="submit " className="btn login-btn w-100 mt-4 " onClick={handleSubmit} disabled={isButtonDisabled}>Send reset link</button>

                <p className='mt-4 text-center'>


                  <button className='btn back-home' onClick={() => { nav("/login") }}>
                    <i className="fa-solid fa-angle-left mx-1"></i>
                    Back to login
                  </button>

                </p>

              </form>


            </div>
          </div>

        </div>

      </div>
      <ToastContainer>
      </ToastContainer>

    </div>
  )
}
const mapStateToProps = state => {
  return {
    auth: state.auth.login
  }
}
const mapDispatchToProps = dispatch => {
  return {
    forgotPassword: (email) => dispatch(forgotPasswordAction(email))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);