import React from 'react'
import { Button } from 'react-bootstrap';
import { Bounce, toast, ToastContainer } from 'react-toastify';

function AlertError() {

    const Error = () => toast.error('invalide!!!', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

  return (
    <div>
      <Button onClick={Error} className='btn btn-danger mx-2'>Error</Button>
      <ToastContainer/>
    </div>
  )
}

export default AlertError
