import React from 'react'
import { Input } from 'rsuite'

const TextAreaField = () => (



    <div>

        <Input
            as="textarea"
            rows={3}
            placeholder="Enter Your Text"
           
        />


    </div>


)

export default TextAreaField