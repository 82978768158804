import React from 'react'
import maintenance_img from "../images/maintenance_images.svg"
import orexlogo from "../images/orexlogo.svg";


function Maintenance() {

    return (

        <div className='maintenance'>

            <div className='error404_logo m_logos'>

                <div>
                    <img src={orexlogo} alt="" />
                </div>
            </div>

            <div className='maintenance_section'>

                <div className='maintenace_Text'>

        
                    <div className='d-flex justify-content-center'>
                        <div>
                            <h1>Website</h1>
                            <h2>Under Maintenance </h2>
                            <p>Sorry for the inconvenience but we’re performing some <br />
                                maintenance at the moment.</p>
                            <h5>Will Back Soon</h5>
                        </div>
                    </div>

                </div>


                <div className='maintenece_img'>

                    <img src={maintenance_img} alt="" />

                </div>

            </div>
        </div>
    )
}

export default Maintenance