import React from 'react';
import GrafImg from "../src/images/main-banner.png";

function Sliders() {
  return (
    <div className='carousel-slid container-fluid'>
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>

        <div className="carousel-inner text-center">
          <div className="carousel-item active">
            <img src={GrafImg} className=' mx-auto slider-img' alt="..." />
          </div>
          <div className="carousel-item">
            <img src={GrafImg} className=' mx-auto slider-img ' alt="..." />
          </div>
          <div className="carousel-item">
            <img src={GrafImg} className=' mx-auto slider-img' alt="..." />
          </div>
        </div>

        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true">
          </span>
          <span className="visually-hidden">Previous</span>
        </button>

        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true">
          </span>
        
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Sliders;
