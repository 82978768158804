const initialState = {
    userlist: [],
    errorMessages: {}
  };

const userReducer = (state = initialState, action) => {
      switch (action.type) {
        case 'LIST_USERS':
          return {
            ...state,userlist:action.payload
          }
       default:
        return state
      }
}

export default userReducer;