import axios from "axios";
import isEmpty from 'lodash/isEmpty';
import API_URL from "../config/apiConfig";
import { trim } from "lodash";

export const getMemberList = (offset,limit,searchTxt = null) => async (dispatch) => {

    searchTxt = trim(searchTxt);
    const searchQueryString = isEmpty(searchTxt) ? '' : ('/'+searchTxt);
    return await axios.get(API_URL+'/members/'+offset+'/'+limit+searchQueryString).then((response) => {
        if(response.status === true){
            dispatch({ type: "LIST_MEMBERS", payload: response });
        }
        return response.data;
    }).catch((err) => {
        console.log("Error ==== ", err);
    })
}