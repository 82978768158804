import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import trim from "lodash/trim";
import API_URL from './config/apiConfig';
import ROUTES from './config/routePath';


import AcounInfo from './AcounInfo';
import { property } from './App';

import regiImg from "./images/registra.jpg";

import errorMessages from './constant/errorMessages';

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import CreatableSelect from 'react-select/creatable';

import compneyInf from "./images/Company_agent.svg"
import compneyresp_white from "./images/Company _representative_white.svg"

const invalidEmails = [
    'yopmail.com', 'mailinator.com', '10minutemail.com',
    'guerrillamail.com', 'trashmail.com', 'temp-mail.org',
    'dispostable.com', 'fakeinbox.com', 'sharklasers.com',
    'getairmail.com', 'maildrop.cc', 'spambog.com',
    'mytemp.email', 'throwawaymail.com', 'tempmailaddress.com',
    'mailcatch.com', 'moakt.com', 'temp-mail.io', 'tutanota.com'
];

function CompanyRepresentative() {

    const nav = useNavigate();
    const { account, compney, compnytext, RegiIcon } = useContext(property);
    const [isButtonDisabled, setisButtonDisabled] = useState(false);
    React.useEffect(() => {
        if (account && account[1]) {
            account[1].style.border = "1px solid #0b4471";
            account[1].style.borderTopLeftRadius = "6px";
            account[1].style.borderTopRightRadius = "6px";
            account[1].style.backgroundColor = "#0b4471";
        }

        if (compney && compney[1]) {
            compney[1].style.color = "white";
        }

        if (compnytext && compnytext[1]) {
            compnytext[1].style.color = "white";
        }

        // *****registration_icon****

        if (RegiIcon && RegiIcon[0]) {
            RegiIcon[0].src = `${compneyInf}`
        }

        if (RegiIcon && RegiIcon[1]) {
            RegiIcon[1].src = `${compneyresp_white}`
        }

    }, [account, compney, compnytext]);

    const handlePrevClick = () => {
        nav(ROUTES.ACCOUNTDETAIL);
    };

    const data = localStorage.getItem('registration-detail');
    if(data == null || data == ""){
        nav(ROUTES.REGISTRATION);
    }

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        mobile: "",
        designation: "",
        country_id: "",
        city_id: "",
        sal: "Mr",
        page2: 2,
        isNewVal: false
    });

    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [countryCode, setSelectedCountryCode] = useState(null);

    const [localStCity, setLocalStCity] = useState(null);

    const [phoneValue, setphoneValue] = useState();
    const [mobileValue, setmobileValue] = useState();

    const [inputValue, setInputValue] = useState('');

    const [verrors, setVErrors] = useState({});
    
    const fetchCountries = async () => {
        await axios.get(API_URL + '/countries').then((response) => {
            if (response.status === 200) {
                if (response.data.status === true) {
                    const options = response.data.data.map(country => ({
                        value: country.id,
                        label: country.name,
                        countryCode: country.iso2
                    }));
                    setCountryList(options);
                }
            }
        }).catch((err) => {
            console.log("Error ========== ", err);
        })
    }

    const handleCountryChange = (cval) => {
        setSelectedCountryCode(cval.countryCode);
        setphoneValue('');
        setmobileValue('');
        setCityList([]);
        const countryId = cval.value;
        setInputValue(countryId);
        setSelectedCountry(cval);
        setSelectedCity(null);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                country_id: countryId
            }
        });
        axios.get(API_URL + '/cities/' + countryId).then((response) => {
            if (response.status === 200) {
                if (response.data.status === true) {
                    const options = response.data.data.map(ct => ({
                        value: ct.id,
                        label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
                        name: ct.name
                    }));
                    setCityList(options);
                }
            }
        }).catch((err) => {
            console.log("Error ============= ", err);
        });
        let er = { ...verrors };
        er.country_id = '';
        setVErrors(er);
    }

    const handleCityChange = (ctval) => {
       
        if (ctval != null) {
            const cityId = ctval.value;
            setSelectedCity(ctval);
            setFormData((prevalue) => {
                return {
                    ...prevalue,
                    city_id: cityId,
                    isNewVal: ctval.__isNew__
                }
            });
            
        }
        
    }
    const handleInputChange = (newValue) => {
        if ((newValue === '' || newValue === null) && inputValue > 0) {
            setCityList([]);
            axios.get(API_URL + '/cities/' + inputValue).then((response) => {
                if (response.status === 200) {
                    if (response.data.status === true) {
                        const options = response.data.data.map(ct => ({
                            value: ct.id,
                            label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
                            name: ct.name
                        }));
                        setCityList(options);
                    }
                }
            }).catch((err) => {
                console.log("Error ============= ", err);
            });
        }
        if (newValue !== '' && inputValue > 0) {
            if (newValue.length >= 3) {
                setCityList([]);
                axios.post(API_URL + '/cities/search', {
                    search_txt: newValue,
                    country_id: inputValue
                }).then((response) => {
                    if (response.status === 200) {
                        if (response.data.status === true) {
                            const options = response.data.data.map(ct => ({
                                value: ct.id,
                                label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
                                name: ct.name
                            }));
                            setCityList(options);
                        }
                    }
                });

            }
        }


    };

    const isDuplicate = (inputValue) => {
        return cityList.some((option) => {
            let tmpLabel = option.label;
            let tmpLabels = tmpLabel.split('(');
            tmpLabel = trim(tmpLabels[0]);
            return tmpLabel.toLowerCase() === inputValue.toLowerCase()
        })
    };

    const handlePhoneChange = (val) => {
        setphoneValue(val);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                phone: val
            }
        });
    }
    const handleMobileChange = (val) => {
        setmobileValue(val);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                mobile: val
            }
        });

    }
    const handleChange = (e) => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                [e.target.name]: e.target.value
            }
        });
    }
    const handleSelutation = (e) => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                sal: e.target.value
            }
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        
        try {
            if (validation()) {
                axios.post(API_URL + "/member/step2",
                    formData,
                    {
                        headers: {
                            "Accept": "application/json"
                        }

                    }).then((response) => {

                        if (response.data.status === true) {
                            setVErrors([]);
                            localStorage.setItem('company-representative', JSON.stringify(formData));
                            localStorage.setItem("city-detail", JSON.stringify(selectedCity));
                            nav(ROUTES.CONTACTDETAIL);
                        } else {
                            setVErrors([]);
                            const errors = {};
                            if (response.data.error.first_name) {
                                errors.first_name = response.data.error.first_name;
                            }
                            if (response.data.error.last_name) {
                                errors.last_name = response.data.error.last_name;
                            }
                            if (response.data.error.email) {
                                errors.email = response.data.error.email;
                            }
                            if (response.data.error.designation) {
                                errors.designation = response.data.error.designation;
                            }
                            if (response.data.error.country_id) {
                                errors.country_id = response.data.error.country_id;
                            }
                            if (response.data.error.phone) {
                                errors.phone = response.data.error.phone;
                            }
                            if (response.data.error.city_id) {
                                errors.city_id = response.data.error.city_id;
                            }
                            if (response.data.error.mobile) {
                                errors.mobile = response.data.error.mobile;
                            }


                            setVErrors(errors);
                            return Object.keys(errors).length > 0;
                        }
                    }).catch((error) => {
                        console.error('Error fetching data:', error);
                    });
            }
        } catch (err) {
            console.error(err);
        }
    };

    const setCompanyRepresentativeInfo = () => {
       
        try {
            const companyData = localStorage.getItem('company-representative');
            const company = JSON.parse(companyData);
            if (company) {
                setFormData(prevData => ({
                    ...prevData,
                    sal:company.sal || "",
                    first_name: company.first_name || "",
                    last_name: company.last_name || "",
                    email: company.email || "",
                    designation: company.designation || "",
                    country_id: company.country_id || "",
                    city_id: company.city_id || "",
                    phone: company.phone || "",
                    mobile: company.mobile || ""
                }));

                setphoneValue(company.phone);
                setmobileValue(company.mobile);
                setInputValue(company.country_id);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const fetchCityList = async (countryId, city_name) => {
        await axios.post(API_URL + '/cities/search', {
            search_txt: city_name,
            country_id: countryId
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.status === true) {
                    const options = response.data.data.map(ct => ({
                        value: ct.id,
                        label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
                        name: ct.name
                    }));
                    setCityList(options);
                }
            }
        });
    }

    useEffect(() => {
        const companyData = localStorage.getItem('company-representative');
        if (companyData) {
            const company = JSON.parse(companyData);
            const selectedCountryOption = countryList.find(option => option.value == company.country_id);
            if (selectedCountryOption) {
                setSelectedCountry(selectedCountryOption);
            }
        }

    }, [countryList]);

    useEffect(() => {
        const companyData = localStorage.getItem('company-representative');
        if (companyData) {
            const company = JSON.parse(companyData);
            const selectedCityOption = cityList.find(option => option.value == company.city_id);
            if (selectedCityOption) {
                setSelectedCity(selectedCityOption);
            }
        }

    }, [cityList]);

    useEffect(() => {
        const companyData = localStorage.getItem('company-representative');
        const fetchData = async () => {
            await fetchCountries();
            if (companyData) {
                const company = JSON.parse(companyData);
                const { country_id } = company;
                const cityDetail = localStorage.getItem('city-detail');
                if (cityDetail) {
                    const parsedCityDetail = JSON.parse(cityDetail);
                    if(parsedCityDetail){
                    const { name } = parsedCityDetail;
                    await fetchCityList(country_id, name);
                    }
                }
                setCompanyRepresentativeInfo();
            }
        }
        fetchData();
    }, []);

   
    const validation = () => {
        let er = { ...verrors };

        let flag = 0;
        if (formData.first_name === '') {
            flag = 1;
            er.first_name = errorMessages.FIELD_REQUIRED;
        }
        if (formData.last_name === '') {
            flag = 1;
            er.last_name = errorMessages.FIELD_REQUIRED;
        }
        if (formData.email === '') {
            flag = 1;
            er.email = errorMessages.FIELD_REQUIRED;
        }
        if (formData.designation === '') {
            flag = 1;
            er.designation = errorMessages.FIELD_REQUIRED;
        }
        if (formData.country_id === '' || formData.country_id === null) {
            flag = 1;
            er.country_id = errorMessages.FIELD_REQUIRED;
        }

        if (phoneValue === undefined || !phoneValue || !isValidPhoneNumber(phoneValue)) {
            if (phoneValue === undefined || phoneValue === "") {
                flag = 1;
                er.phone = errorMessages.FIELD_REQUIRED;
            } else {
                flag = 1;
                er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
            }

        }
        if (mobileValue) {
            if (!mobileValue || !isValidPhoneNumber(mobileValue))
                flag = 1;
            er.mobile = errorMessages.MOBILE_NUMBER_VALIDATION_MSG;

        }

        setVErrors(er);
        if (flag === 1) {
            return false;
        } else {
            return true;
        }

    };


    const handleFocusOut = async(e) => {
        let flag = 0;
        let er = { ...verrors };
        if (e.target.name === 'first_name') {
            if (e.target.value === '') {
                flag = 1;
                er.first_name = errorMessages.FIELD_REQUIRED;
            } else {
                const regExp = /^[a-zA-Z][a-zA-Z\d\s]*$/;
                const check = regExp.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.first_name = errorMessages.IN_VALID_FIELD_FORMAT;
                }
                else {
                    flag = 0;
                    er.first_name = '';
                }
            }
        }
        if (e.target.name === 'last_name') {
            if (e.target.value === '') {
                flag = 1;
                er.last_name = errorMessages.FIELD_REQUIRED;
            } else {

                const regExp = /^[a-zA-Z][a-zA-Z\d\s]*$/;
                const check = regExp.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.last_name = errorMessages.IN_VALID_FIELD_FORMAT;
                }
                else {
                    flag = 0;
                    er.last_name = '';
                }
            }
        }

        if (e.target.name === 'email') {
            if (e.target.value === '') {
                flag = 1;
                er.email = errorMessages.FIELD_REQUIRED;
            } else {
                const currentEmail = e.target.value.split('@')[1];
                const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                const check = regExp.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.email = errorMessages.IN_VALID_FIELD_FORMAT;
                } else if (invalidEmails.includes(currentEmail)) {
                    flag = 1;
                    er.email = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.email = '';
                    try {
                        const response = await axios.post(API_URL + '/email/validation', { search_txt: e.target.value });
                        if(response.data.status === true){
                            flag = 1;
                            er.email = response.data.message;
                        }else{
                            flag = 0;
                            er.email = ''; 
                        }
                    } catch (err) {
                        console.log("Error ============= ", err);
                    }
                }
            }
        }

        if (e.target.name === 'designation') {
            if (e.target.value === '') {
                flag = 1;
                er.designation = errorMessages.FIELD_REQUIRED;
            } else {

                const regExp = /^[A-Za-z][A-Za-z0-9 ,&.-]*$/;
                const check = regExp.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.designation = errorMessages.IN_VALID_FIELD_FORMAT;
                }
                else {
                    flag = 0;
                    er.designation = '';
                }
            }
        }
       
        if (e.target.name === 'phone') {
            let phoneValue = e.target.value;
            if (phoneValue === undefined || !phoneValue || !isValidPhoneNumber(phoneValue)) {
                if (phoneValue === undefined || phoneValue === "") {
                    flag = 1;
                    er.phone = errorMessages.FIELD_REQUIRED;
                } else {
                    flag = 1;
                    er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
                    
                }
                
            }else{
                    flag = 0;
                    er.phone = '';

                    try {
                        const response = await axios.post(API_URL + '/phone/validation', { search_txt: phoneValue });
                        if(response.data.status === true){
                            flag = 1;
                            er.phone = response.data.message;
                        }else{
                            flag = 0;
                            er.phone = ''; 
                        }
                    } catch (err) {
                        console.log("Error ============= ", err);
                    }
                   
                    
                }   
           
        }
         if (e.target.name === 'mobile') {
            let mobileValue = e.target.value;

            if (mobileValue) {
                if (!mobileValue || !isValidPhoneNumber(mobileValue)){
                    flag = 1;
                    er.mobile = errorMessages.MOBILE_NUMBER_VALIDATION_MSG;
                }else{
                    flag = 0;
                    er.mobile = ''; 
                    try {
                       
                        const response = await axios.post(API_URL + '/phone/validation', { search_txt: mobileValue });
                        if(response.data.status === true){
                            flag = 1;
                            er.mobile = response.data.message;
                        }else{
                            flag = 0;
                            er.mobile = ''; 
                        }
                    
                    } catch (err) {
                        console.log("Error ============= ", err);
                    }

                }
                let mobileNo = mobileValue.replace(/ /g, '');
                if(mobileNo === phoneValue)
                {
                    flag = 1;
                    er.mobile = 'The mobile number must match the phone number.';
                }
            }

        }

        setVErrors(er);
        if (flag === 0) {
            setisButtonDisabled(false);
            return false;
        } else {
            setisButtonDisabled(true);
            return true;
        }

    }

    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem('company-representative');
            localStorage.removeItem('city-detail');
            return true;
        };
        window.onbeforeunload = handleBeforeUnload;
        return () => {
            window.onbeforeunload = null;
        };
    }, []);


    const styleError = {
        display: 'block',
    };

    return (

        <div>
            <div className="main-regi">
                <div className="regi-menIMG">
                    <img src={regiImg} />
                </div>

                <div className="regi-form">

                    <AcounInfo />

                    <form id="company-representative" className='border-area' onSubmit={handleSubmit}>


                        <div className="row mt-5 px-5">

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label required">First Name</label>
                                <div className="input-group">
                                    <span className="input-group-addon">
                                        <select name="sal" onChange={handleSelutation} value={formData.sal}>
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss">Miss</option>
                                        </select>
                                    </span>

                                    <input type="text" name="first_name" className="form-control" onChange={handleChange} placeholder="Enter first name" onBlur={handleFocusOut} value={formData.first_name} />
                                    {verrors.first_name && <div className="invalid-feedback" style={styleError}>{verrors.first_name}</div>}
                                </div>
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">

                                <label className="form-label required">Last Name</label>
                                <input type="text" name="last_name" id="last_name" className="form-control" onChange={handleChange} placeholder="Enter last name" onBlur={handleFocusOut} value={formData.last_name} />
                                {verrors.last_name && <div className="invalid-feedback" style={styleError}>{verrors.last_name}</div>}
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">

                                <label className="form-label required">Email</label>
                                <input type="text" name="email" id="email" className="form-control" onChange={handleChange} placeholder="Enter email" onBlur={handleFocusOut} value={formData.email} />
                                {verrors.email && <div className="invalid-feedback" style={styleError}>{verrors.email}</div>}
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label required">Designation</label>
                                <input type="text" name="designation" id="designation" className="form-control" onChange={handleChange} placeholder="Enter designation" onBlur={handleFocusOut} value={formData.designation} />
                                {verrors.designation && <div className="invalid-feedback" style={styleError}>{verrors.designation}</div>}
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label required">Country</label>
                                <div className="position-relative">

                                    <Select
                                        name="country_id"
                                        options={countryList}
                                        value={selectedCountry}
                                        onChange={handleCountryChange}
                                        placeholder="Select country"
                                    />
                                    {verrors.country_id && <div className="invalid-feedback" style={styleError}>{verrors.country_id}</div>}
                                </div>
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label">City</label>
                                <div className="position-relative">
                                    <CreatableSelect
                                        onChange={handleCityChange}
                                        onInputChange={handleInputChange}
                                        options={cityList}
                                        value={selectedCity}
                                        placeholder="Enter city"
                                        formatCreateLabel={(inputValue) =>
                                            isDuplicate(inputValue) ? null : `Create "${inputValue}"`
                                        }
                                        isValidNewOption={(inputValue) => !isDuplicate(inputValue)}
                                    />
                                    {verrors.city_id && <div className="invalid-feedback" style={styleError}>{verrors.city_id}</div>}
                                </div>
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label required">Contact</label>
                                <PhoneInput id="contact-form-phone" className="form-control inquiry-phone inp-font-size border-dropdown" name="phone"
                                    international limitMaxLength
                                    placeholder="Enter contact"
                                    defaultCountry={countryCode}
                                    value={phoneValue}
                                    onChange={handlePhoneChange}
                                    onBlur={handleFocusOut}
                                />
                                {verrors.phone && <div className="invalid-feedback" style={styleError}>{verrors.phone}</div>}
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label">Mobile</label>
                                <PhoneInput id="contact-form-mobile" className="form-control inquiry-phone inp-font-size border-dropdown" name="mobile"
                                    international limitMaxLength
                                    placeholder="Enter mobile"
                                    defaultCountry={countryCode}
                                    value={mobileValue}
                                    onChange={handleMobileChange}
                                    onBlur={handleFocusOut}
                                />
                                {verrors.mobile && <div className="invalid-feedback" style={styleError}>{verrors.mobile}</div>}
                            </div>

                            <div className="col-12 d-flex justify-content-between  mt-3 ">
                                <button onClick={handlePrevClick} className="btn-regi regi-next text-white border-0">

                                    Prev
                                </button>

                                <button type="submit" className=" btn-regi text-white border-0 regi-next" disabled={isButtonDisabled}>Next

                                </button>

                            </div>

                        </div>


                    </form>

                </div>

            </div>

        </div>

    )
}

export default CompanyRepresentative  
