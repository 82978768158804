const initialState = {
    errorMessages: {}
  };
  const updateProfileReducer = (state = initialState, action) => {
      switch (action.type) {
          case 'UPDATE_PROFILE_ACTION':
            return {
              ...state,updateProfile:action.payload
            }
          case 'UPDATE_COMPANY_ACTION':
            return {
              ...state,updateCompany:action.payload
            }
          case 'UPDATE_SERVICE_ACTION':
            return {
              ...state,updateService:action.payload
            }
          case 'GET_CONTACT_ACTION':
            return {
              ...state,getContact:action.payload
            }
          case 'GET_MEMBER_SERVICE_ACTION':
            return {
              ...state,getMemberService:action.payload
            }
          case 'UPDATE_CONTACT_ACTION':
            return {
              ...state,updateContact:action.payload
            }
          case 'GET_OTHER_DETAIL_ACTION':
            return {
              ...state,getOtherDetail:action.payload
            }
          case 'UPDATE_OTHER_DETAIL_ACTION':
            return {
              ...state,updateOtherDetail:action.payload
            }
          default:
            return state
      }
     }
     export default updateProfileReducer;