import React, { useEffect, useState } from 'react'
import OrexSidebar from '../../components/OrexSidebar'
import { Link, Route, Routes, useLocation } from 'react-router-dom';

import MENU_ROUTES from '../../config/menuRoutes';
import { includes } from "lodash";


import TopMenu from '../../components/TopMenu';
import Updateprofile from '../Compney/Updateprofile';


function AdminPanle() {

    const location = useLocation();
    
    const [visibleSidebar, setSidebar] = useState(false);

    useEffect(() => {

        console.log(location.pathname);
        if (includes(MENU_ROUTES, location.pathname)) {
            setSidebar(true);
        } else {
            setSidebar(false);
        }
    }, []);

    return (
        <div>
            <div style={{ display: "flex", height: "100vh" }}>
                <OrexSidebar />

                <div className='content'>


                    <TopMenu />
                    {/* ******slider routing******* */}
                    <Routes>
                        <Route path='/updateprofile' element={<Updateprofile />}></Route>
                    </Routes>

                </div>
            </div>
        </div>
    )
}

export default AdminPanle
