import React from 'react'
import { Button } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertSuccess from '../pages/AlertSuccess';
import AlertWarning from '../pages/AlertWarning';
import AlertError from '../pages/AlertError';

function Toasty() {


    return (
        <div>

            <AlertSuccess/>
            <AlertWarning/>
            <AlertError/>


        </div>
    )
}

export default Toasty
