import React from 'react'
import { DateRangePicker } from 'rsuite'

function DateTimePickerField() {
  return (
    <div>
      <DateRangePicker
        format="MM/dd/yyyy hh:mm aa"
        className='w-100 orm-error'
        placeholder="mm-dd-yyyy hh:mm"
      />
    </div>
  )
}

export default DateTimePickerField
