const errorMessages = {
    COMPANY_NAME_REQUIRED: 'The company name field is required.',
    WEBSITE_URL_REQUIRED: 'The website field is required.',
    NAME_REQUIRED: 'The name field is required.',
    EMAIL_REQUIRED: 'The email field is required.',
    PHONE_REQUIRED: 'The contact field is required.',
   // MOBILE_REQUIRED: 'The mobile field is required.',
    COUNTRY_REQUIRED: 'The country field is required.',
    INQUIRY_MSG_REQUIRED: 'The inquiry message field is required.',
    CONTACT_NUMBER_VALIDATION_MSG : 'Please enter valid contact number.',
    MOBILE_NUMBER_VALIDATION_MSG : 'Please enter valid mobile number.',
    PASSWORD_REQUIRED: 'The password field is required.',
    CONFIRM_PASSWORD_REQUIRED: 'The confirm password field is required.',
    STRONG_PASSWORD: 'Please provide strong password with 8+ characters, mix letters, numbers, symbols.',
    MATCH_PASSWORD: 'The password field did not matched.',
    INVALID_EMAIL: 'Please provide a valid Email',
    FIELD_REQUIRED: 'The field is required.',
    IN_VALID_FIELD_FORMAT: 'The field format is invalid.',
    MAX_LENGTH_EXCEEDED:'The field must not be greater than 40 characters.',
    UNIQUE_MSG:'The data has already been taken.'

};

export default errorMessages;
