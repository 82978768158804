import React, { useEffect, useRef, useState } from 'react';
import bissines from "../src/images/bissiness_images.png"
import { FaArrowAltCircleUp } from "react-icons/fa";

function Bissiness() {


    const [isVisible, setIsVisible] = useState(false);
    const prevScrollPos = useRef(0);


    useEffect(() => {

        const toggleVisibility = () => {
            const currentScrollPos = window.pageYOffset;

            // Button is displayed after scrolling for 500 pixels
            if (currentScrollPos > 500 && currentScrollPos > prevScrollPos.current) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }

            prevScrollPos.current = currentScrollPos;
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, [isVisible]);



    let topscroll = () => {

        window.scrollTo(0, 0);

    }

    return (

        <div>
            <section className='grow'>

                <div className="how_we">

                    <div className='boost'>
                        <p >Boost Business</p>
                    </div>

                    <h5> <span className='fw-bold'> How We </span>
                        <span className='boost-span'>
                            <span className='fw-bold'> Boost Your </span> Business
                        </span>
                        Performance
                    </h5>

                    <p className='with-our'>
                        With Our AI-Based Data Platform for Showcasing, Filtering, and Graphing Hierarchical Reports, You Can Quickly & Easily.
                    </p>
                </div>


                <div>

                    <div className='bissines'>

                        <div className="b-img">

                            <img src={bissines} />

                        </div>

                        <div className="b-img our-data ">


                            <h5 className='mb-3'> What Our Data Gives You: <span className='fw-bold text-start'>Real-Time Clarity and Growth.</span></h5>



                            <div className='d-flex align-items-center mb-2 align-items-center mb-2'>

                                <div>
                                    <i className="fa-solid fa-chevron-right mx-2"></i>

                                </div>

                                <div>
                                    <span>Identify the Perfect Trading Partner</span>
                                </div>
                            </div>


                            <div className='d-flex align-items-center mb-2'>

                                <div>
                                    <i className="fa-solid fa-chevron-right mx-2"></i>
                                </div>

                                <div>
                                    <span>Track Your Competitors</span>
                                </div>
                            </div>


                            <div className='d-flex align-items-center mb-2'>

                                <div>
                                    <i className="fa-solid fa-chevron-right mx-2"></i>
                                </div>

                                <div>
                                    <span>Locate Sales Prospects</span>
                                </div>

                            </div>


                            <div className='d-flex align-items-center mb-2'>

                                <div>
                                    <i className="fa-solid fa-chevron-right mx-2"></i>
                                </div>

                                <div>
                                    <span>Determine Market Size</span>
                                </div>
                            </div>


                            <div className='d-flex align-items-center mb-2'>

                                <div>
                                    <i className="fa-solid fa-chevron-right mx-2"></i>
                                </div>

                                <div>
                                    <span>Set Optimal Product Prices</span>
                                </div>
                            </div>

                            <div className='d-flex align-items-center mb-2'>

                                <div>
                                    <i className="fa-solid fa-chevron-right mx-2"></i>
                                </div>

                                <div>
                                    <span>Manage Investment Risk</span>
                                </div>
                            </div>

                            <div className='d-flex align-items-center mb-2'>

                                <div>
                                    <i className="fa-solid fa-chevron-right mx-2"></i>
                                </div>

                                <div>
                                    <span>Access Essential Information for Market Leadership</span>
                                </div>
                            </div>

                            <div className='d-flex align-items-center mb-2'>

                                <div>
                                    <i className="fa-solid fa-chevron-right mx-2"></i>
                                </div>

                                <div>
                                    <span>Secure Financial Protection

                                    </span>
                                </div>
                            </div>

                            <div className='d-flex align-items-center mb-2'>

                                <div>
                                    <i className="fa-solid fa-chevron-right mx-2"></i>
                                </div>

                                <div>
                                    <span>Trusted Transport Partners

                                    </span>
                                </div>
                            </div>


                            <div className='d-flex align-items-center mb-2'>

                                <div>
                                    <i className="fa-solid fa-chevron-right mx-2"></i>
                                </div>

                                <div>
                                    <span>Powerful Business Prospective
                                    </span>
                                </div>
                            </div>



                        </div>

                        <div className='scroll-btn'>

                            {
                                isVisible && (
                                    <FaArrowAltCircleUp className='top-scroll' onClick={topscroll}></FaArrowAltCircleUp>
                                )
                            }


                        </div>

                    </div>


                </div>

            </section>

        </div>
    )
}

export default Bissiness