const initialState = {
    memberlist: [],
    errorMessages: {}
  };

const memberReducer = (state = initialState, action) => {
      switch (action.type) {
        case 'LIST_MEMBERS':
          return {
            ...state,memberlist:action.payload
          }
       default:
        return state
      }
}

export default memberReducer;