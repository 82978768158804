import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useMemo, useRef, useState } from 'react'
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import InputField from './InputField';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button } from 'react-bootstrap';

function Table() {

    const rowHeight = 60;

    const [open, setOpen] = React.useState(false);
    const gridRef = useRef();


    const [rowData, setRowData] = useState([

        {
            make: "View",
            SrNo: "1",
            BillDate: "August 31,2024",
            HSCode: 1,
            month: "June",
            ProductDescription: "Packing Material(Empty Carton)",
            CountryofImport: "india",
            ShiperName: "worldwide",
            ConsigneeName: "Pharma Focus International",
            PortOfLanding: "Ahmedabad,India,Gujarat",
            PortOfUnlanding: "Ahmedabad,India,Gujarat",
            MadeOfTransportation: "NA",
            Quantity: "20.0 Piece(s)",
            FOBValue: "2.93",
            PerUnitValue: "1.96 Per Piece",

        },

        {
            make: "View",
            SrNo: "2",
            BillDate: "August 31,2024",
            HSCode: 12346,
            month: "June",
            ProductDescription: "Packing Material(Empty Carton)",
            CountryofImport: "jadav",
            ShiperName: "worldwide",
            ConsigneeName: "Pharma Focus International",
            PortOfLanding: "Ahmedabad,India,Gujarat",
            PortOfUnlanding: "Ahmedabad,India,Gujarat",
            MadeOfTransportation: "NA",
            Quantity: "20.0 Piece(s)",
            FOBValue: "2.93",
            PerUnitValue: "1.96 Per Piece"
        },

        {
            make: "View",
            SrNo: "3",
            BillDate: "August 31,2024",
            HSCode: 12346,
            month: "June",
            ProductDescription: "Packing Material(Empty Carton)",
            CountryofImport: "india",
            ShiperName: "worldwide",
            ConsigneeName: "Pharma Focus International",
            PortOfLanding: "Ahmedabad,India,Gujarat",
            PortOfUnlanding: "Ahmedabad,India,Gujarat",
            MadeOfTransportation: "NA",
            Quantity: "20.0 Piece(s)",
            FOBValue: "2.93",
            PerUnitValue: "1.96 Per Piece"
        },


        {
            make: "View",
            SrNo: "4",
            BillDate: "August 31,2024",
            HSCode: 12346,
            month: "June",
            ProductDescription: "Packing Material(Empty Carton)",
            CountryofImport: "india",
            ShiperName: "worldwide",
            ConsigneeName: "Pharma Focus International",
            PortOfLanding: "Ahmedabad,India,Gujarat",
            PortOfUnlanding: "Ahmedabad,India,Gujarat",
            MadeOfTransportation: "NA",
            Quantity: "20.0 Piece(s)",
            FOBValue: "2.93",
            PerUnitValue: "1.96 Per Piece"
        },

        {
            make: "View",
            SrNo: "5",
            BillDate: "August 31,2024",
            HSCode: 12346,
            month: "June",
            ProductDescription: "Packing Material(Empty Carton)",
            CountryofImport: "india",
            ShiperName: "worldwide",
            ConsigneeName: "Pharma Focus International",
            PortOfLanding: "Ahmedabad,India,Gujarat",
            PortOfUnlanding: "Ahmedabad,India,Gujarat",
            MadeOfTransportation: "NA",
            Quantity: "20.0 Piece(s)",
            FOBValue: "2.93",
            PerUnitValue: "1.96 Per Piece"
        },


    ]);

    const CustomButtonComponent = () => {
        return <button onClick={handleClickToOpen} style={{ width: 20, height: 20, display: "flex", justifyContent: "center", alignItems: "center", fontSize: "20px", border: "none" }}> +</button>;
    }

    // var dateFilterParams = {
    //     comparator: (filterLocalDateAtMidnight, cellValue) => {
    //       var dateAsString = cellValue;
    //       if (dateAsString == null) return -1;
    //       var dateParts = dateAsString.split("/");
    //       var cellDate = new Date(
    //         Number(dateParts[2]),
    //         Number(dateParts[1]) - 1,
    //         Number(dateParts[0]),
    //       );
    //       if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    //         return 0;
    //       }
    //       if (cellDate < filterLocalDateAtMidnight) {
    //         return -1;
    //       }
    //       if (cellDate > filterLocalDateAtMidnight) {
    //         return 1;
    //       }
    //       return 0;
    //     },
    //   };

    const [columnDefs, setColumnDefs] = useState([

        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            pinned: "left",
            width: 50

        },

        {
            field: "View",
            cellRenderer: CustomButtonComponent,
            pinned: "left",
            width: 70,

        },

        { field: "SrNo", width: 70 ,headerName:"Sr.No" },
        { field: "BillDate" },
        { field: "HSCode", width: 150 },
        { field: "ProductDescription", width: 250 },

        // { field: "CountryofImport", width: 200 },
        { field: 'CountryofImport', headerName: 'Country of Import', width: 200  },
        { field: "ShiperName", width: 150 },
        { field: "ConsigneeName", width: 250 },
        { field: "PortOfLanding" ,headerName: 'Port of Landing', },
        { field: "PortOfUnlanding" },
        { field: "MadeOfTransportation" ,width: 250 ,headerName: 'Made of Transportation',},
        { field: "Quantity", width: 130 },
        { field: "FOBValue", width: 130 },
        { field: "PerUnitValue" ,headerName:"Per unit Value" }

    ]);


    // const defaultColDef = useMemo(() => {
    //     return {
    //         filter: "agTextColumnFilter",
    //         floatingFilter: true,
    //     };
    // }, []);


    const onGridReady = useCallback((params) => {
        fetch(rowData)
            .then((resp) => resp.json())
            .then((data) => setRowData(data));
    }, []);


    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setGridOption(
            "quickFilterText",
            document.getElementById("filter-text-box").value,
        );
    }, []);


    // **dialog box ****


    const handleClickToOpen = () => {
        setOpen(true);
    };

    const handleToClose = () => {
        setOpen(false);
    };



    const gridOptions = {
        defaultColDef: {
            initialWidth: 200,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        columnDefs: columnDefs,
    };


    document.addEventListener("DOMContentLoaded", () => {
        const gridDiv = document.querySelector("#myGrid");
      let   gridApi = AgGridReact.createGrid(gridDiv, gridOptions);
    });


    return (

        <div className=''>

            <div className={"ag-theme-quartz card shadow mt-5"} id="myGrid" style={{ height: 600 }}>

                <div className='d-flex justify-content-between align-items-center p-3'>

                    <h5 className='table-text'>React Data Table</h5>

                    <div className='d-flex justify-content-between align-items-center p-3'>
                        <label className='mx-3 table-text'>Search :</label>
                        <InputField
                            type="text"
                            id="filter-text-box"
                            placeholder="Search..."
                            className='w-25'
                            onInput={onFilterTextBoxChanged}
                        ></InputField>
                    </div>

                </div>

                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    // defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 20, 30, 40, 50]}
                    onGridReady={onGridReady}
                    rowHeight={rowHeight}
                    className="data-table"
              

                />

                <Dialog open={open} onClose={handleToClose}>
                    <DialogTitle>{"Popup box"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            I am Good, Hope the same for you!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleToClose}
                            color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>




        </div>
    )
}

export default Table