import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import memberReducer from './memberReducer';
import userReducer from './userReducer';
import updateProfileReducer from './updateProfileReducer';

export default combineReducers({
 auth: loginReducer,
 member: memberReducer,
 users: userReducer,
 update: updateProfileReducer
});
