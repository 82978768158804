import axios from "axios";
import API_URL from "../config/apiConfig";
export const LOGIN = "LOGIN";

const apiUrl = API_URL;
export const storeToken = (token) => {
    localStorage.setItem('token', token);
  };
export const storeRememberMeToken = (rememberMeToken) => {
    localStorage.setItem('rememberMeToken', rememberMeToken);
};
export const storeEmail = (email) => {
    localStorage.setItem('email', email);
};
export const storePassword = (password) => {
    let encoded = btoa(password);
    console.log('encoded: ',encoded, 'password: ',password,);
    localStorage.setItem('password', encoded);
};
export const removeAll = (password) => {
    localStorage.removeItem("password");
    localStorage.removeItem("email");
    localStorage.removeItem("rememberMeToken");
};


export const loginAction =  (email, password, isRememberMeChecked) => async (dispatch) => {
    
   return await axios.post(apiUrl + "/login", {
        email,
        password,
        isRememberMeChecked
    }, {
        headers: {
            "Accept": "application/json"
        }
        })
                .then((response) => {
                    // console.log("test111111111111", response.data);
                    if(response.status === 200 && response.data.success === true){
                        dispatch({ type: "LOGIN_ACTION", payload: response.data.data });
                        storeToken(response.data.data.token);
                        if(isRememberMeChecked){
                            
                            storeRememberMeToken(response.data.data.remember_token);
                            storeEmail(response.data.data.email);
                            storePassword(password);
                        }else{
                            // console.log('Removed all the data from LocalStorage');
                            removeAll();
                        }
                        
                        // console.log('saved');
                        // return Promise.resolve();  
                        return response.data;
                    }
                })
                .catch((error) => {
                    // console.log(' API RESPONSE CATCH BLOCK ============ ', error.response.data);
                    if(error.response.data.data.error === "Please provide valid credentials"){
                        // console.log('zxdasasd');
                      //   dispatch({ type: "LOGIN_ACTION_FAILURE", payload: error.response.data });
                        return error.response.data;
                    } else if(error.response.data.data.error === "The email is not registered."){
                        // console.log('zxdasasd');
                      //   dispatch({ type: "LOGIN_ACTION_FAILURE", payload: error.response.data });
                        return error.response.data;
                    } else if(error.response.data.message === "Validation Error."){
                       //  dispatch({ type: "LOGIN_ACTION_FAILURE", payload: error.response.data.data });
                        return error.response.data;
                    } else {
                        return false;
                    }
                });
}

export const forgotPasswordAction =  (email) => async (dispatch) => {
    
    return await axios.post(apiUrl + "/forgot-password", {
         email
     }, {
         headers: {
             "Accept": "application/json"
         }
         })
                 .then((response) => {
                    console.log("here1111111111111111", response);
                    if(response.status === 200){
                        console.log("reset_password_link =    ",response.data.reset_link);
                        dispatch({ type: "FORGOT_PASSWORD_ACTION", payload: response.data.data });
                        return response.data;
                    }
                    //  dispatch({ type: "FORGOT_PASSWORD_ACTION", payload: response.data.data });
                 })
                 .catch((error) => {
                    console.log("here1111111111111111error", error);
                    if(error.response.data.message === "Validation Error."){
                        return error.response.data;
                    }else if(error.response.data.message === "The email is not registered."){
                        return error.response.data;
                    } else {
                        return false;
                    }
                    
                    //  dispatch({ type: "FORGOT_PASSWORD_ACTION", payload: error });
                 });
 }

export const resetPasswordAction =  (password, token, email, password_confirmation) => async (dispatch) => {
    
    return await axios.post(apiUrl + "/reset-password" , {
         password,
         token,
         email,
         password_confirmation
     }, {
         headers: {
             "Accept": "application/json"
         }
         })
                 .then((response) => {
                    // console.log("reset", response.data);
                    if(response.status === 200){
                        dispatch({ type: "RESET_PASSWORD_ACTION", payload: response.data.data });
                        return true;
                    }
                     
                 })
                 .catch((error) => {
                     // console.log(error);
                    if(error.response.data.message === "Validation Error."){
                        return error.response.data;
                    } else if(error.response.data.message === "Invalid token or email."){
                        return error.response.data;
                    } else if(error.response.data.message === "Token has expired."){
                        return error.response.data;
                    } else if(error.response.data.message === "New password cannot be the same as the old password."){
                        return error.response.data;
                    } else {
                        return false;
                    }
                    
                    //  dispatch({ type: "RESET_PASSWORD_ACTION", payload: error });
                 });
 }