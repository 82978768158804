import { useContext, useEffect, useState } from "react";
import DropDown from "../../components/DropDown";
import InputField from "../../components/InputField";
import Select from 'react-select';
import axios from "axios";
import trim from "lodash/trim";
import size from "lodash/size";
import API_URL from "../../config/apiConfig";
import CreatableSelect from 'react-select/creatable';
import PhoneInput from 'react-phone-number-input';
import { connect } from 'react-redux';
import { getContactAction, getMemberServiceAction, getOtherDetailAction, updateCompanyAction, updateContactAction, updateOtherDetailAction, updateProfileAction, updateServiceAction } from "../../actions/updateProfileAction";
import isEmpty from "lodash/isEmpty";
import errorMessages from "../../constant/errorMessages";
import { MultiSelect } from 'react-multi-select-component';
import FileUpload from "../FileUpload";
import { property } from "../../App";

import uploadPhotos from "../../images/upload_photos.svg";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

const editorConfiguration = {
    plugins: [ Essentials, Bold, Italic, Paragraph ],
    toolbar: [ 'bold', 'italic' ]
};

// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';


function UpdateProfile(props) {
    const [countryList, setCountryList] = useState([]);
    const [countryList2, setCountryList2] = useState([]);
    const [head_office, setHeadOffice] = useState();
    const [branch_office, setBranchOffice] = useState(null);
    const [member_country, setMemberCountry] = useState(null);
    const [country3, setCountry3] = useState(null);
    const [selectedCountry4, setSelectedCountry4] = useState(null);
    const [countryCode, setSelectedCountryCode] = useState(null);
    const [industries, setIndustries] = useState([]);
    const [industry, setIndustry] = useState(null);
    const [selectCountryList, setSelectCountryList] = useState([]);
    const [selectCountryList2, setSelectCountryList2] = useState([]);
    const [phone, setPhone] = useState();
    const [mobileValue, setmobileValue] = useState();
    const [cityList, setCityList] = useState([]);
    const [cityList2, setCityList2] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedCity, setSelectedCity] = useState(null);
    const [city, setCity] = useState(null);
    const [member_city, setMemberCity] = useState(null);
    const [company_size, setCompanySize] = useState(null);
    const [shipment_mode, setShipmentMode] = useState(null);
    const [office_type, setOfficeType] = useState(null);
    const [errors, setErrors] = useState({});
    const { profileName, setProfileName } = useContext(property)
    // const [profile, setProfile] = useState();
    const [formData, setFormData] = useState({
        first_name:"",
        last_name:"",
        email:"",
        company_name:"",
        website:"",
        designation:"",
        address1:"",
        address2:"",
        pincode:"",
        page:1
    });

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleInputChange = (newValue) => {
        if (newValue === '' || newValue === null && inputValue > 0) {
            setCityList([]);
            axios.get(API_URL + '/cities/' + inputValue).then((response) => {
                if (response.status === 200) {
                    if (response.data.status === true) {
                        const options = response.data.data.map(ct => ({
                            value: ct.id,
                            label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : '')
                        }));
                        setCityList(options);
                    }
                }
            }).catch((err) => {
                // console.log("Error ============= ", err);
            });
        }
        if (newValue !== '' && inputValue > 0) {
            if (newValue.length >= 3) {
                setCityList([]);
                axios.post(API_URL + '/cities/search', {
                    search_txt: newValue,
                    country_id: inputValue
                }).then((response) => {
                    if (response.status === 200) {
                        if (response.data.status === true) {
                            const options = response.data.data.map(ct => ({
                                value: ct.id,
                                label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : '')
                            }));
                            setCityList(options);
                        }
                    }
                });

            }
        }


    };
    const handleInputChange2 = (newValue) => {
        if (newValue === '' || newValue === null && inputValue > 0) {
            setCityList2([]);
            axios.get(API_URL + '/cities/' + inputValue).then((response) => {
                if (response.status === 200) {
                    if (response.data.status === true) {
                        const options = response.data.data.map(ct => ({
                            value: ct.id,
                            label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : '')
                        }));
                        setCityList2(options);
                    }
                }
            }).catch((err) => {
                // console.log("Error ============= ", err);
            });
        }
        if (newValue !== '' && inputValue > 0) {
            if (newValue.length >= 3) {
                setCityList2([]);
                axios.post(API_URL + '/cities/search', {
                    search_txt: newValue,
                    country_id: inputValue
                }).then((response) => {
                    if (response.status === 200) {
                        if (response.data.status === true) {
                            const options = response.data.data.map(ct => ({
                                value: ct.id,
                                label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : '')
                            }));
                            setCityList2(options);
                        }
                    }
                });

            }
        }


    };
    const fetchCountries = async () => {
        await axios.get(API_URL + '/countries').then((response) => {
            if (response.status === 200) {
                if (response.data.status === true) {
                    const options = response.data.data.map(country => ({
                        value: country.id,
                        label: country.name,
                        countryCode: country.iso2
                    }));
                    // console.log('4444', options);
                    setCountryList(options);
                }
            }
        }).catch((err) => {
            // console.log("Error =", err);
        })
    }
    const fetchCountries2 = async () => {
        await axios.get(API_URL + '/countries').then((response) => {
            if (response.status === 200) {
                if (response.data.status === true) {
                    const options = response.data.data.map(country => ({
                        value: country.id,
                        label: country.name,
                        countryCode: country.iso2
                    }));
                    setCountryList2(options);
                }
            }
        }).catch((err) => {
            // console.log("Error ========== ", err);
        })
    }
    const fetchCountries3 = async () => {
        await axios.get(API_URL + '/countries').then((response) => {
            if (response.status === 200) {
                if (response.data.status === true) {
                    const options = response.data.data.map(country => ({
                        value: country.id,
                        label: country.name,
                        countryCode: country.iso2
                    }));
                    setSelectCountryList(options);
                }
            }
        }).catch((err) => {
            // console.log("Error ========== ", err);
        })
    }
    const fetchCountries4 = async () => {
        await axios.get(API_URL + '/countries').then((response) => {
            if (response.status === 200) {
                if (response.data.status === true) {
                    const options = response.data.data.map(country => ({
                        value: country.id,
                        label: country.name,
                        countryCode: country.iso2
                    }));
                    setSelectCountryList2(options);
                }
            }
        }).catch((err) => {
            // console.log("Error ========== ", err);
        })
    }

    const handleCountryChange = async (cval) => {

        const countryId = cval.value;
        setHeadOffice(cval);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                head_office: countryId
            }
        });
       
    }
    const handleSelectChangeCompanySize = (event) => {
        setCompanySize(event)
        setFormData((prevalue) => {
            return {
                ...prevalue,
                company_size: event.value
            }
        });
    }
    const handleCountryChange2 = (cval) => {
        const countryId = cval.value;
        setBranchOffice(cval);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                branch_office: countryId
            }
        });
    }
    const handleIndustryChange = (cval) => {
        const industryId = cval.value
        setIndustry(cval);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                industry: industryId
            }
        });
        
    }
    const handleShipmenMode = (event) => {
        setShipmentMode(event);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                shipment_mode: event.value
            }
        });
    }
    const handleOfficeType = (event) => {
        setOfficeType(event);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                office_type: event.value
            }
        });
    }
    const handleCountryChange3 = async (cval, from = '', ctid = 0) => {
        // console.log('cval===', cval,' == from == ',from);
        setSelectedCountryCode(cval.countryCode);
        setCountry3(cval.value);
        setMemberCountry(cval);
        setPhone('');
        setmobileValue('');
        // setStateList([]);
        setCityList([]);
        
        setInputValue(cval.value);
        // setMemberCountry(cval);
        //setSelectedState(null);
        setSelectedCity(null);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                country_id: cval.value
            }
        });
        // console.log('77777==fetchedCountry===', fetchedCountry);
        if (cval && cval.value) {
            // console.log('77777=====');
        await axios.get(API_URL + '/cities/' + cval.value).then((response) => {
            // console.log("City getting called based on country ====");
            if (response.status === 200) {
                if (response.data.status === true) {
                    const options = response.data.data.map(ct => ({
                        value: ct.id,
                        label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : '')
                    }));
                    setCityList(options);

                    if(from === 'companyInfo') {
                        //  console.log("city =========== ", city);
                        const selectedCity = options.find(option => option.value == ctid);
                        // console.log('selectedCity=======', selectedCity);
                        setSelectedCity(selectedCity);
                    }
                }
            }
        }).catch((err) => {
            // console.log("Error ============= ", err);
        });
    }
    }
    const handleCountryChange4 = async (cval) => {

        setSelectedCountryCode(cval.countryCode);
        // setPhone('');
        // setmobileValue('');
        // setStateList([]);
        setCityList2([]);
        const countryId = cval.value;
        setInputValue(countryId);
        setSelectedCountry4(cval);
        //setSelectedState(null);
        setMemberCity(null);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                country_id: countryId
            }
        });
        await axios.get(API_URL + '/cities/' + countryId).then((response) => {
            if (response.status === 200) {
                if (response.data.status === true) {
                    const options = response.data.data.map(ct => ({
                        value: ct.id,
                        label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : '')
                    }));
                    setCityList2(options);
                }
            }
        }).catch((err) => {
            // console.log("Error ============= ", err);
        });
    }

    const handleCityChange = async (ctval) => {
        if (ctval != null) {
            const cityId = ctval.value;
            setSelectedCity(ctval);
            setFormData((prevalue) => {
                return {
                    ...prevalue,
                    city_id: cityId,
                    isNewVal: ctval.__isNew__
                }
            });
        }
    }
    const handleCityChange2 = async (ctval) => {
        if (ctval != null) {
            const cityId = ctval.value;
            setMemberCity(ctval);
            setFormData((prevalue) => {
                return {
                    ...prevalue,
                    city_id: cityId,
                    isNewVal: ctval.__isNew__
                }
            });
        }
    }
    const handleChange = (e) => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                [e.target.name]: e.target.value
            }
        })
    }
    const handlePhoneChange = (val) => {
        setPhone(val);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                phone: val
            }
        });

    }

    const handleMobileChange = (val) => {
        setmobileValue(val);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                mobile: val
            }
        });

    }
    const isDuplicate = (inputValue) => {
        return cityList.some((option) => {
            let tmpLabel = option.label;
            let tmpLabels = tmpLabel.split('(');
            tmpLabel = trim(tmpLabels[0]);
            return tmpLabel.toLowerCase() === inputValue.toLowerCase()
        })
    };
    const isDuplicate2 = (inputValue) => {
        return cityList2.some((option) => {
            let tmpLabel = option.label;
            let tmpLabels = tmpLabel.split('(');
            tmpLabel = trim(tmpLabels[0]);
            return tmpLabel.toLowerCase() === inputValue.toLowerCase()
        })
    };

    

    useEffect(() => {
     
        axios.get(API_URL+"/industry", {
            headers: {
                "Accept": "application/json"
            }
        }).then((response) => {
            if (response.data.status === true) {
                const options = response.data.data.map(industry => ({
                    value: industry.id,
                    label: industry.industy_name
                }));
                setIndustries(options);
            } else {
                console.log("Something went wrong");
            }
        }).catch((error) => {
            console.log('Error fetching data:', error);
        });
    
    }, []);

    

    const fetchCompanyInfo = async () => {
        // e.preventDefault();
    await props.updateProfile(1)
                .then((response) => {
                    console.log('66666666==',response);
                    const fetchedData = {
                        first_name:response[0].first_name,
                        last_name:response[0].last_name,
                        email:response[0].email,
                        company_name:response[0].company_name,
                        website:response[0].website,
                        designation:response[0].designation,
                        address1:response[0].member_company_addresses.address1,
                        address2:response[0].member_company_addresses.address2,
                        pincode:response[0].member_company_addresses.pincode,
                    }
                    setFormData(fetchedData);
                    const fetchedLogo = response[0].company_logo;
                    setSelectedFile("http://localhost/orexTrade/orexTradeAPIs/storage/app/public/" + fetchedLogo);
                                        
                    // console.log('ffffffff', cityList);
                    const selectedOption = countryList.find(option => option.value == response[0].head_office);
                    const selectedOption2 = countryList2.find(option => option.value == response[0].branch_office);
                    const fetchedCompanySize = companySizeList.find(option => option.value == response[0].company_size);
                    const fetchedIndustries = industries.find(option => option.value == response[0].industry_id);
                    const fetchedShipmentMode = shipmentModeOption.find(option => option.value == response[0].shipment_mode);
                    const fetchedCountry = selectCountryList.find(option => option.value == response[0].country_id)
                    const fetchedOfficeType = officeTypeOption.find(option => option.value == response[0].member_company_addresses.office_type)
                    const fetchedSelectedCountry4 = selectCountryList2.find(option => option.value == response[0].member_company_addresses.country_id)
                    setSelectedCountry4(fetchedSelectedCountry4); 

                    setHeadOffice(selectedOption);
                    setBranchOffice(selectedOption2);
                    setCompanySize(fetchedCompanySize);
                    setIndustry(fetchedIndustries);
                    setShipmentMode(fetchedShipmentMode);
                    if(fetchedCountry){
                        setMemberCountry(fetchedCountry);
                        setCountry3(fetchedCountry);    
                        // setCity((prevCity) => {
                        //     return {...prevCity,city : response[0].cities_id}
                        // });                 
                        setCity(response[0].cities_id);
                        handleCountryChange3(fetchedCountry, 'companyInfo', response[0].cities_id);
                        
                        // console.log('cityList======', cityList);
                        // const selectedCity = cityList.find(option => option.value == response[0].cities_id);
                        // console.log('selectedCity=======', selectedCity);
                        // setSelectedCity(selectedCity);
                        
                    }
                    setPhone(response[0].phone);
                    setmobileValue(response[0].mobile);
                    setOfficeType(fetchedOfficeType);

                    // setCity(response[0].cities_id);
                    // console.log('cityList======', cityList);
                    // const selectedCity = cityList.find(option => option.value == response[0].cities_id);
                    // console.log('selectedCity=======', selectedCity);
                    // if(selectedCity) {
                    //     setSelectedCity(selectedCity);
                    // }
                        

                    
                    // setSelectedCity(response[0].cities_id);
                    
                })
                .catch((error) => {
                    console.log(error);
                });
        
    };

    useEffect(() => {
       
        const fetchData = async() => {
            await fetchCountries();
            await fetchCountries2();
            await fetchCountries3();
            await fetchCountries4();
            
        }
        fetchData();
    }, []);

    useEffect(() => {
        fetchCompanyInfo();
    },[countryList, countryList2, industries, selectCountryList, selectCountryList2]);

    const companySizeList = [
        { label: 'Select company size', value: null },
        { label: '1-10 employees', value: '1-10' },
        { label: '11-50 employees', value: '11-50' },
        { label: '51-200 employees', value: '51-200' },
        { label: '201-500 employees', value: '201-500' },
        { label: '501-1000 employees', value: '501-1000' },
        { label: '1000-5000 employees', value: '1000-5000' },
        { label: '5001-10000 employees', value: '5001-10000' },
        { label: '10,00+1 employees', value: '10000-1' },
    ];
    const shipmentModeOption = [
        { label: 'Select shipment mode', value: '' },
        { label: 'Import', value: '1' },
        { label: 'Export', value: '2' },
        { label: 'Logistics', value: '3' },
    ];
    const officeTypeOption = [
        { label: 'Select office type', value: '' },
        { label: 'Branch Office', value: 'branch' },
        { label: 'Head Office', value: 'head' },
    ];

    const handleSubmit = async(e) => {
        e.preventDefault();
        if (!validation()) {
        console.log("Form Submitted1 ::::: ", filedata);
        await props.updateCompany(1,formData, mobileValue, head_office, branch_office, company_size, industry, shipment_mode, selectedCity, phone, member_country, member_city, office_type, filedata)
        .then((response) => {
            const errors = {};
    
            // console.log('parth1=====', response);
            if (response.message === "Validation Error.") {
                // console.log('Validation Error Here', response.errors.last_name);
                if (!isEmpty(response.errors.first_name)) {
                    errors.first_name = response.errors.first_name[0];
                }
                if (!isEmpty(response.errors.last_name)) {
                    errors.last_name = response.errors.last_name[0];
                }
                if (!isEmpty(response.errors.mobileValue)) {
                    errors.mobileValue = response.errors.mobileValue[0];
                }
                if (!isEmpty(response.errors.email)) {
                    errors.email = response.errors.email[0];
                }
                if (!isEmpty(response.errors.company_name)) {
                    errors.company_name = response.errors.company_name[0];
                }
                if (!isEmpty(response.errors.head_office)) {
                    errors.head_office = response.errors.head_office[0];
                }
                if (!isEmpty(response.errors.branch_office)) {
                    errors.branch_office = response.errors.branch_office[0];
                }
                if (!isEmpty(response.errors.company_size)) {
                    errors.company_size = response.errors.company_size[0];
                }
                if (!isEmpty(response.errors.industry)) {
                    errors.industry = response.errors.industry[0];
                }
                if (!isEmpty(response.errors.shipment_mode)) {
                    errors.shipment_mode = response.errors.shipment_mode[0];
                }
                if (!isEmpty(response.errors.website)) {
                    errors.website = response.errors.website[0];
                }
                if (!isEmpty(response.errors.designation)) {
                    errors.designation = response.errors.designation[0];
                }
                if (!isEmpty(response.errors.phone)) {
                    errors.phone = response.errors.phone[0];
                }
            }
            setErrors(errors);
        })
        .catch((error) => {
            // setisButtonDisabled(false);
             // console.log(error);
         });
        }
    };

    const validation = () => {
        const errors = {};
        if (formData.first_name == '' || formData.first_name == null) {
            errors.first_name = errorMessages.FIELD_REQUIRED;
        }
        if (formData.last_name == '' || formData.last_name == null) {
            errors.last_name = errorMessages.FIELD_REQUIRED;
        }
        if (mobileValue == '' || mobileValue == null) {
            errors.mobileValue = errorMessages.FIELD_REQUIRED;
        }
        if (formData.company_name == '' || formData.company_name == null) {
            errors.company_name = errorMessages.FIELD_REQUIRED;
        }
        if (formData.website == '' || formData.website == null) {
            errors.website = errorMessages.FIELD_REQUIRED;
        }
        if (head_office == '' || head_office == null) {
            errors.head_office = errorMessages.FIELD_REQUIRED;
        }
        if (branch_office == '' || branch_office == null) {
            errors.branch_office = errorMessages.FIELD_REQUIRED;
        }
        if (formData.email == '' || formData.email == null) {
            errors.email = errorMessages.FIELD_REQUIRED;
        } else if (emailPattern.test(formData.email) == false) {
            errors.invalidEmail = errorMessages.INVALID_EMAIL;
        }
        if (company_size.value == '' || company_size.value == null) {
            errors.company_size = errorMessages.FIELD_REQUIRED;
        }
        if (industry == '' || industry == null) {
            errors.industry = errorMessages.FIELD_REQUIRED;
        }
        if (shipment_mode.value == '' || shipment_mode.value == null) {
            errors.shipment_mode = errorMessages.FIELD_REQUIRED;
        }
        if (formData.designation == '' || formData.designation == null) {
            errors.designation = errorMessages.FIELD_REQUIRED;
        }
        if (phone == '' || phone == null) {
            errors.phone = errorMessages.FIELD_REQUIRED;
        }
        setErrors(errors);
        return Object.keys(errors).length > 0;
    };

    const styleError = {
        display: 'block',
    };

    //

    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState([]);
    // const [others, setOthers] = useState();

    // const handleOtherServices = (e) => {
    //     setOthers({[e.target.name]: e.target.value})
        
    // }
    const fetchServices = () => {
        axios.get(API_URL+"/services", {
            headers: {
                "Accept": "application/json"
            }
        }).then((response) => {
            // console.log('pqrs===== ', response);
            if (response.data.success === true) {
                const options = response.data.data.map(service => ({
                    value: service.id,
                    label: service.service_name
                }));
                // console.log('options=====', options);
                // options.push({
                //     value: 'others',
                //     label: 'Others'
                // })
                // console.log('options=====', options);
                setServices(options);
            } else {
                console.log("Something went wrong");
            }
        }).catch((error) => {
            console.log('Error fetching data:', error);
        });
    } 
    
    
    const fetchMemberService = async () => {

    let mServices = [];
    await props.getMemberService(1)
                .then((response) => {
                    // console.log('fetchMemberService=React=',response);
                    // console.log(' services list ==== ', services);
                    
                    const memberServiceArray = response.map((res) => {
                        
                       const mService = services.find(option => option.value === res.service_id);
                        
                        if(mService) {
                            mServices.push(mService);
                        } 
                    }); 
                    setSelectedService(mServices); 
                })
                .catch((error) => {
                    console.log(error);
                });
        
    };
    
    useEffect(() => {
        if(size(services) > 0) {
            fetchMemberService();
        }        
    },[services]);

    useEffect(() => {      

        const fetchData = async() => {
            await fetchServices();
        }
        fetchData();

    },[]);
    
    const serviceValidation = () => {
        const errors = {};
        if (isEmpty(selectedService)) {
            errors.serviceError = errorMessages.FIELD_REQUIRED;
        }
        
        setErrors(errors);
        return Object.keys(errors).length > 0;
    };

    const handleServiceSubmit = async(e) => {
        e.preventDefault();
        if (!serviceValidation()) {
        // console.log('serviceFormSubmitted===', selectedService);
        await props.updateService(1, selectedService)
        .then((response) => {
            const errors = {};
    
            // console.log('serviceReact=====', response);
            if (response.message === "Validation Error.") {
                if (!isEmpty(response.errors.ser)) {
                    errors.serviceError = response.errors.ser[0];
                }
            }
            setErrors(errors);
        })
        .catch((error) => {
            // setisButtonDisabled(false);
             // console.log(error);
         });
        }
    }

    //
    const [array, setArray] = useState([0]);
    const [inputFields, setInputFields] = useState([{ salutation: 'Mr', first_name: '', last_name: '', email: '', designation: '', phone: '' }]);
    const [fetchedContactList, setFetchedContactList] = useState([]);

    

    const handleAddDiv = (event) => {
        if(array.length >= 2){
            event.target.style.display = "none";
        }
      if (array.length < 3) {
          setArray((prev) => [...prev, array.length]);
          setInputFields([...inputFields, { salutation: 'Mr', first_name: '', last_name: '', email: '', designation: '', phone: '' }]);
      }
  };
 
  const handleRemoveDiv = (idx) => {
    if(idx <= 2){
          document.getElementById('addButton').style.display='block';
    }
    if(array.length > 1) {
        setArray((prev) => prev.filter((_, index) => index !== idx));
        setInputFields((prev) => prev.filter((_, index) => index !== idx));
      }
  };

// const handleRemoveDiv = (index) => {
//     const newInputFields = inputFields.filter((_, idx) => idx !== index);
//     setInputFields(newInputFields);
// };

//   const handleRemoveDiv = (index) => {
//     const newInputFields = inputFields.filter((_, idx) => idx !== index);
//     setInputFields(newInputFields);
// };

  const handleContactChange = (e, idx) => {
    const { name, value } = e.target;
    const newInputFields = [...inputFields];
    newInputFields[idx][name] = value;
    setInputFields(newInputFields);
};

const handleContactPhoneChange = (val, idx) => {
    const newInputFields = [...inputFields];
    newInputFields[idx].phone = val;
    setInputFields(newInputFields);
};

  //
  const fetchContactInfo = async () => {
    // e.preventDefault();
await props.getContact(1)
            .then((response) => {
                console.log('contact==',response);
                setFetchedContactList(response);   
                setArray(response);
            })
            .catch((error) => {
                console.log(error);
            });
    
};

  //
  useEffect(() => {
    fetchContactInfo();
},[]);

// useEffect(() => {
//     setInputFields(fetchedContactList.map(item => ({
//         salutation: item.salutation,
//         first_name: item.first_name,
//         last_name: item.last_name,
//         email: item.email,
//         phone: item.phone,
//         designation: item.designation,
//     })));
// }, [fetchedContactList]);


useEffect(() => {
    setInputFields(fetchedContactList.length > 0 ? fetchedContactList.map(item => ({
        salutation: item.salutation,
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        phone: item.phone,
        designation: item.designation,
    })) : [{ salutation: "", first_name: "", last_name: "", email: "", phone: "", designation: "" }]);
}, [fetchedContactList]);


// console.log('array123456789===========', array);
  const handleContactSubmit  = async (e) => {
    e.preventDefault();
    console.log('inputFields==handleContactSubmit===', inputFields);
    
        // if (!serviceValidation()) {
        // console.log('serviceFormSubmitted===', selectedService);
        await props.updateContact(1, inputFields)
        .then((response) => {
            const errors = {};
    
            // console.log('serviceReact=====', response);
            if (response.message === "Validation Error.") {
                // if (!isEmpty(response.errors.ser)) {
                //     errors.contactError = response.errors.ser[0];
                // }
            }
            setErrors(errors);
        })
        .catch((error) => {
            // setisButtonDisabled(false);
             // console.log(error);
         });
        
        // }
  }
  // other section
  const [selectedDate, handleDateChange] = useState(new Date());
  const [about_company, setAboutCompany] = useState();
  const [yearOption, setYearOption] = useState([]);
  const [established_year, setEstablishedYear] = useState(null);
//   const [about_company, setAboutCompany] = useState();

const handleSelectChangeEstablishedYear = (event) => {
    setEstablishedYear(event)
    
        return {
            established_year: event.value
        }
    
}

let maxOffset = 100;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for(let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x)
    }
    // console.log('allYears==========', allYears);
    const yearsList = () => {
        const optionsYear = allYears.map(yr =>({
            value: yr,
            label: yr,
        })
    );
    setYearOption(optionsYear);  
    }
   
  const fetchOtherDetail = async () => {
    await props.getOtherDetail(1)
            .then((response) => {
                console.log('66666fetchOtherDetail666==',response);
                const fetchedAboutCompany = response[0].about_company;
                const fetchedEstablishedYear = response[0].Year;

                setAboutCompany(fetchedAboutCompany);

                const selectedYearOption = yearOption.find(option => option.value == response[0].Year);
                
                if (selectedYearOption) {
                    setEstablishedYear(selectedYearOption);  
                }
                
                
            })
            .catch((error) => {
                console.log(error);
            });
    
};
useEffect(() => {
    // fetchOtherDetail();
    yearsList();
},[]);
useEffect(() => {
    if(size(yearOption) > 0) {
        fetchOtherDetail();
    }        
},[yearOption]);
  const handleOtherSubmit = async(e) => {
    e.preventDefault();
    // console.log("handleOtherSubmit=========", established_year, about_company.data );
    await props.updateOtherDetail(1, established_year, about_company)
        .then((response) => {
            // const errors = {};
            console.log('responseOTHERDETAIL', response);
            // console.log('serviceReact=====', response);
            // if (response.message === "Validation Error.") {
            //     if (!isEmpty(response.errors.ser)) {
            //         errors.serviceError = response.errors.ser[0];
            //     }
            // }
            // setErrors(errors);
        })
        .catch((error) => {
            // setisButtonDisabled(false);
             // console.log(error);
         });
  }

  const [filename, setFilename] = useState('');
  const [selectedFile, setSelectedFile] = useState(uploadPhotos);
  const [filedata, setFileData] = useState(null);
  
  const handleProfileChange = (e) => {
    console.log("handleProfileChange called == ");
  }
 

    return (
            <div> 
                <div className="regi-form">
                
                    <form id="account-detail" className='border-area' onSubmit={handleSubmit}>
                        <div className="row px-5">
                        <h4>Company Information</h4>
                        <FileUpload  setFilename={setFilename} filename={filename} selectedFile={selectedFile} setSelectedFile={setSelectedFile} profileName={profileName} setProfileName={setProfileName} filedata={filedata} setFileData={setFileData}/>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <InputField label={"First Name *"} placeholder={"First Name *"} name="first_name" value={formData.first_name} onChange={handleChange}/>
                                {errors.first_name && <div className="invalid-feedback" style={styleError}>{errors.first_name}</div>}
                            </div>
                            
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <InputField label={"Last Name"} placeholder={"Last Name"} name="last_name" value={formData.last_name} onChange={handleChange}/>
                                {errors.last_name && <div className="invalid-feedback" style={styleError}>{errors.last_name}</div>}
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                            <label className="form-label required">Select Country</label>
                                <DropDown 
                                   options={selectCountryList} 
                                   value={member_country}
                                   onChange={handleCountryChange3}
                                   placeholder="Select country"
                                />  
                                {/* {errors.mobileValue && <div className="invalid-feedback" style={styleError}>{errors.mobileValue}</div>}    */}
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                            <label className="form-label required">Select City</label>
                                <CreatableSelect
                                    onChange={handleCityChange}
                                    onInputChange={handleInputChange}
                                    options={cityList}
                                    value={selectedCity}
                                    placeholder="Enter city"
                                    formatCreateLabel={(inputValue) =>
                                        isDuplicate(inputValue) ? null : `Create "${inputValue}"`
                                    }
                                    isValidNewOption={(inputValue) => !isDuplicate(inputValue)}

                                />                
                            </div>
                            
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <InputField label={"Email"} placeholder={"Email"} name="email" value={formData.email} onChange={handleChange}/>
                                {errors.email && <div className="invalid-feedback" style={styleError}>{errors.email}</div>}
                                {errors.invalidEmail && <div className="invalid-feedback" style={styleError}>{errors.invalidEmail}</div>}
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <InputField label={"Company Name"} placeholder={"Comapny Name"} name="company_name" value={formData.company_name} onChange={handleChange}/>
                                {errors.company_name && <div className="invalid-feedback" style={styleError}>{errors.company_name}</div>}
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label required">Head Office</label>
                                    <div className="position-relative">
                                    <Select
                                        name="head_office"
                                        options={countryList}
                                        value={head_office}
                                        onChange={handleCountryChange}
                                        placeholder="Select country"
                                    />
                                    {errors.head_office && <div className="invalid-feedback" style={styleError}>{errors.head_office}</div>}
                               </div>
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label required">Branch Office</label>
                                <div className="position-relative">
                                    <Select
                                        name="branch_office"
                                        options={countryList2}
                                        value={branch_office}
                                        onChange={handleCountryChange2}
                                        placeholder="Select country"
                                    />
                                    {errors.branch_office && <div className="invalid-feedback" style={styleError}>{errors.branch_office}</div>}
                                </div>
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                            <label className="form-label required">Company Size</label>
                                <DropDown 
                                    options={companySizeList}
                                    value={company_size}
                                    onChange={handleSelectChangeCompanySize}
                                    name="company_size" 
                                    id="company_size"
                                />
                                {errors.company_size && <div className="invalid-feedback" style={styleError}>{errors.company_size}</div>}
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                            <label className="form-label required">Company Industry</label>
                                <DropDown 
                                    options={industries} 
                                    value={industry} 
                                    onChange={handleIndustryChange} 
                                    placeholder="Select industry"
                                />
                                {errors.industry && <div className="invalid-feedback" style={styleError}>{errors.industry}</div>}
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                            <label className="form-label required">Shipment Mode</label>
                                <DropDown
                                    options={shipmentModeOption}
                                    onChange={handleShipmenMode}
                                    value={shipment_mode}
                                    name='shipment_mode'
                                    id='shipment_mode'
                                 />
                                 {errors.shipment_mode && <div className="invalid-feedback" style={styleError}>{errors.shipment_mode}</div>}
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <InputField label={"Website"} placeholder={"Website"} name="website" value={formData.website} onChange={handleChange}/>
                                {errors.website && <div className="invalid-feedback" style={styleError}>{errors.website}</div>}
                            </div>
                            <div className="col-md-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label inquiry-text " for="contact-form-mobile">Mobile</label>
                                <PhoneInput id="contact-form-mobile" className="form-control inquiry-mobile inp-font-size border-dropdown" name="mobile"
                                international limitMaxLength
                                placeholder="Enter mobile"
                                defaultCountry={countryCode}
                                value={mobileValue}
                                onChange={handleMobileChange}                     
                                />
                                {errors.mobileValue && <div className="invalid-feedback" style={styleError}>{errors.mobileValue}</div>}
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                            <label className="form-label inquiry-text required">Phone</label>
                                <PhoneInput className="form-control inquiry-phone inp-font-size border-dropdown" name="phone"
                                    international limitMaxLength
                                    placeholder="Enter phone"
                                    defaultCountry={countryCode}
                                    value={phone}
                                    onChange={handlePhoneChange}
                                />
                                {errors.phone && <div className="invalid-feedback" style={styleError}>{errors.phone}</div>}
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <InputField label={"Designation"} placeholder={"Designation"} name="designation" value={formData.designation} onChange={handleChange}/>
                                {errors.designation && <div className="invalid-feedback" style={styleError}>{errors.designation}</div>}
                            </div>

                            <h4 className="mt-3">Member Company Address</h4>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                            <label className="form-label required">Select Country</label>
                                <DropDown 
                                   options={selectCountryList2} 
                                   value={selectedCountry4}
                                   onChange={handleCountryChange4}
                                   placeholder="Select country"
                                />     
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                            <label className="form-label required">Select City</label>
                                <CreatableSelect
                                    onChange={handleCityChange2}
                                    onInputChange={handleInputChange2}
                                    options={cityList2}
                                    value={member_city}
                                    placeholder="Enter city"
                                    formatCreateLabel={(inputValue) =>
                                        isDuplicate2(inputValue) ? null : `Create "${inputValue}"`
                                    }
                                    isValidNewOption={(inputValue) => !isDuplicate2(inputValue)}

                                />                
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <InputField label={"Address1"} placeholder={"Address1"} name="address1" value={formData.address1} onChange={handleChange}/>
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <InputField label={"Address2"} placeholder={"Address2"} name="address2" value={formData.address2} onChange={handleChange}/>
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <InputField label={"Pincode"} placeholder={"Pincode"} type="number" name="pincode" value={formData.pincode} onChange={handleChange}/>
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                            <label className="form-label required">Office Type</label>
                                <DropDown
                                    options={officeTypeOption}
                                    onChange={handleOfficeType}
                                    value={office_type}
                                    name='office_type'
                                    id='office_type'
                                 />
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn-get">Save</button>
                            </div>
                        </div>
                    </form>
                    </div>
                    <div className="regi-form">
                    <form className='border-area' onSubmit={handleServiceSubmit}>
                    <div className="row px-5">
                    <h4>Member Services</h4>
                    <div className='col-sm-6 mt-3 fv-plugins-icon-container'>
                            <label className="form-label required">Select Services</label>
                            <div className='col-sm-10'>
                            <div>
                            <MultiSelect
                            options={services}
                            value={selectedService}
                            onChange={setSelectedService}
                            // defaultValue={s[2]}
                            labelledBy="Select Services"
                            />
                            {errors.serviceError && <div className="invalid-feedback" style={styleError}>{errors.serviceError}</div>}
                            </div>
                            </div>

                        </div>
                        {/* {service[0].value === "others" && (
                            <InputField label={"Other Services"} placeholder={"Other Services"} name="others" value={others} onChange={handleOtherServices}/>
                        )} */}
                        <div className="col-12">
                                <button type="submit" className="btn-get">Save</button>
                            </div>
                        </div>
                        </form>
                    </div>
                    <div className="regi-form">

                    

                    <form id="company-representative" className='border-area' onSubmit={handleContactSubmit}>

                        <div className='contect-btn'>

                            <button type="button" className='add-remove add-efect' onClick={handleAddDiv} id="addButton">
                                <span className='remove_icon add-icon-color'>+</span>
                                Add</button>

                        </div>


                        {inputFields.map((item, idx) => (
                            
                            <div key={idx} id={idx} className="dynamicDiv">
                                {idx !== 0 && (                                    
                                    <div className='contect-btn'>
                                    <button  type="button" onClick={() => handleRemoveDiv(idx)} className='add-remove remove-efect'>
                                        <span className='remove_icon '> -</span>
                                        Remove
                                    </button>
                                    </div>
                                )}
                            <div className="row mt-5 px-5">
                            <div className="justify-content-between mt-3">
                                {/* <button onClick={handleAddDiv} className="btn-success text-white add-button" id="addButton">Add</button> */}
                            </div>                        
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                    <label className="form-label required">First Name</label>
                                    <div className="input-group">
                                        <span className="input-group-addon">
                                        <select
                                            name="salutation"
                                            value={item.salutation}
                                            onChange={(e) => handleContactChange(e, idx)}>
                                        
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss">Miss</option>
                                        </select>
                                        </span>
                                        <input
                                            type="text"
                                            name="first_name"
                                            className="form-control"
                                            placeholder="Enter first name"
                                            value={item.first_name}
                                            onChange={(e) => handleContactChange(e, idx)}
                                        />
                                        
                                    </div>
                                </div>

                                <div className="col-sm-6 mt-3 fv-plugins-icon-container">

                                    <label className="form-label required">Last Name</label>
                                    <input type="text" name="last_name" id="last_name" className="form-control"  onChange={(e) => handleContactChange(e, idx)} placeholder="Enter last name" value={item.last_name}/>
                                
                                </div>

                                <div className="col-sm-6 mt-3 fv-plugins-icon-container">

                                    <label className="form-label required">Email</label>
                                    <input type="text" name="email" id="email" className="form-control" onChange={(e) => handleContactChange(e, idx)} placeholder="Enter email" value={item.email} />
                                
                                </div>

                                    <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                    <label className="form-label required">Phone</label>
                                     <PhoneInput id="contact-form-phone" className="form-control inquiry-phone inp-font-size border-dropdown" name="phone"
                                        international limitMaxLength
                                        placeholder="Enter contact"
                                        defaultCountry="IN"
                                        onChange={(e) => handleContactPhoneChange(e, idx)}
                                        value={item.phone}
                                    />
                                </div>

                                <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                    <label className="form-label required">Designation</label>
                                    <input type="text" name="designation" id="designation" className="form-control" onChange={(e) => handleContactChange(e, idx)} placeholder="Enter designation" value={item.designation} />
                                </div>
                            </div>                            
                        </div>
                     ))}
                     
                        <div className="col-12 d-flex justify-content-between  mt-3 ">
                                        <button type="submit" className="btn-regi text-white border-0 regi-next" >Save
                                        </button>

                                    </div>
                   
                </form>

                    </div>
                    <div className="regi-form">
                    <form className='border-area' onSubmit={handleOtherSubmit}>
                    
                    <div className="row px-5">
                    <h4>Other Details</h4>
                    <div className='col-sm-6 mt-3 fv-plugins-icon-container'>
                            <label className="form-label required">About Company</label>
                            <div className='col-sm-10'>
                            <div>
                            
                            {/* <TextAreaField value={about_company} onChange={handleTextAreaChange}/> */}
                            <CKEditor
                                editor={ ClassicEditor }
                                data={about_company}
                                config={ {
                                    toolbar: {
                                        items: [ 'undo', 'redo', '|', 'bold', 'italic' ],
                                    },
                                    plugins: [
                                        Bold, Essentials, Italic, Mention, Paragraph, Undo
                                    ],
                                    licenseKey: '<YOUR_LICENSE_KEY>',
                                    mention: {
                                        // Mention configuration
                                    },
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    console.log(data);
                                    setAboutCompany(data);
                                } }
                            />
                            {errors.aboutCompanyError && <div className="invalid-feedback" style={styleError}>{errors.aboutCompanyError}</div>}
                            </div>
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                            <label className="form-label required">Established Year</label>
                                <DropDown 
                                    options={yearOption}
                                    value={established_year}
                                    onChange={handleSelectChangeEstablishedYear}
                                />
                                {errors.established_year && <div className="invalid-feedback" style={styleError}>{errors.established_year}</div>}
                            </div>
                            </div>

                        </div>
                        {/* {service[0].value === "others" && (
                            <InputField label={"Other Services"} placeholder={"Other Services"} name="others" value={others} onChange={handleOtherServices}/>
                        )} */}
                        <div className="col-12">
                                <button type="submit" className="btn-get">Save</button>
                            </div>
                        </div>
                        </form>
                    </div>
                    </div>
                    
)
}

const mapStateToProps = state => {
    return {
        
        update: state.update.updateProfile,
        updateCompany: state.update.updateCompany,
        updateService: state.update.updateService,
        getContact: state.update.getContact,
        getMemberService: state.update.getMemberService,
        updateContact: state.update.updateContact,
        getOtherDetail: state.update.getOtherDetail,
        updateOtherDetail: state.update.updateOtherDetail
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateProfile: ($member_id) => dispatch(updateProfileAction($member_id)),
        
        updateCompany: ($member_id, formData, mobileValue, head_office, branch_office, company_size, industry, shipment_mode, selectedCity, phone, member_country, member_city, office_type, profileName) => dispatch(updateCompanyAction($member_id, formData, mobileValue, head_office, branch_office, company_size, industry, shipment_mode, selectedCity, phone, member_country, member_city, office_type, profileName)),

        updateService: ($member_id, service) => dispatch(updateServiceAction($member_id, service)),
        getContact: ($member_id) => dispatch(getContactAction($member_id)),
        getMemberService: ($member_id) => dispatch(getMemberServiceAction($member_id)),
        updateContact: ($member_id, inputFields) => dispatch(updateContactAction($member_id, inputFields)),
        getOtherDetail: ($member_id) => dispatch(getOtherDetailAction($member_id)),
        updateOtherDetail: ($member_id, established_year, about_company) => dispatch(updateOtherDetailAction($member_id, established_year, about_company))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile);