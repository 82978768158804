import React from 'react'
import { Button } from 'react-bootstrap';
import { Bounce, toast, ToastContainer } from 'react-toastify';

function AlertSuccess({message}) {


    const success = () => toast.success(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

  return (
    <div>
       <Button onClick={success} className='btn btn-success mx-2'>Success</Button>  
       <ToastContainer/>
      
    </div>
  )
}

export default AlertSuccess
