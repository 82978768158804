import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import defaultValues from '../../constant/defaultValues'; 

import { createUser, deleteUser, getUser, getUserList } from '../../actions/userAction';

import AcSimpleTable from "../../components/AcSimpleTable";
import InputField from '../../components/InputField';
import MediumPopup from '../../components/MediumPopup';

import DropDown from '../../components/DropDown';
import { getMemberList } from '../../actions/memberAction';
import { isEmpty } from 'lodash';
import { map, toPairs, fromPairs, keys, values } from 'lodash';
import AlertSuccess from '../AlertSuccess';
import { Bounce, toast } from 'react-toastify';

import SmallPopup from '../../components/SmallPopup';

function Users(props) {

    const { admin, USER_DEFAULTS } = defaultValues;
    const statusList = map(toPairs(USER_DEFAULTS.STATUS), d => fromPairs([d]));

    let sList = map(statusList, (s) => {
        return {
            label: keys(s)[0],
            value: values(s)[0]
        }
    });

    const [usersList, setUsersList] = useState([]);
    const [offset, setOffset] = useState(admin.OFFSET);
    const [limit, setLimit] = useState(admin.LIMIT);
    const [searchTxt, setSearchTxt] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totalRows, settotalRows] = useState(0);

    const [mediummodalShow, setMediumModalShow] = useState(false);
    const [smallmodalShow, setSmallModalShow] = useState(false);

    const [memberList, setMemberList] = useState([]);

    const [formdata, setFormData] = useState({
        name: '',
        email: '',
        member_id: '',
        status_id: 1
    });

    const [deleteUserId, setDeleteUserId] = useState();
    const [editeduserId, setEditedUserId] = useState();
    const [statusOptions, setStatusOptions] = useState(sList);
    const [selectedStatus, setSeletecStatus] = useState();
    const [selectedCompany, setSelectedCompany] = useState();

    const columns = [
        {
          name: 'Id',
          selector: row => row.id,
      }, 
          {
              name: 'Name',
              selector: row => row.name,
              minWidth: '200px'
          },
          {
              name: 'Email',
              selector: row => row.email,
              minWidth: '200px'
          },
          {
              name: 'Company Name',
              selector: row => row.member.company_name,
              minWidth: '200px'
          },
          {
            name: 'Role',
            selector: row => row.role.name,
            minWidth: '200px'
          },
          {
            name: 'Actions',
            cell:(row) => <>                
                {/* <button className='btn' onClick={() => handleEditClick(row.id)}>
                    <i class="fa-regular fa-pen-to-square table-icon-color"></i>
                </button> */}
                <button className='btn' onClick={() => handleDeleteClick(row.id)}>
                    <i class="fa-regular fa fa-trash table-icon-color"></i>
                </button>
            </>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          }
    ];

    const handleAddUserClick = () => {
        console.log("Add User Click");
        setMediumModalShow(true);
    }

    const handleEditClick = async (id) => {
        console.log("Edit click called.", id);
        await props.getUser(id).then((response) => {
            console.log("response ========== ", response);
            setFormData({ name: response.data[0].name,
                email: response.data[0].email,
                member_id: response.data[0].member.id,
                status: response.data[0].status
            });
            setEditedUserId(id);
            setMediumModalShow(true);
            
            
        }).catch((err) => {
            console.log("Error ===== ", err);
        })

    }

    const handleDeleteClick = (id) => {
        console.log("Delete function called..",id);
        setSmallModalShow(true);
        setDeleteUserId(id);
    };

    const handleConfirmDeleteClick = () => {
        props.deleteUser(deleteUserId).then((response) => {
            if(response.status === true) {
                setOffset(admin.OFFSET);
                setLimit(admin.LIMIT);
                setSearchTxt('');
                getmappedUsers(admin.OFFSET,admin.LIMIT,'');
            }
            setSmallModalShow(false);
            
        }).catch((err) => {
            console.log("Error == ", err);
        });

    }

    const handleChange = (e) => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                [e.target.name]: e.target.value
            }
        })

    }

    const handleCompanyChange = (cval) => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                member_id: cval.value
            }
        });
        setSelectedCompany(cval);
    }

    const handleInputChange = (inputVal) => {
        if(inputVal.length >= 3) {
            console.log("API call ");
            getRegisteredMembers(offset,limit,inputVal);
        } else {
            getRegisteredMembers(offset,limit,searchTxt);
        }
    }

    const validForm = () => {
        console.log("Valid Form ========= ");
        console.log("Form Data === ", formdata);
        if(isEmpty(formdata.name)) {

        }
        return true;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form is submitted");
        if(validForm()) {
            await props.saveUser(formdata).then((response) => {
                console.log("Response ==== ", response);
                if(response.status === true) {
                    console.log("Here============== ");
                    setMediumModalShow(false);
                    setOffset(admin.OFFSET);
                    setLimit(admin.LIMIT);
                    setSearchTxt('');
                    getmappedUsers(admin.OFFSET,admin.LIMIT,'');
                    toast.success('🦄 Wow so easy!', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                        });
                }
            }).catch((err) => {
                console.log("Error ====== ", err);
            })
        }
    }

    const onSearchTextBoxChanged = (e) => {
        setSearchTxt(e.target.value);
        setOffset(admin.OFFSET);
        setLimit(admin.LIMIT);
        getmappedUsers(admin.OFFSET, admin.LIMIT, searchTxt);
    }


    const getmappedUsers = async (offset, limit, searchTxt) => {
        await props.getUsers(offset,limit,searchTxt).then((response) => {
            setUsersList(response.data);
            settotalRows(response.metadata.total);
        }).catch((err) => {
            console.log("Error ==== ", err);
        })
    }

    const getRegisteredMembers = async (offset, limit, searchTxt) => {
        await props.getMembers(offset,limit,searchTxt).then((response) => {
            let memberData = response.data;
            const options = memberData.map(m => ({
                value: m.id,
                label: m.first_name+' '+m.last_name
            }));
            setMemberList(options);            
        }).catch((err) => {
            console.log("Error ==== ", err);
        })
    }

    useEffect(() => {
        if(mediummodalShow) {
            getRegisteredMembers(0, 10, '');
        }
        
    },[mediummodalShow]);

    useEffect(() => {
        getmappedUsers(offset, limit, searchTxt);
    },[]);

    return(<>


        <div>
            <div className={"ag-theme-quartz card shadow mt-5"} style={{ height: 400 }}>
            <div className='d-flex justify-content-end align-items-center pt-2 pe-3 '>
                {/* <button className='btn-regi text-white mx-1 data-export-btn'>Export</button> */}
                <button type="button" className=' mx-1 btn-regi text-white' onClick={handleAddUserClick}> + Add New User</button>
            </div>

            <MediumPopup
                title="Create User"
                mediummodalShow={mediummodalShow}
                setMediumModalShow={setMediumModalShow}
                onSubmit={handleSubmit}
            >
                <form className='col-sm-12 py-4 ps-5 pe-5' onSubmit={handleSubmit}>
                    <div className='row justify-content-cetner align-items-center'>
                        <label className='col-sm-12 mb-2' >Name</label>
                        <div className='col-sm-12'>
                            <InputField className=" form-error " placeholder={"Enter Your Name"} name="name" onChange={handleChange} value={formdata.name}></InputField>
                            <p className="form-text-error">Please enter your name</p>
                        </div>
                    </div>

                    <div className='row justify-content-cetner align-items-center my-2'>
                        <label className='col-sm-12 mb-2' >Email</label>
                        <div className='col-sm-12'>
                            <div className="form-error form-error-textarea">
                                <InputField className=" form-error " placeholder={"Enter Your Email"} name="email" onChange={handleChange} value={formdata.email}></InputField>
                            </div>
                            <p className="form-text-error">Please enter text</p>
                        </div>
                    </div>
                    <div className='row justify-content-cetner align-items-center my-2'>
                        <label className='col-sm-12 mb-2' >Company</label>
                        <div className='col-sm-12'>
                            <div className="form-error form-error-textarea">
                                <DropDown options={memberList} placehoder="Select Company" onChange={handleCompanyChange} onInputChange={handleInputChange} name="company_id" ></DropDown>
                            </div>
                            <p className="form-text-error">Please enter text</p>
                        </div>
                    </div>
                    { editeduserId && <div className='row justify-content-cetner align-items-center my-2'>
                        <label className='col-sm-12 mb-2' >Status</label>
                        <div className='col-sm-12'>
                            <div className="form-error form-error-textarea">
                                <DropDown options={statusOptions} placehoder="Select Status" onChange={handleCompanyChange} onInputChange={handleInputChange} name="status_id" value={formdata.status_id}></DropDown>
                            </div>
                            <p className="form-text-error">Please enter text</p>
                        </div>
                    </div>
                    }
                </form>
                
            </MediumPopup>
            <SmallPopup
            title="Delete User"
                smallmodalShow={smallmodalShow}
                setSmallModalShow={setSmallModalShow}
                onSubmit={handleConfirmDeleteClick}
                closeButtonLabel="Cancel"
                okButtonLabel="Delete"
            >
                <p>
                    Are you sure you want to delete user?
                </p>
            </SmallPopup>

                <div className='d-flex justify-content-between align-items-center ps-3 pe-3'>
                    <h5 className='table-text'>Users</h5>
                    <div className='d-flex justify-content-between align-items-center p-3'>
                        <label className='mx-3 table-text'>Search :</label>
                        <InputField
                            type="text"
                            id="filter-text-box"
                            placeholder="Search..."
                            className='w-25'
                            onChange={onSearchTextBoxChanged}
                        ></InputField>
                    </div>

                </div>

                {usersList && <AcSimpleTable data={usersList} title="Users List" pagination={true} paginationPageSize={limit}
                    columns={columns}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    fixedColumnsStart={admin.FIXED_COLUMNS_START}
                    getData={getmappedUsers}
                    offset={offset}
                    limit={limit}
                    setLimit={setLimit}
                    setOffset={setOffset}
                    setLoading={setLoading}
                    noDataComponent="Your Text Here"
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
            /> }
            </div>
        </div>
      
    </>)
}

const mapStateToProps = state => {

    return {
        user_list: state.users.userlist,
        member_list: state.member.memberlist,
        // authError: state.auth.errorMessages
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUsers: (offset, limit, searchTxt) => dispatch(getUserList(offset, limit, searchTxt)),
        getMembers: (offset, limit, searchTxt) => dispatch(getMemberList(offset, limit, searchTxt)),
        saveUser: (data) => dispatch(createUser(data)),
        deleteUser: (id) => dispatch(deleteUser(id)),
        getUser: (id) => dispatch(getUser(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Users);
