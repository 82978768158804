const initialState = {
  isAuthenticated: false,
  login: {},
  errorMessages: {}
};
const loginReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGIN_ACTION':
        return {
          isAuthenticated: true,
          ...state,login:action.payload
        }
        // case 'LOGIN_ACTION_FAILURE':
        //   return {
        //     isAuthenticated: false,
        //   ...state,errorMessages:action.payload
        //   }
      case 'FORGOT_PASSWORD_ACTION':
        return {
          ...state,forgotPassword:action.payload
        }
      case 'RESET_PASSWORD_ACTION':
          return {
            ...state,resetPassword:action.payload
          }
     default:
      return state
    }
   }
   export default loginReducer;