import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';

import API_URL from './config/apiConfig';
import ROUTES from './config/routePath';

import errorMessages from './constant/errorMessages';

import AcounInfo from './AcounInfo';
import { property } from './App';

import regiImg from "./images/registra.jpg";


function AccountDetail() {

    const nav = useNavigate();
    const { account,compney,compnytext, RegiIcon } = useContext(property);
    const [isButtonDisabled, setisButtonDisabled] = useState(false);
    React.useEffect(() => {
        if (account && account[0]) {
            account[0].style.border = "1px solid #0b4471";
            account[0].style.borderTopLeftRadius = "6px";
            account[0].style.borderTopRightRadius = "6px";
            account[0].style.backgroundColor = "#0b4471";
        }

        if (compney && compney[0]) {
            compney[0].style.color = "white";
        }

        if (compnytext && compnytext[0]) {
            compnytext[0].style.color = "white";
        }

    }, [account, compney, compnytext]);


    const [formData, setFormData] = useState({
        company_name: "",
        head_office: "",
        branch_office: "",
        company_size: "",
        industry: "",
        shipment_mode: "",
        website: "",
        with_us:"",
        page1: 1
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validation()) {
            try {
                axios.post(API_URL + "/member/step1",
                    formData,
                    {
                        headers: {
                            "Accept": "application/json"
                        }

                    }).then((response) => {

                        if (response.data.status === true) {
                            setVErrors([]);
                            localStorage.setItem('registration-detail', JSON.stringify(formData));
                            nav(ROUTES.COMPANYREPRESENTATIVE);
                        } else {
                            setVErrors([]);
                            const errors = {};
                            if (response.data.error.company_name) {
                                errors.company_name = response.data.error.company_name;
                            }
                            if (response.data.error.head_office) {
                                errors.head_office = response.data.error.head_office;
                            }
                            if (response.data.error.branch_office) {
                                errors.branch_office = response.data.error.branch_office;
                            }
                            if (response.data.error.company_size) {
                                errors.company_size = response.data.error.company_size;
                            }
                            if (response.data.error.industry) {
                                errors.industry = response.data.error.industry;
                            }
                            if (response.data.error.shipment_mode) {
                                errors.shipment_mode = response.data.error.shipment_mode;
                            }
                            if (response.data.error.website) {
                                errors.website = response.data.error.website;
                            }

                            setVErrors(errors);
                            return Object.keys(errors).length > 0;
                        }
                    }).catch((error) => {
                        console.error('Error fetching data:', error);
                    });
            } catch (err) {
                console.error(err);
            }
        }
    };


    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const [countryList2, setCountryList2] = useState([]);
    const [selectedCountry2, setSelectedCountry2] = useState(null);

    const [industries, setIndustries] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState(null);

    useEffect(() => {
        try {

            const companyData = localStorage.getItem('registration-detail');
            const company = JSON.parse(companyData);

            if (company) {
                const selectedOption = countryList.find(option => option.value === company.head_office);
                const selectedOption2 = countryList.find(option => option.value === company.branch_office);
                const industry = industries.find(option => option.value === company.industry);

                setFormData(prevData => ({
                    ...prevData,
                    company_name: company.company_name || "",
                    head_office: company.head_office || "",
                    branch_office: company.branch_office || "",
                    company_size: company.company_size,
                    industry: company.industry,
                    shipment_mode: company.shipment_mode,
                    website: company.website || "",
                    with_us:company.with_us || ""
                }));

                setSelectedCountry(selectedOption);
                setSelectedCountry2(selectedOption2);
                setSelectedIndustry(industry);

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [countryList, industries]);

    const fetchCountries = async () => {
        await axios.get(API_URL + '/countries').then((response) => {
            if (response.status === 200) {
                if (response.data.status === true) {
                    const options = response.data.data.map(country => ({
                        value: country.id,
                        label: country.name,
                        countryCode: country.iso2
                    }));
                    setCountryList(options);
                }
            }
        }).catch((err) => {
            console.log("Error ========== ", err);
        })
    }

    const fetchCountries2 = async () => {
        await axios.get(API_URL + '/countries').then((response) => {
            if (response.status === 200) {
                if (response.data.status === true) {
                    const options = response.data.data.map(country => ({
                        value: country.id,
                        label: country.name,
                        countryCode: country.iso2
                    }));
                    setCountryList2(options);
                }
            }
        }).catch((err) => {
            console.log("Error ========== ", err);
        })
    }




    const handleCountryChange = async (cval) => {
        const countryId = cval.value;
        setSelectedCountry(cval);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                head_office: countryId
            }
        });
        let er = { ...verrors };
        er.head_office = '';
        setVErrors(er);
    }

    useEffect(() => {

        axios.get(API_URL + "/industry", {
            headers: {
                "Accept": "application/json"
            }
        }).then((response) => {
            if (response.data.status === true) {
                const options = response.data.data.map(industry => ({
                    value: industry.id,
                    label: industry.industy_name
                }));
                setIndustries(options);
            } else {
                console.log("Something went wrong");
            }
        }).catch((error) => {
            console.log('Error fetching data:', error);
        });

    }, []);

    const handleIndustryChange = (cval) => {
        const industryId = cval.value
        setSelectedIndustry(cval);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                industry: industryId
            }
        });
        let er = { ...verrors };
        er.industry = '';
        setVErrors(er);

    }

    const handleSelectChangeCompanySize = (event) => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                company_size: event.target.value
            }
        });
        let er = { ...verrors };
        er.company_size = '';
        setVErrors(er);
    }

    const handleCountryChange2 = (cval) => {
        const countryId = cval.value;
        setSelectedCountry2(cval);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                branch_office: countryId
            }
        });
        let er = { ...verrors };
        er.branch_office = '';
        setVErrors(er);
    }

    const handleShipmenMode = (event) => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                shipment_mode: event.target.value
            }
        });
        let er = { ...verrors };
        er.shipment_mode = '';
        setVErrors(er);
    }

    const handleSelectWithUs = (event) => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                with_us: event.target.value
            }
        });
    }

    const handleChange = (e) => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                [e.target.name]: e.target.value
            }
        });
    }

    useEffect(() => {
        fetchCountries();
        fetchCountries2();
    }, []);

    const [message, setMessage] = useState('');
    useEffect(() => {
        var msg = localStorage.getItem('successMessage');
        if (msg) {
            setMessage(msg);
            const timeoutId = setTimeout(() => {
                setMessage("");
                localStorage.removeItem('successMessage');
            }, 4000);
            return () => clearTimeout(timeoutId);
        }
    }, []);
    const [verrors, setVErrors] = useState({});
    const validation = () => {
        let er = { ...verrors };
        let flag = 0;
        if (formData.company_name === '' || formData.company_name === null) {
            flag = 1;
            er.company_name = errorMessages.FIELD_REQUIRED;
        }
        if (formData.head_office === '' || formData.head_office === null) {
            flag = 1;
            er.head_office = errorMessages.FIELD_REQUIRED;
        }
        if (formData.branch_office === '' || formData.branch_office === null) {
            flag = 1;
            er.branch_office = errorMessages.FIELD_REQUIRED;
        }
        if (formData.company_size === '' || formData.company_size === null) {
            flag = 1;
            er.company_size = errorMessages.FIELD_REQUIRED;
        }
        if (formData.industry === '' || formData.industry === null) {
            er.industry = errorMessages.FIELD_REQUIRED;
        }
        if (formData.shipment_mode === '' || formData.shipment_mode === null) {
            flag = 1;
            er.shipment_mode = errorMessages.FIELD_REQUIRED;
        }
        if (formData.website === '' || formData.website === null) {
            flag = 1;
            er.website = errorMessages.FIELD_REQUIRED;
        }


        setVErrors(er);
        if (flag === 1) {
            return false;
        } else {
            return true;
        }
    };

    const handleFocusOut = (e) => {
        let flag = 0;
        let er = { ...verrors };
        if (e.target.name === 'company_name') {
            if (e.target.value === '') {
                flag = 1;
                er.company_name = errorMessages.FIELD_REQUIRED;
            } 
            else if (e.target.value.length > 40) {
                flag = 1;
                er.company_name = errorMessages.MAX_LENGTH_EXCEEDED; 
            } else {

                const regExp = /^(?=.*[a-zA-Z])(?!(?:\d+)$)[a-zA-Z0-9 .&@]+$/;
                const check = regExp.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.company_name = errorMessages.IN_VALID_FIELD_FORMAT;
                }

                else {
                    flag = 0;
                    er.company_name = '';
                }
            }
        }

        if (e.target.name === 'website') {
            if (e.target.value === '') {
                flag = 1;
                er.website = errorMessages.FIELD_REQUIRED;
            } else {
                const regExp = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/;
                const check = regExp.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.website = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.website = '';
                }
            }
        }

        setVErrors(er);
        if (flag === 0) {
            setisButtonDisabled(false);
            return false;
        } else {
            setisButtonDisabled(true);
            return true;
        }
    }

    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem('registration-detail');
            return true;
        };
        window.onbeforeunload = handleBeforeUnload;
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const styleError = {
        display: 'block',
    };

    return (

        <div>
            <div className="main-regi">

                <div className="regi-menIMG">
                    <img src={regiImg} alt='no img' />
                </div>

                <div className="regi-form">

                    <AcounInfo />

                    <form id="account-detail" className='border-area' onSubmit={handleSubmit}>

                        <div className="row px-5">
                            {message && <div className="alert alert-success">{message}</div>}
                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label required">Company Name</label>
                                <input type="text" name="company_name" id="company_name" className="form-control" onChange={handleChange} placeholder="Enter company name" onBlur={handleFocusOut} value={formData.company_name} />
                                {verrors.company_name && <div className="invalid-feedback" style={styleError}>{verrors.company_name}</div>}

                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label required">Head Office</label>
                                <div className="position-relative">
                                    <Select
                                        name="head_office"
                                        options={countryList}
                                        value={selectedCountry}
                                        onChange={handleCountryChange}
                                        placeholder="Select country"
                                    />
                                    {verrors.head_office && <div className="invalid-feedback" style={styleError}>{verrors.head_office}</div>}
                                </div>
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label required">Branch Office</label>
                                <div className="position-relative">
                                    <Select
                                        name="branch_office"
                                        options={countryList2}
                                        value={selectedCountry2}
                                        onChange={handleCountryChange2}
                                        placeholder="Select country"
                                    />
                                    {verrors.branch_office && <div className="invalid-feedback" style={styleError}>{verrors.branch_office}</div>}
                                </div>
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label required">Company Size</label>
                                <div className="position-relative">

                                    <select onChange={handleSelectChangeCompanySize} name="company_size" id="company_size" className="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="multiStepsState" tabIndex="-1" aria-hidden="true" value={formData.company_size}>

                                        <option value="" data-select2-id="2">Select company size</option>
                                        <option value="1-10">1-10 employees</option>
                                        <option value="11-50">11-50 employees</option>
                                        <option value="51-200">51-200 employees</option>
                                        <option value="201-500">201-500 employees</option>
                                        <option value="501-1000">501-1000 employees</option>
                                        <option value="1000-5000">1000-5000 employees</option>
                                        <option value="5001-10000">5001-10,000 employees</option>
                                        <option value="10000-1">10,00+1 employees</option>
                                    </select>
                                    {verrors.company_size && <div className="invalid-feedback" style={styleError}>{verrors.company_size}</div>}
                                </div>
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label required">Company Industry</label>
                                <div className="position-relative">
                                    <Select
                                        name="industry"
                                        options={industries}
                                        value={selectedIndustry}
                                        onChange={handleIndustryChange}
                                        placeholder="Select industry"
                                    />
                                    {verrors.industry && <div className="invalid-feedback" style={styleError}>{verrors.industry}</div>}
                                </div>
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label required">Trade mode</label>
                                <div className="position-relative">

                                    <select onChange={handleShipmenMode} name="shipment_mode" id="shipment_mode" className="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="multiStepsState" tabIndex="-1" aria-hidden="true" value={formData.shipment_mode}>

                                        <option value="" data-select2-id="2">Select trade mode</option>
                                        <option value="Import">Import</option>
                                        <option value="Export">Export</option>
                                        <option value="Shipping & Logistics">Shipping & Logistics</option>
                                        <option value="Trader">Trader</option>
                                    </select>
                                    {verrors.shipment_mode && <div className="invalid-feedback" style={styleError}>{verrors.shipment_mode}</div>}
                                </div>
                            </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label required">Website</label>
                                <input type="text" name="website" id="website" className="form-control" onChange={handleChange} placeholder="Enter website" onBlur={handleFocusOut} value={formData.website} />
                                {verrors.website && <div className="invalid-feedback" style={styleError}>{verrors.website}</div>}
                           </div>

                            <div className="col-sm-6 mt-3 fv-plugins-icon-container">
                                <label className="form-label">What you want with us?</label>
                                <div className="position-relative">

                                    <select name="with_us" id="with_us" onChange={handleSelectWithUs} className="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="multiStepsState" tabIndex="-1" aria-hidden="true" value={formData.with_us}>

                                        <option value="" data-select2-id="2">Select option</option>
                                        <option value="Buyer">Buyer</option>
                                        <option value="Supplier">Supplier</option>
                                        <option value="Logistic Partner">Logistic Partner</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-12 d-flex justify-content-between  mt-3 ">
                                <Link to={"/"}>
                                    <button className='btn btn-light text-dark back-home-btn
                                border-5'>

                                        Back To Home</button>
                                </Link>

                                <button type="submit" className=" btn-regi
                                 text-white border-0 regi-next mt-0" disabled={isButtonDisabled}>Next
                                </button>

                            </div>

                        </div>

                    </form>

                    

                </div>

            </div>

        </div>

    )
}

export default AccountDetail
