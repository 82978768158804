import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useRef, useState } from 'react'
import InputField from './InputField';

function SimpleTable() {

    const gridRef = useRef();
    const rowHeight = 60;

    const [rowData, setRowData] = useState([

        {
            make: "View",
            SNo: "1",
            BillDate: "August 31,2024",
            HsCode: 1,
            month: "June",
            ProductDescription: "Packing Material(Empty Carton)",
            CountryOfImport: "india",
            ShiperName: "worldwide",
            ConsigneeName: "Pharma Focus International",
            PortOfLanding: "Ahmedabad,India,Gujarat",
            PortOfUnlanding: "Ahmedabad,India,Gujarat",
            MadeOfTransportation: "NA",
            Action: "20.02 Piece",
            FOBValue: "2.93",
            PerUnitValue: "1.96 Per Piece"
        },

        {
            make: "View",
            SNo: "1",
            BillDate: "August 31,2024",
            HsCode: 1,
            month: "June",
            ProductDescription: "Packing Material(Empty Carton)",
            CountryOfImport: "india",
            ShiperName: "worldwide",
            ConsigneeName: "Pharma Focus International",
            PortOfLanding: "Ahmedabad,India,Gujarat",
            PortOfUnlanding: "Ahmedabad,India,Gujarat",
            MadeOfTransportation: "NA",
            Action: "20.02 Piece",
            FOBValue: "2.93",
            PerUnitValue: "1.96 Per Piece"
        },

        {
            make: "View",
            SNo: "1",
            BillDate: "August 31,2024",
            HsCode: 1,
            month: "June",
            ProductDescription: "Packing Material(Empty Carton)",
            CountryOfImport: "india",
            ShiperName: "worldwide",
            ConsigneeName: "Pharma Focus International",
            PortOfLanding: "Ahmedabad,India,Gujarat",
            PortOfUnlanding: "Ahmedabad,India,Gujarat",
            MadeOfTransportation: "NA",
            Action: "20.02 Piece",
            FOBValue: "2.93",
            PerUnitValue: "1.96 Per Piece"
        },

    ]);




    const CustomEditComponent = () => {

        return <button className='btn' >
            <i class="fa-regular fa-pen-to-square table-icon-color"></i>

        </button>;

    }

    const CustomDotsComponent = () => {

        return <div class="dropdown">
            <button class="btn border border-0 table-btn-style" type="button" id="cardOpt6" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <i class="fa-solid fa-ellipsis-vertical table-icon-color"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="cardOpt6" data-popper-placement="bottom-end">
                <p class="dropdown-item">View More</p>
                <p class="dropdown-item" >Delete</p>
            </div>
        </div>
    }


    const CustomDeleteComponent = () => {

        return <button className='btn mx-2' >
            <i class="fa-solid fa-trash-can table-icon-color"></i>
        </button>;

    }

    const [columnDefs, setColumnDefs] = useState([

        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            pinned: "left",
            width: 50

        },

        { field: "SNo", width: 70, headerName: "Sr.No" },
        { field: "BillDate" },
        { field: "HsCode", width: 150, headerName: "HS Code" },
        { field: "ProductDescription", width: 200 },
        { field: "CountryOfImport", width: 160, headerName: "Country of Import" },
        { field: "PortOfLanding", headerName: "Port of Landing" },
        { field: "PortOfLanding", headerName: "Port of Unlanding" },


        {
            field: "Action",
            cellRenderer: () => {
                return <>
                    <div className='d-flex '>
                        <CustomEditComponent />
                        <CustomDeleteComponent />
                        <CustomDotsComponent />
                    </div>
                </>
            },
        },


    ]);


    const onGridReady = useCallback((params) => {
        fetch(rowData)
            .then((resp) => resp.json())
            .then((data) => setRowData(data));
    }, []);



    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setGridOption(
            "quickFilterText",
            document.getElementById("filter-text-box").value,
        );
    }, []);



    return (
        <div>
            <div className={"ag-theme-quartz card shadow mt-5"} style={{ height: 400 }}>

            <div className='d-flex justify-content-end align-items-center pt-2 pe-3 '>
            <button className='btn-regi text-white mx-1 data-export-btn'>Export</button>
            <button className=' mx-1 btn-regi text-white'> + Add New Record</button></div>

                <div className='d-flex justify-content-between align-items-center ps-3 pe-3'>

                    <h5 className='table-text'>React Data Table</h5>

                    <div className='d-flex justify-content-between align-items-center p-3'>
                        <label className='mx-3 table-text'>Search :</label>

                        <InputField
                            type="text"
                            id="filter-text-box"
                            placeholder="Search..."
                            className='w-25'
                            onInput={onFilterTextBoxChanged}
                        ></InputField>

                    </div>

                </div>

                <AgGridReact

                    rowData={rowData}
                    columnDefs={columnDefs}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    rowHeight={rowHeight}
                />
            </div>
        </div>
    )
}

export default SimpleTable