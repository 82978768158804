import React, { useState } from 'react'

import { Button } from 'react-bootstrap'
// import SmallPopup from '../pages/SmallPopup'

import SmallPopup from '../components/SmallPopup';
import MediumPopup from '../components/MediumPopup';
import LargePopup from '../components/LargePopup';
import InputField from '../components/InputField';

function Popupbox() {

    const [SmallmodalShow, setSmallModalShow] = useState(false);
    const [mediummodalShow, setMediumModalShow] = useState(false);
    const [LargeModalShow, setLargeModalShow] = useState(false)


    return (
        <div>
            
            {/** Small Popup Start */}

            <Button variant="primary" className='mx-2' onClick={() => setSmallModalShow(true)}>
                Small Popup
            </Button>

            <SmallPopup title="my popup" SmallmodalShow={SmallmodalShow} setSmallModalShow={setSmallModalShow}>
                <div>
                    <label>Email</label>
                    <InputField className="" placeholder={"Enter Your Name"}></InputField>
                </div>

                <div className='row my-3'>

                    <div className='col-xl-12 mt-2'>
                        <label>Password</label>
                        <InputField className="" placeholder={"Enter Your Name"}></InputField>
                    </div>

                </div>
            </SmallPopup>

            {/** Small Popup End */}


            {/** Medium Popup Start */}

            <Button variant="primary" className='mx-2 my-2' onClick={() => setMediumModalShow(true)}>
                Medium Popup
            </Button>

            <MediumPopup
                title="medium popup"
                mediummodalShow={mediummodalShow}
                setMediumModalShow={setMediumModalShow}
            >
                <div>
                    <label>Name</label>
                    <InputField className="" placeholder={"Enter Your Name"}></InputField>
                </div>

                <div className='row my-3'>
                    <div className='col-xl-6 mt-2'>
                        <label>Email</label>
                        <InputField className="" placeholder={"Enter Your Name"}></InputField>
                    </div>
                    <div className='col-xl-6 mt-2'>
                        <label>Password</label>
                        <InputField className="" placeholder={"Enter Your Password"}></InputField>

                    </div>
                </div>
            </MediumPopup>

            {/** Medium Popup End */}


            {/** Larage Popup Start */}

            <Button variant="primary" className='mx-2' onClick={() => setLargeModalShow(true)}>
                Large modal
            </Button>

            <LargePopup
                title="larege popupbox"
                LargeModalShow={LargeModalShow}
                setLargeModalShow={setLargeModalShow}

            >

                <div>
                    <label>Name</label>
                    <InputField className="" placeholder={"Enter Your Name"}></InputField>
                </div>

                <div className='row my-3'>
                    <div className='col-xl-6 mt-2'>
                        <label>Email</label>
                        <InputField className="" placeholder={"Enter Your Name"}></InputField>
                    </div>
                    <div className='col-xl-6 mt-2'>
                        <label>Password</label>
                        <InputField className="" placeholder={"Enter Your Password"}></InputField>

                    </div>
                </div>

            </LargePopup>

            {/** Larage Popup End */}


        </div>
    )
}

export default Popupbox
