const defaultValues = {
    admin: {
        LIMIT: 10,
        OFFSET: 0,
        PAGINATE_RANGE: [1, 2, 5, 10, 25, 50, 100],
        FIXED_COLUMNS_START: 0
    },
    enduser: {
        LIMIT: 50,
        OFFSET: 0,
        PAGINATE_RANGE: [50, 100, 150, 200],
        FIXED_COLUMNS_START: 2
    },
    member: {
        status: {
            PENDING: 0,
            ACTIVE: 1,
            VERFIED: 2,
            PAYMENT_DUE: 3,
            DELETED: 4
        }        
    },
    USER_DEFAULTS : {
        STATUS: {
            PENDING: 0,
            ACTIVE: 1,
            DE_ACTIVATED: 2,
            EXPIRED: 3
        }
    }
}

export default defaultValues;