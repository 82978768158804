import React from 'react'
import { Button, Modal } from 'react-bootstrap';


function LargePopup(props) {


    return (
        
        <div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='modal-large'
                show={props.LargeModalShow}
                onHide={() => props.setLargeModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='modal-title'>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {props.children}

                </Modal.Body>

                <Modal.Footer>

                    <Button
                        className='btn-regi dialog-btn'
                        onClick={() => props.setLargeModalShow(false)}
                    >Close</Button>

                    <Button
                        className='btn-regi dialog-btn'
                    >Submit</Button>

                    <Button
                        className='btn-regi dialog-btn'
                    >Clear</Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default LargePopup