import React from "react";
import DataTable from 'react-data-table-component';

const AcSimpleTable = (props) => {

    const handlePageChange = page => {

      let newOffset =   props.limit * (page - 1);
      props.setOffset(newOffset);
      props.getData(newOffset,props.limit);
    };


    const handlePerRowsChange = async (newPerPage, page) => {

      let newOffset =   props.limit * (page - 1);
      props.setLoading(true);
      props.getData(newOffset,newPerPage);
      props.setOffset(newOffset);
      props.setLimit(newPerPage);
      props.setLoading(false);
    };

    return <DataTable 
      title={""} 
      columns={props.columns} 
      data={props.data} 
      progressPending={props.loading} 
      pagination 
      paginationServer 
      paginationTotalRows={props.paginationTotalRows} 
      onChangeRowsPerPage={handlePerRowsChange} 
      onChangePage={handlePageChange} 
      selectableRows 
      fixedColumnsStart={props.fixedColumnsStart} 
      paginationRowsPerPageOptions={props.paginationRowsPerPageOptions} 
      paginationPerPage={props.paginationPerPage} />;
  }

export default AcSimpleTable;