import React, { useEffect, useRef, useState } from 'react'
import mission from "../src/images/our mission_image.png"
import whowe from "../src/images/what-we-do.png"
import Contact from './Contact'
import Footer from './Footer'
import NavSection from './NavSection'
import value from "../src/images/Value for Customers.svg"
import result from "../src/images/Results-Driven.svg"
import Ongoing from "../src/images/Ongoing Learning.svg"
import innovate from "../src/images/simplify.svg"
import thik from "../src/images/Think Big.svg"
import { RiArrowDropRightLine } from "react-icons/ri";
import { FaArrowAltCircleUp } from 'react-icons/fa'

function About() {

    const [isVisible, setIsVisible] = useState(false);
    const prevScrollPos = useRef(0);



    useEffect(() => {

        const toggleVisibility = () => {
            const currentScrollPos = window.pageYOffset;

            // Button is displayed after scrolling for 500 pixels
            if (currentScrollPos > 500 && currentScrollPos > prevScrollPos.current) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }

            prevScrollPos.current = currentScrollPos;
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, [isVisible]);

    let topscroll = () => {

        window.scrollTo(0, 0);

    }

    return (



        <div className='container-fluid about-container overflow-hidden'>

            <div className='scroll-btn'>
                {
                    isVisible && (
                        <FaArrowAltCircleUp className='top-scroll' onClick={topscroll}></FaArrowAltCircleUp>
                    )
                }

            </div>

            {/* nav****8 */}

            <NavSection />


            {/* ****about-banner**** */}

            <div className='about-banner border'>

                <div className='about-banner-text'>
                    <h1>ABOUT <span className='about-banner-us'>US</span></h1>
                </div>

            </div>


            {/* *****section 1 ********* */}

            <div className='my-5'>

                <div className="how_we how-we-margin">

                    <div className='boost m-0 p-0'>
                        <p className=''>Our Identity </p>
                    </div>

                    <h5> <span className='fw-bold'> Who </span>
                        <span className='boost-span'>
                            <span className='fw-bold'>we </span> are
                        </span> {" "}
                    </h5>

                    <p className='with-our'>
                        Guiding You Through the Evolving Trade Landscape
                    </p>

                </div>

                <div className='row about-padding'>

                    <div className='col-xl-6 what_we_text'>

                        <p>In the fast-paced global market, staying ahead requires agility and precision. Orextrade is your trusted partner in navigating this ever-evolving landscape. Since 2015, we have established ourselves as India's leading data provider, delivering 90% real-time buyer and supplier data with unparalleled accuracy.
                        </p>

                        <p>Our AI-powered solutions offer a range of advanced services, including dynamic data display, filtration, and hierarchical graphical reports. With our Landed Cost Calculator, managing import and export shipments becomes seamless and efficient.</p>

                        <p>We understand the challenges of adapting to shifting consumer preferences, uncovering new markets, and making informed decisions. That's why we provide hassle-free, timely access to worldwide buyer-supplier data, empowering you to track emerging trends, discover new opportunities, and make strategic, data-driven decisions.
                        </p>

                        <p>At Orextrade, we deliver secure, reliable solutions that keep you ahead of the curve. Trust us to guide your business through the complexities of the global market with confidence and ease.</p>
                    </div>

                    <div className='col-xl-6'>
                        <img src={whowe} alt="" height={400} className='w-100' />
                    </div>

                </div>

            </div>



            {/* ******* section 2 *********** */}


            <div className=' what-we-do'>

                <div className="how_we how-we-margin">

                    <div className='boost m-0 p-0'>
                        <p className=''>What We Offer </p>
                    </div>

                    <h5> <span className='fw-bold'> What </span>

                        <span className='boost-span'>
                            <span className='fw-bold'> we</span> do
                        </span>

                    </h5>

                    <p className='with-our'>

                        We simplify global trade into actionable insights, keeping your business ahead with our comprehensive services.


                    </p>

                </div>

                <div>

                    <div className="row about-padding">

                        {/* card 1  */}
                        <div className=" my-3 col-xxl-3 col-xl-4">

                            <div className="border-0 about-card-text card shadow">

                                <div className="card-body text-center mt-2">

                                    <h5 className="text-center border-bottom pb-3 fw-bold">Buyer & Supplier Data</h5>

                                    <p className="with-our text-center what-we-card  pt-2">

                                        We provide 90% accurate, real-time data to help you make informed decisions. Our data solutions enable you to track market trends, identify new business opportunities, and connect with key stakeholders worldwide.
                                    </p>

                                </div>
                            </div>
                        </div>

                        {/* card 2  */}



                        <div className=" my-3 col-xxl-3 col-xl-4">

                            <div className="border-0 about-card-text card shadow">

                                <div className="card-body text-center mt-2">
                                    {/* <img src={laptop} alt="laptop" className="mb-4" /> */}
                                    <h5 className="text-center border-bottom pb-3 fw-bold">AI-Powered Solutions</h5>

                                    <p className="with-our text-center what-we-card pt-2">
                                        Leverage our advanced AI technology for dynamic data visualisation and filtration. Our AI tools generate detailed hierarchical graphical reports, offering you a clear, structured view of your data.

                                    </p>

                                </div>
                            </div>
                        </div>



                        {/* card 3  */}

                        <div className=" my-3 col-xxl-3 col-xl-4">

                            <div className="border-0 about-card-text card shadow">

                                <div className="card-body text-center mt-2 ">
                                    {/* <img src={laptop} alt="laptop" className="mb-4" /> */}
                                    <h5 className="text-center border-bottom pb-3 fw-bold">Secure Services</h5>

                                    <p className="with-our text-center what-we-card pt-2">
                                        Dedicated to delivering secure, dependable data solutions that uphold the highest standards of integrity and protection, ensuring your business's information remains safe and uncompromised at all times.

                                    </p>

                                </div>
                            </div>
                        </div>



                        {/* card 4  */}



                        <div className=" my-3 col-xxl-3 col-xl-4">

                            <div className="border-0 about-card-text card shadow">

                                <div className="card-body text-center mt-2 ">
                                    {/* <img src={laptop} alt="laptop" className="mb-4" /> */}
                                    <h5 className="text-center border-bottom pb-3 fw-bold">Market Insights</h5>

                                    <p className="with-our text-center what-we-card pt-2">
                                        Stay ahead of evolving consumer preferences and emerging market trends with our expert analysis, offering actionable insights that empower your business to make informed, strategic decisions for sustained growth.
                                    </p>

                                </div>
                            </div>
                        </div>





                    </div>

                    <div className='row mb-4 about-padding justify-content-center'>
                        {/* card 5  */}


                        <div className="my-3 col-xxl-3 col-xl-4">

                            <div className="border-0 about-card-text card shadow">

                                <div className="card-body text-center mt-2">
                                    {/* <img src={laptop} alt="laptop" className="mb-4" /> */}
                                    <h5 className="text-center border-bottom pb-3 fw-bold">Uncover Hidden Gems</h5>

                                    <p className="with-our text-center what-we-card pt-2">
                                        Access custom global Buyer-Supplier data with Orextrade. Our advanced solutions help you spot profitable opportunities, track trends, and make informed decisions, giving you a competitive edge and driving growth.

                                    </p>

                                </div>
                            </div>
                        </div>

                        {/* card 6  */}


                        <div className=" my-3 col-xxl-3 col-xl-4">

                            <div className="border-0 about-card-text card shadow">

                                <div className="card-body text-center mt-2">
                                    {/* <img src={laptop} alt="laptop" className="mb-4" /> */}
                                    <h5 className="text-center border-bottom pb-3 fw-bold">Insights That Empower</h5>

                                    <p className="with-our text-center what-we-card pt-2">
                                        Orextrade transforms raw data into actionable insights with expert reports and analysis. Understand markets, competitors, and trends deeply, enabling confident, strategic decisions to drive your business forward with clarity.

                                    </p>

                                </div>
                            </div>
                        </div>

                        {/* card 7  */}


                        <div className=" my-3 col-xxl-3 col-xl-4">

                            <div className="border-0 about-card-text card shadow">

                                <div className="card-body text-center mt-2">
                                    {/* <img src={laptop} alt="laptop" className="mb-4" /> */}
                                    <h5 className="text-center border-bottom pb-3 fw-bold">Solutions That Simplify</h5>

                                    <p className="with-our text-center what-we-card pt-2">
                                        Our intuitive platform offers powerful data tools for easy filtering, analysis, and visualisation. Save time and resources while we manage complexities, so you can focus on your success and strategic goals.
                                    </p>


                                </div>
                            </div>
                        </div>



                    </div>


                </div>

            </div>





            {/* ******section 3 ****** */}




            <div className='row  ourMision'>

                <div className='col-xl-12'>

                    <div className='row gap-5 justify-content-center my-5 '>

                        <div className='col-xl-4 ourmission-img'>

                            <img src={mission} alt="" className='w-100' height={500} />

                        </div>

                        <div className='col-xl-5'>

                            <div className='our-mission'>

                                <h4> <span className=' fw-bold '> Our </span>

                                    <span className='boost-span'>
                                        <span className='fw-bold'>Mission</span>
                                    </span>

                                </h4>


                                <p>At Orextrade, our mission is to fuel informed decisions in global trade. We empower businesses with comprehensive worldwide Buyer -Supplier  data and actionable insights, unlocking profitable opportunities while minimising risks. By providing precise, real-time information and expert analysis, we help you navigate the complexities of the global market with confidence and strategic clarity.

                                </p>
                            </div>


                            <div className='our-vission'>

                                <h4> <span className=' fw-bold '> Our </span>

                                    <span className='boost-span'>
                                        <span className='fw-bold'>Vision</span>
                                    </span>

                                </h4>


                                <p>We envision a future where the landscape of international trade is transformed by seamless access to reliable Buyer-Supplier data. At Orextrade, we aim to empower every business to navigate the global marketplace with confidence, driving prosperity and progress through precision, insight, and innovation.
                                </p>


                            </div>

                            <div className='our-values'>

                                <h4> <span className=' fw-bold'> Our </span>

                                    <span className='boost-span'>
                                        <span className='fw-bold'>Values</span>
                                    </span>

                                </h4>

                                <div className='row mt-2 our-values'>


                                    <div className='col-xl-4 our-values-line'>

                                        <p><i className="fa-solid fa-angle-right fw-bold"></i>   Value for Customers</p>

                                        <p><i className="fa-solid fa-angle-right fw-bold"></i> Results-Driven</p>

                                        <p><i className="fa-solid fa-angle-right fw-bold"></i> Ongoing Learning</p>


                                    </div>

                                    <div className='col-xl-4 our-values-line'>
                                        <p><i className="fa-solid fa-angle-right fw-bold"></i> Think Big</p>

                                        <p><i className="fa-solid fa-angle-right fw-bold"></i> Innovate & Simplify</p>

                                        <p><i className="fa-solid fa-angle-right fw-bold"></i> Hire Great People</p>


                                    </div>

                                    <div className='col-xl-4 our-values-line'>

                                        <p><i className="fa-solid fa-angle-right fw-bold"></i> Take Responsibility</p>
                                        <p><i className="fa-solid fa-angle-right fw-bold"></i> Think Long-Term</p>
                                        <p><i className="fa-solid fa-angle-right fw-bold"></i> Respect & Trust</p>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>





            {/* *****section 4 *********** */}

            <Footer />


        </div>
    )
}

export default About