import React from 'react'
import appstore from "../src/images/appstore.png"
import googlepaly from "../src/images/googelplay.png"
import orex from "../src/images/ore_footer_logo.png"
import { Link, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import ROUTES from './config/routePath';

function Footer() {

    const nav = useNavigate("")

    return (

        <div className='footer-effect'>
            <div className='footer'>

                <div className='container pt-5 pb-5'>

                    <div className="row justify-content-evenly gy-6 ftr">

                        <div className="col-lg-4 my-3">

                            <img src={orex} alt="" className='mb-3 footer-logo' />

                            <p className="footer-text footer-logo-description mb-6">

                                Subscribe for the latest buyer &amp;  supplier news, announcements, and more, delivered directly to your inbox.

                            </p>

                            <div className='text-right my-3'>

                                <a href="javascript:void(0)" className="" >
                                    <i className="fa-brands fa-linkedin-in text-white fs-5"></i>
                                </a>

                                <a href="javascript:void(0)" className="" >
                                    <i className="fa-brands fa-facebook-f text-white fs-5 mx-3"></i>
                                </a>


                                <a href="javascript:void(0)">
                                    <i className="fa-brands fa-instagram text-white fs-5 "></i>
                                </a>

                            </div>

                            <form className="footer-form">

                                {/* <div className="d-flex mt-1">

                    <input type="email" className="form-control rounded-1 rounded-start-bottom rounded-start-top" id="footer-email" placeholder="Your email" />
                    <button type="submit" className="btn btn-get shadow-none rounded-0 rounded-end-bottom rounded-end-top subscibe-btn">
                        Subscribe
                    </button>

                </div> */}

                            </form>

                        </div>

                        <div className="col-lg-2 col-md-4 col-sm-6">

                            <h6 className="footer-title mb-6"></h6>

                            <ul className="list-unstyled footer-link" >

                                <p className='fs-5 fw-bold'>Quick Links</p>

                                <Nav.Link>
                                    <li className="my-3" onClick={() => { nav(ROUTES.HOME) 
                                         window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth'
                                        });
                                    }}>
                                        Home
                                    </li>
                                </Nav.Link>

                                <Nav.Link >
                                    <li className="mb-3" onClick={() => { nav(ROUTES.ABOUT) 
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth'
                                        });
                                    }}>
                                        About Us
                                    </li>
                                </Nav.Link>

                                <Nav.Link href="#faq" onClick={() => { nav(ROUTES.HOME) }}><li className="mb-3">
                                    FAQ
                                </li></Nav.Link>

                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-4 col-sm-6">

                            <h6 className="footer-title mb-6 fw-bold"></h6>

                            <ul className="list-unstyled footer-link">

                                <p className='fs-5 fw-bold'>Registraion Links</p>

                                <Link to="/registration" onClick={()=>{
                                      window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth'
                                    });
                                }}>

                                    <li className="my-3 ">
                                        Pre-Registration
                                    </li>

                                </Link>


                            </ul>

                        </div>

                        <div className="col-lg-3 col-md-4">

                            <h6 className="footer-title mb-6 fw-bold">Download our app</h6>
                            <div className="my-4">
                                <img src={appstore} alt="apple icon" />
                            </div>
                            <div>
                                <img src={googlepaly} alt="google play icon" />
                            </div>
                        </div>

                    </div>

                </div>


                <div className="footer-bottom py-4  footer-color">

                    <div className="container d-flex flex-wrap justify-content-between flex-md-row flex-column text-center text-md-start">

                        <div className="mb-2 mb-md-0">
                            <span className="footer-bottom-text"> copyright © 2024 All Rights Reserved by OrexTrade</span>
                        </div>

                        <div>
                            <span className='footer-bottom-text'>Design & Developed By <span className='footer-design'>InfinGlobal</span></span>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    )
}

export default Footer