import React from 'react'
// import plan from "../src/images/plane.svg"
import { SlPlane } from "react-icons/sl";
import { GiCommercialAirplane } from "react-icons/gi";
import home from "../src/images/ourdata_home.svg"
import pc from "../src/images/our_pc.svg"
import hart from "../src/images/ourdata_hart.svg"
import setting from "../src/images/ourdta_setting.svg"
import chart from "../src/images/ourdata_chart.svg"

function Ourdatas() {

  return (

    <div className='dark-bg-section'>

      <div className='my-5 container-fluid'>

        <div className="how_we">

          <div className='boost'>
            <p >Real-Time Clarity</p>
          </div>

          <h5> <span className='fw-bold'> What Our Data </span>
            <span className='boost-span'>
              <span className='fw-bold'> Gives You: </span> Real-Time Clarity
            </span> {' '}
            and Growth.
          </h5>

          <p className='with-our'>
            Unlock Global Trade Insights: Real-Time Data, Advanced Tools, and Comprehensive Analysis for Strategic Growth.

          </p>
        </div>


        <div className='row gap-4 text-center justify-content-center aline-items-center mt-5 row-pading'>

          {/* /** card1 - start */}

          <div className="col-lg-3 my-4 text-center">

            <div className="row card card-section-shadow">

              <div className='card-section'>

                <div className="abc-col-md-12 ">

                  <div className='ourdata_icon d-flex justify-content-center align-items-center'>

                    <img src={home} alt="" />

                  </div>

                </div>

                <div className="abc-col-md-12">

                  <div className="card-body">

                    <p className="card-content">Access to Global Buyer-Supplier Trade Data.Comprehensive Global Access to Buyer-Supplier Trade Data Insights.  
                    </p>

                  </div>

                </div>

              </div>




            </div>



          </div>

          {/* /** card1 - end */}



          { /** card2 start */}


          <div className="col-lg-3 my-4  text-center">

            <div className="row card card-section-shadow">

              <div className='card-section'>

                <div className="abc-col-md-12 ">


                  <div className='ourdata_icon d-flex justify-content-center align-items-center'>

                    <img src={pc} alt="" />

                  </div>

                </div>

                <div className="abc-col-md-12">

                  <div className="card-body">

                    <p className="card-content">Advanced Online Platform for Effortless Trade Data Report Analysis.
                    </p>

                  </div>

                </div>

              </div>




            </div>



          </div>
          {/** card 2 - end */}

          { /** card 3 start */}


          <div className="col-lg-3 my-4  text-center">

            <div className="row card  card-section-shadow">

              <div className='card-section'>

                <div className="abc-col-md-12 ">


                  <div className='ourdata_icon d-flex justify-content-center align-items-center'>

                    <img src={hart} alt="" />

                  </div>

                </div>

                <div className="abc-col-md-12">

                  <div className="card-body">

                    <p className="card-content">Coverage of 209+ Countries and Ports Worldwide.
                    </p>

                  </div>

                </div>

              </div>




            </div>



          </div>
          {/** card 3 - end */}


        </div>


        <div className='row gap-3 mt-0 text-center justify-content-center aline-items-center row-pading'>

          { /** card 4 start */}

          <div className="col-lg-3 my-4  text-center">

            <div className="row card  card-section-shadow">

              <div className='card-section'>

                <div className="abc-col-md-12 ">


                  <div className='ourdata_icon d-flex justify-content-center align-items-center'>

                    <img src={setting} alt="" />

                  </div>

                </div>

                <div className="abc-col-md-12">

                  <div className="card-body">

                    <p className="card-content">Customizable Dashboard with Modifiable Inputs for Enhanced Pivot Tables and Charts.
                    </p>

                  </div>

                </div>

              </div>




            </div>



          </div>
          {/** card 4 - end */}

          { /** card 5 start */}

          <div className="col-lg-3 my-4  text-center">

            <div className="row card  card-section-shadow">

              <div className='card-section'>

                <div className="abc-col-md-12 ">


                  <div className='ourdata_icon d-flex justify-content-center align-items-center'>

                    <img src={chart} alt="" />

                  </div>

                </div>

                <div className="abc-col-md-12">

                  <div className="card-body">

                    <p className="card-content">Understandable Offline Data, Carefully Validated and Refined Buyer-Supplier Data Records in Excel.
                    </p>

                  </div>

                </div>

              </div>




            </div>



          </div>
          {/** card 5 - end */}
        </div>

      </div>

    </div>
  )
}

export default Ourdatas