import React from 'react'
import { ProSidebarProvider } from 'react-pro-sidebar';
import AdminPanle from '../pages/AdminUi/AdminPanle';

function OrexSidebarsProviders() {
  return (
    <div>
            <ProSidebarProvider>
                <AdminPanle/>
            </ProSidebarProvider>
    </div>
  )
}

export default OrexSidebarsProviders
