import React, { useContext, useState } from 'react'
import uploadPhotos from "../images/upload_photos.svg"
import { property } from '../App';

function FileUpload(props) {


    // console.log("props === ", props);
    // const [filename, setFilename] = useState('');
    // const [selectedFile, setSelectedFile] = useState(uploadPhotos);

    // let {setProfileName,profileName} = useContext(property)
    

    const handleChange = (e) => {

        const selectedFile = e.target.files[0];
        props.setFileData(selectedFile);
        // console.log();
        props.setFilename(selectedFile.name)
        previewFile(selectedFile);
        props.setProfileName(selectedFile.name);

    };
    // console.log('selectedFile.name========================',profileName);
    

    const previewFile = (file) => {

        const reader = new FileReader();

        reader.onload = () => {

            props.setSelectedFile(reader.result);


        };
        reader.readAsDataURL(file);
    };

    const handleImgReset = () => {

        props.setSelectedFile(uploadPhotos);
        props.setFilename('');

        document.querySelector("#file-input").value = ""

    };

    const style = {
        height: '170px',
        width: '170px',
    };

    return (
        <div>

            <div className='img-upload'>

                <label htmlFor="file-input">

                    <div className='fa-pen-main'>
                        <img id="previewImg"
                            src={props.selectedFile}
                            style={style}
                            alt="Preview"
                            className='rounded-circle upload_img my-2'
                    />

                        <i className="fa-solid fa-pen"></i>

                    </div>

                </label>

                <input
                    id="file-input"
                    type="file"
                    onChange={handleChange}
                    style={{ display: 'none' }}
                />


                <div className='mx-5'>
                    <button className='reset-btn' onClick={handleImgReset}>Reset</button>
                    <p className=''>{props.filename}</p>

                    <p className='upload-certificate p-0 py-2'>Allowed JPG, GIF or PNG. Max size of 800K</p>
                </div>

            </div>


        </div>
    )
}

export default FileUpload
