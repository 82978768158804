import React from 'react'
import { useNavigate } from 'react-router-dom';

import ROUTES from '../config/routePath';

import errorOImg from "../images/error404_img.svg"
import Button from '../components/Button'
import orexTradelogo from "../images/orexlogo.svg"

function Error404() {
    const nav = useNavigate();
    return (
        <div>
            <div>

                <div className='error404_logo'>

                    <div>
                        <img src={orexTradelogo} alt="" />
                    </div>
                </div>


                <div className='error'>

                    <div className='error-img'>
                        <img src={errorOImg} alt="" />
                    </div>

                    <div className='erro-text'>
                        <h1>Page Not Found </h1>
                        <p>Please try again later or feel free to contact us if the problem persists.</p>
                    </div>

                    <div className='error-reason'>

                        <h6>Possible Reasons</h6>
                        <p>The address may have been typed incorrectly.
                            It may be a broken or outdated link.</p>
                    </div>

                    <div>
                        <Button onClick={() => nav(ROUTES.HOME)} className="mt-3 back_home_404">Back to Home </Button>
                    </div>

                </div>
            </div>
        </div>
    )

}

export default Error404