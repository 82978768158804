import axios from "axios";
import API_URL from "../config/apiConfig";
import _ from "lodash";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

const apiUrl = API_URL;

export const updateProfileAction =  ($member_id) => async (dispatch) => {
    
    return await axios.get(apiUrl + "/member-company/" + $member_id, {}, {
         headers: {
             "Accept": "application/json"
         }
         })
         .then((response) => {
            // console.log('response======', response.data.data);
            dispatch({ type: "UPDATE_PROFILE_ACTION", payload: response.data.data }); 
            return response.data.data;   
        })
        .catch((error) => {
            dispatch({ type: "UPDATE_PROFILE_ACTION", payload: error });
        });
 }

 export const updateCompanyAction = ($member_id, formData, mobileValue, head_office, branch_office, company_size, industry, shipment_mode, selectedCity, phone, member_country, member_city, office_type, filedata) => async (dispatch) => {
    console.log('formData1223456===========',filedata);
    return await  axios.post(apiUrl + "/update-member-company/" + $member_id, {
        ...formData,
        mobileValue,
        head_office,
        branch_office,
        company_size,
        industry,
        shipment_mode,
        selectedCity,
        phone,
        member_country,
        member_city,
        office_type,
        filedata
    }, {
        headers: {
            // "Accept": "application/json"
            'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) => {
        // console.log('responseAPI===formData1223456===', response);
        if(response.data.status === true){
            dispatch({ type: "UPDATE_COMPANY_ACTION", payload: response.data });
            return true;
        }  
        if(response.data.status === false){
            if(response.data.message === "Validation Error."){
                return response.data;
            }
        }
    })
    .catch((error) => {
        // console.log(error);
        dispatch({ type: "UPDATE_COMPANY_ACTION", payload: error });
    });

 }

 export const updateServiceAction = ($member_id, service) => async (dispatch) => {
    // console.log('heyParth===', service);
    let ser = service.map(a => a.value);
    // console.log('ser======', ser);
    return await  axios.post(apiUrl + "/update-member-services/" + $member_id, {
        ser
    }, {
        headers: {
            "Accept": "application/json"
        }
    })
    .then((response) => {
        console.log('responsesERVICEAPI======', response);
        if(response.data.status === true){
            dispatch({ type: "UPDATE_SERVICE_ACTION", payload: response.data });
            return true;
        }  
        if(response.data.status === false){
            if(response.data.message === "Validation Error."){
                return response.data;
            }
        }
    })
    .catch((error) => {
        // console.log(error);
        dispatch({ type: "UPDATE_SERVICE_ACTION", payload: error });
    });

 }

 export const getContactAction =  ($member_id) => async (dispatch) => {
    
    return await axios.get(apiUrl + "/member-contacts/" + $member_id, {}, {
         headers: {
             "Accept": "application/json"
         }
         })
         .then((response) => {
            // console.log('response======', response.data.data);
            dispatch({ type: "GET_CONTACT_ACTION", payload: response.data.data }); 
            return response.data.data;   
        })
        .catch((error) => {
            dispatch({ type: "GET_CONTACT_ACTION", payload: error });
        });
 }

 export const getMemberServiceAction =  ($member_id) => async (dispatch) => {
    
    return await axios.get(apiUrl + "/member-services/" + $member_id, {}, {
         headers: {
             "Accept": "application/json"
         }
         })
         .then((response) => {
            // console.log('response==getMemberServiceAction====', response.data);
            dispatch({ type: "GET_MEMBER_SERVICE_ACTION", payload: response.data.data }); 
            return response.data.data;   
        })
        .catch((error) => {
            dispatch({ type: "GET_MEMBER_SERVICE_ACTION", payload: error });
        });
 }

 export const updateContactAction = ($member_id, inputFields) => async (dispatch) => {
    
    return await  axios.post(apiUrl + "/update-member-contacts/" + $member_id, {
        inputFields
    }, {
        headers: {
            "Accept": "application/json"
        }
    })
    .then((response) => {
        console.log('responsContactAPI======', response);
        if(response.data.status === true){
            dispatch({ type: "UPDATE_CONTACT_ACTION", payload: response.data });
            return true;
        }  
        // if(response.data.status === false){
        //     if(response.data.message === "Validation Error."){
        //         return response.data;
        //     }
        // }
    })
    .catch((error) => {
        // console.log(error);
        dispatch({ type: "UPDATE_CONTACT_ACTION", payload: error });
    });

 }

 export const getOtherDetailAction =  ($member_id) => async (dispatch) => {
    
    return await axios.get(apiUrl + "/member-other-details/" + $member_id, {}, {
         headers: {
             "Accept": "application/json"
         }
         })
         .then((response) => {
            // console.log('response==getMemberServiceAction====', response.data);
            dispatch({ type: "GET_OTHER_DETAIL_ACTION", payload: response.data.data }); 
            return response.data.data;   
        })
        .catch((error) => {
            dispatch({ type: "GET_OTHER_DETAIL_ACTION", payload: error });
        });
 }

 export const updateOtherDetailAction = ($member_id, established_year, about_company) => async (dispatch) => {
    
    return await  axios.post(apiUrl + "/update-other-details/" + $member_id, {
        established_year,
        about_company
    }, {
        headers: {
            "Accept": "application/json"
        }
    })
    .then((response) => {
        // console.log('responseOtherAPI======', response);
        if(response.data.status === true){
            dispatch({ type: "UPDATE_OTHER_DETAIL_ACTION", payload: response.data });
            return true;
        }  
        if(response.data.status === false){
            // if(response.data.message === "Validation Error."){
            //     return response.data;
            // }
        }
    })
    .catch((error) => {
        
        dispatch({ type: "UPDATE_OTHER_DETAIL_ACTION", payload: error });
    });

 }
