import React, { useEffect, useRef, useState } from 'react';

import ROUTES from './config/routePath';

import Sliders from './Sliders';
import Rewards from './Rewards';
import Faq from './Faq';
import Redy from './Redy';
import Contact from './Contact';
import Footer from './Footer';
import orex from "../src/images/orexlogo.svg"

import arow from "../src/images/arrow-icon.png"
import shadow from "../src/images/banner_bg.png"



// nav***


import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useNavigate } from 'react-router-dom';
import Bissiness from './Bissiness';
import Ourdatas from './Ourdatas';
import WhatDatas from './WhatDatas';
import OurClient from './OurClient';
import Blog from './Blog';
import OnlineData from './OnlineData';
import WhatMackOrex from './WhatMackOrex';
import Coustomers from './Coustomers';
import About from './About';
import ConnectingBuyer from './ConnectingBuyer';


function Website() {

    // let blog = useRef(null)
    let faq = useRef(null)
    let content = useRef(null)
    let nav = useNavigate("")

    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    var myDiv = document.getElementById("pos")

    if (scrolled) {

        myDiv.style.backgroundColor = "white"
    }

    useEffect(() => {

        const handleScroll = () => {

            setScrolled(window.scrollY >= 400);

        };

        window.addEventListener('scroll', handleScroll);


        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);


    // Scrolll to section***********

    let hendleScroll = (e, sectionRef) => {

        e.preventDefault();

        setShowOffcanvas(false);

        setTimeout(() => {

            const navbarHeight = document.querySelector('.navbar').offsetHeight;
            const offsetTop = sectionRef.current.offsetTop - navbarHeight;

            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });

        }, 300);

    }

    let nendleabout = () => {
        nav(ROUTES.ABOUT);

    }


    return (

        <div td='dark'>

            <div className='nav-efect'>

                {/* <div className='side-btn'>

                    <div>
                        <p>Inquiry</p>
                        <p>Now</p>
                    </div>


                </div> */}


                <div className="every nev-content">

                    <img src={shadow} alt="" className='bannaer-img' />

                    <h1>OrexTrade Your Trusted Partner for Secure Buyer-Supplier Connections

                    </h1>

                    <p className='features-icon-description'>
                    Connecting global markets with precise data and hands-on support for smooth, secure transactions.
                    </p>

                    <Link to={"/registration"}>
                        <button className='btn-get'>Get early Access</button>

                    </Link>

                    <div className='join_arrow'>

                        <p className='join_us'>Join Us Now</p>
                        <img src={arow} alt="" />

                    </div>

                </div>

                <div className='nav_bar'>

                    <div>
                        <img src={orex} alt="" className='orex-logo' />
                    </div>

                    <div id='pos' >

                        {['xl'].map((expand) => (

                            <Navbar key={expand} expand={expand} className="bg-body-tertiary landing-navbar ">

                                <Container fluid>
                                    <img src={orex} alt="" className='logo-small' />

                                    <Navbar.Toggle
                                        aria-controls={`offcanvasNavbar-expand-${expand}`}
                                        onClick={() => setShowOffcanvas(!showOffcanvas)}
                                    />

                                    <Navbar.Offcanvas

                                        show={showOffcanvas}
                                        onHide={() => setShowOffcanvas(false)}
                                        id={`offcanvasNavbar-expand-${expand}`}
                                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                        placement="start"
                                    >
                                        <Offcanvas.Header closeButton>

                                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                                <h2 className="app-brand-text demo menu-text fw-bold ms-2 ps-1">

                                                </h2>
                                            </Offcanvas.Title>
                                        </Offcanvas.Header>


                                        <Offcanvas.Body>



                                            <Nav className="justify-content-start flex-grow-1 nav-menu">

                                                <Nav.Link href="#home" className='px-4'><span className='nav-color'>Home</span><span className='ps-4 slas'>|</span></Nav.Link>

                                                <Nav.Link className='px-3' onClick={nendleabout}><span className='nav-color'>About Us</span> <span className='ps-4 slas'>|</span></Nav.Link>

                                                <Nav.Link href="#team" className='px-3 ' onClick={(e) => { hendleScroll(e, content) }}><span className='nav-color'>Contact</span> <span className='ps-4 slas'>|</span></Nav.Link>

                                                <Nav.Link href="#faq" className='px-3 ' onClick={(e) => { hendleScroll(e, faq) }}><span className='nav-color'>FAQ</span></Nav.Link>

                                                {/* <Nav.Link href="#con" className=' nav-color px-3' onClick={(e) => { hendleScroll(e, blog) }}>Blog <span>|</span></Nav.Link> */}

                                            </Nav>



                                            {/* <p className='mt-3 mx-2 px-1'>Get in Touch : <span className='contect_num'>+91 98745 63256</span> </p> */}


                                            <Link to={"/registration"}>

                                                <Button className='btn-regi'>  Pre  Registration Open

                                                    <i className="fa-solid fa-angle-right ms-1"></i>

                                                </Button>

                                            </Link>



                                        </Offcanvas.Body>

                                    </Navbar.Offcanvas>

                                </Container>

                            </Navbar>
                        ))}

                    </div>

                </div>



            </div>

            <Sliders />

            <Bissiness />

            <Ourdatas />

            <WhatDatas />

            <Coustomers />

            <WhatMackOrex />

            <Redy />

            <ConnectingBuyer/>

            <Rewards />


            <div ref={content} id="contect"> <Contact /></div>


            <div ref={faq} id="faq" ><Faq /></div>


            <Footer />

            


        </div>
    )
}

export default Website