import React from 'react'

function SubHeader() {

  return (

    <div>
      
      <div className='sub-header'>

        <h3>Company Info</h3>

        <p>

          User Management
          <i className="fa-solid fa-angle-right mx-2"></i>

          Add User
          <i className="fa-solid fa-angle-right mx-2"></i>

          <span>Compny Info</span>
        </p>

      </div>

    </div>
  )

}

export default SubHeader
