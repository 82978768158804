import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import defaultValues from '../../constant/defaultValues';
import ROUTES from '../../config/routePath';

import { getMemberList } from '../../actions/memberAction';

import AcSimpleTable from "../../components/AcSimpleTable";
import InputField from '../../components/InputField';


function Members(props) {

    const { admin } = defaultValues;
    const nav = useNavigate();

    const [memberList, setMemberList] = useState([]);
    const [offset, setOffset] = useState(admin.OFFSET);
    const [limit, setLimit] = useState(admin.LIMIT);
    const [searchTxt, setSearchTxt] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totalRows, settotalRows] = useState(0);

    const columns = [
        {
          name: 'Id',
          selector: row => row.id,
        }, 
        {
            name: 'Company Name',
            selector: row => row.company_name,
            minWidth: '200px'
        },
        {
            name: 'Name',
            selector: row => row.first_name+' '+row.last_name,
            minWidth: '200px'
        },
        {
            name: 'Email',
            selector: row => row.email,
            minWidth: '200px'
        },
        {
            name: 'Trade Mode',
            selector: row => row.shipment_mode,
            minWidth: '200px'
        },
        {
            name: 'Country',
            selector: row => row.country.name,
            minWidth: '200px'
        },  
        // {
        //     name: 'Mobile',
        //     selector: row => row.mobile,
        //     // minWidth: '200px',
        //     style: {
        //       WhiteSpace: 'nowrap',
        //       overflow: 'visible',
        //       TextOverflow: 'unset'
        //     }
        // },
          /* {
            name: 'Actions',
            cell:(row) => <button className='btn' onClick={() => handleViewClick(row.id)}>
                            <i class="fa-regular fa fa-eye table-icon-color"></i>
                          </button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          } */
    ];

    const handleViewClick = (id) => {
        nav(ROUTES.ADMIN_MEMBERS+'/'+id);
    };

    const onSearchTextBoxChanged = (e) => {
        setSearchTxt(e.target.value);
        setOffset(admin.OFFSET);
        setLimit(admin.LIMIT);
        getRegisteredMembers(admin.OFFSET, admin.LIMIT, e.target.value);
    }


    const getRegisteredMembers = async (offset, limit, searchTxt) => {
        await props.getMembers(offset,limit,searchTxt).then((response) => {
            setMemberList(response.data);
            settotalRows(response.metadata.total);
        }).catch((err) => {
            console.log("Error ==== ", err);
        })
    }

    useEffect(() => {
        getRegisteredMembers(offset, limit, searchTxt);
    },[]);

    return(<>


        <div>
            <div className={"ag-theme-quartz card shadow mt-5"} style={{ height: 400 }}>

            <div className='d-flex justify-content-end align-items-center pt-2 pe-3 '>
                {/* <button className='btn-regi text-white mx-1 data-export-btn'>Export</button>
                <button className=' mx-1 btn-regi text-white'> + Add New Record</button> */}
            </div>

                <div className='d-flex justify-content-between align-items-center ps-3 pe-3'>
                    <h5 className='table-text'>Members</h5>
                    <div className='d-flex justify-content-between align-items-center p-3'>
                        <label className='mx-3 table-text'>Search :</label>
                        <InputField
                            type="text"
                            id="filter-text-box"
                            placeholder="Search..."
                            className='w-25'
                            onKeyUp={onSearchTextBoxChanged}
                        ></InputField>
                    </div>

                </div>

                {memberList && <AcSimpleTable data={memberList} title="Member List" pagination={true} paginationPageSize={limit}
                    columns={columns}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    fixedColumnsStart={admin.FIXED_COLUMNS_START}
                    getData={getRegisteredMembers}
                    offset={offset}
                    limit={limit}
                    setLimit={setLimit}
                    setOffset={setOffset}
                    setLoading={setLoading}
                    noDataComponent="Your Text Here"
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
            /> }
            </div>
        </div>
      
    </>)
}

const mapStateToProps = state => {

    return {
        member_list: state.member.memberlist,
        // authError: state.auth.errorMessages
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getMembers: (offset, limit, searchTxt) => dispatch(getMemberList(offset, limit, searchTxt))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Members);
