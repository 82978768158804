import React from 'react'

function RadioButtonField({ value }) {
    return (
        // <div className='form-check d-inline border'>
        
        <div className='d-inline'>
            
            <input
                className="form-check-input mx-2"
                type="radio"
                value="value"
                 />

        </div>

        // </div>
    )
}

export default RadioButtonField