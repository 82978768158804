import React, { useContext, useEffect } from 'react'
import { property } from './App';
import orexlogo from "../src/images/orexlogo.svg"
import { Link } from 'react-router-dom'

import compneyresp from "./images/Company r_representative.svg"
import contect from "./images/Contact.svg"

import compneyInfWhite from "./images/Company_agent_white.svg"


function AcounInfo() {

    let {setRegiIcon,RegiIcon} = useContext(property)

    let regi_icon = document.getElementsByClassName("regi-icon-imgs")

    setRegiIcon(regi_icon)

    console.log()



    let { setaccount, setcompney, setcompnytext } = useContext(property)

    let acount = document.getElementsByClassName("aco-reg")
    let compney_text = document.getElementsByClassName("compey")
    let compney_info = document.getElementsByClassName(" compney_info")

    setaccount(acount)
    setcompney(compney_text)
    setcompnytext(compney_info)

    return (

        <div className=''>

            <div className='registraion-orexlogo'>
                <Link to={"/"}>
                    <img src={orexlogo} alt="" />
                </Link>
            </div>

            <div className='acount-regi'>

                <div className='aco-reg' id='acount-detail' >


                    <div className='regi-icon'>
                        <img src={compneyInfWhite} alt="" className='regi-icon-imgs' />
                    </div>

                    <div className='rego-ico'>

                        <h6 className='compey'>Company</h6>
                        <p className='compney_info' >Company Information</p>

                    </div>

                </div>

                <div className='aco-reg reg-agent' id='company-representative' >

                    <div className='regi-icon'>
                        <img src={compneyresp} alt="" className='regi-icon-imgs' />
                    </div>

                    <div className='rego-ico'>

                        <h6 className='compey'>Agent </h6>
                        <p className='compney_info'>Company Representative</p>

                    </div>


                </div>

                <div className='aco-reg reg-account' id='contact-detail' >

                    <div className='regi-icon'>
                        <img src={contect} alt="" className='contect-img regi-icon-imgs' />
                    </div>

                    <div className='rego-ico'>

                        <h6 className='compey'>Contact</h6>
                        <p className='compney_info'>Contact Information</p>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default AcounInfo