import React from 'react'
import { Button, Modal } from 'react-bootstrap'

function SmallPopup(props) {

    return (
        <div >
            
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.smallmodalShow}
                onHide={() => props.setSmallModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='modal-title'>
                    {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
      
                    {props.children}

                </Modal.Body>

                <Modal.Footer>
                    <Button
                        className='btn-regi dialog-btn'
                        onClick={() => props.setSmallModalShow(false)}
                    >{props.closeButtonLabel}</Button>

                    <Button
                        type={props.type}
                        onClick={props.onSubmit}
                        className='btn-regi dialog-btn'

                    >{props.okButtonLabel}</Button>

                </Modal.Footer>


            </Modal>

        </div>
    )
}

export default SmallPopup